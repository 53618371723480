module.exports = {
  BasicNFTStorefront: require("./BasicNFTStorefront"),
  BasicStakepool: require("./BasicStakepool"),
  DiscountedMultiRoundTokenGatedStorefront: require("./DiscountedMultiRoundTokenGatedStorefront"),
  MerkleIDOPoolWithCliffVesting: require("./MerkleIDOPoolWithCliffVesting"),
  StandardizedMerkleStorefront: require("./StandardizedMerkleStorefront"),
  TokenRedeemer: require("./TokenRedeemer"),
  TokenRedeemerV2: require("./TokenRedeemerV2"),
  TriRoundStorefront: require("./TriRoundStorefront"),
  Staking: require("./Staking"),
};
