import { groupBy } from "lodash";
import { Divider, Select, Stack, TextInput } from "@mantine/core";
import DateAndTimePicker from "../components/reusable/DateAndTimePicker";
import {
  DEFAULT_NOTIFICATION_DELAY,
  PROJECT_ACTIONS
} from "../utils/constants";
import { useForm } from "@mantine/form";
import ProjectActions from "../views/ProjectPage/components/ProjectActions";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";
import { withDynamicFields } from "../forms/formUtils";
import DescriptionFormField from "../components/reusable/FormFields/DescriptionFormField";
import MediaFormField from "../components/reusable/FormFields/MediaFormField";
import PrivateAccessToFormField from "../components/reusable/FormFields/PrivateAccessToFormField";

const NOTIFICATION_MAX_ROWS = 5;

// DropzoneInput component uses its own state in order to use the ImageCropper.
// So, in order to reset every child component state when doing the form reset, we need to uplift each
// clear child state handler, store it, and call it at the same time with the resetForm handler.
let clearChildStateHandlers = {};

const useCreateForm = ({ formSchema, onSubmit }) => {
  const form = withDynamicFields(useForm(formSchema));

  const assignClearChildState = (componentName, clearStateHandler) => {
    clearChildStateHandlers[componentName] = clearStateHandler;
  };

  const renderStringField = (field) => {
    return (
      <TextInput
        key={field.formRef}
        label={field.value.label}
        placeholder={field.value.label}
        {...form.getInputProps(field.formRef)}
      />
    );
  };

  const renderNumberField = (field) => {
    const inputProps = form.getInputProps(field.formRef);

    const handleOnChange = (e) => {
      const number = Number(e.target.value);
      inputProps.onChange(isNaN(number) ? e.target.value : number);
    };

    return (
      <TextInput
        key={field.formRef}
        label={field.value.label}
        placeholder={field.value.label}
        type="number"
        {...inputProps}
        onChange={handleOnChange}
      />
    );
  };

  const renderSelectField = (field) => {
    return (
      <Select
        key={field.formRef}
        label={field.value.label}
        placeholder={field.value.label}
        data={field.value.options}
        {...form.getInputProps(field.formRef)}
      />
    );
  };

  const renderDateField = (field) => {
    return (
      <DateAndTimePicker
        key={field.formRef}
        label={field.value.label}
        placeholder={field.value.label}
        {...form.getInputProps(field.formRef)}
      />
    );
  };

  const renderMediaField = (field) => {
    const inputProps = form.getInputProps(field.formRef);

    switch (true) {
      case field.formRef.indexOf("description") > -1:
        return (
          <DescriptionFormField
            key={field.formRef}
            field={field}
            inputProps={inputProps}
            onClearState={assignClearChildState}
          />
        );
      case field.formRef.indexOf("privateAccessTo") > -1:
        return (
          <PrivateAccessToFormField
            key={field.formRef}
            field={field}
            inputProps={inputProps}
            onClearState={assignClearChildState}
          />
        );
      default:
        return (
          <MediaFormField
            key={field.formRef}
            field={field}
            inputProps={inputProps}
            onClearState={assignClearChildState}
          />
        );
    }
  };

  const handleOnSubmit = (values) => {
    onSubmit(values);
  };

  const handleOnErrors = (errors) => {
    const errorInputs = Object.keys(errors).map((key, index) => {
      return index < NOTIFICATION_MAX_ROWS ? (
        <span key={key}>{errors[key]}</span>
      ) : null;
    });

    showNotification({
      id: "validate-form",
      color: "red",
      title: `Invalid inputs`,
      message: (
        <Stack spacing={0}>
          {errorInputs}
          {errorInputs.length > NOTIFICATION_MAX_ROWS ? "..." : null}
        </Stack>
      ),
      icon: <IconX size={16} />,
      autoClose: DEFAULT_NOTIFICATION_DELAY
    });
  };

  const handleOnReset = () => {
    Object.keys(clearChildStateHandlers).forEach((handlerKey) =>
      clearChildStateHandlers[handlerKey]()
    );
    clearChildStateHandlers = {};
    form.reset();
  };

  const composeInputFields = () => {
    const valueFieldKey = "value";
    let parentRef = "";

    const walkFields = (obj, group = "", formRef = "", depth = 0) => {
      return Object.keys(obj).map((key) => {
        const child = obj[key];
        if (depth === 0) parentRef = key + `.${valueFieldKey}.`;

        if (child.type === "group") {
          return walkFields(child.value, child.label, key, depth + 1);
        } else {
          return {
            group,
            formRef: `${depth > 1 ? parentRef : ""}${formRef}.${valueFieldKey}.${key}.${valueFieldKey}`,
            value: child
          };
        }
      });
    };

    const fields = walkFields(form.values);
    const flatFields = fields.flat(2);
    return groupBy(flatFields, (item) => item.group);
  };

  const fields = composeInputFields();

  return (
    <form onSubmit={form.onSubmit(handleOnSubmit, handleOnErrors)}>
      {Object.keys(fields).map((key) => {
        const _fields = fields[key];

        return _fields.length > 0 ? (
          <Stack spacing={2} mb={25} key={key}>
            <Divider my="xs" label={key} />
            {_fields?.length &&
              _fields.map((field) => {
                const { type, hidden } = field.value;

                if (hidden) return null;

                switch (type) {
                  case "string":
                    return renderStringField(field);
                  case "number":
                    return renderNumberField(field);
                  case "select":
                    return renderSelectField(field);
                  case "date":
                    return renderDateField(field);
                  case "media":
                    return renderMediaField(field);
                  default:
                    return null;
                }
              })}
          </Stack>
        ) : null;
      })}

      <ProjectActions
        hasChanges={true}
        actions={PROJECT_ACTIONS.CREATE}
        initialProjectStatus={PROJECT_ACTIONS.CREATE[0].value}
        projectStatus={form.getInputProps("core.value.status.value")}
        onReset={handleOnReset}
      />
    </form>
  );
};

export default useCreateForm;
