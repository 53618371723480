import { Card, createStyles, Group, Stack } from "@mantine/core";
import EditableImage from "../../../components/editable/EditableImage";
import { useAsync } from "react-async";
import utilities from "../../../utils/utilities";
import EditableText from "../../../components/editable/EditableText";
import ProjectStatusBadge from "../../../components/reusable/ProjectStatusBadge";
import ProjectTypeBadge from "../../../components/reusable/ProjectTypeBadge";

const fileToDataUrl = async ({ value }) => {
  if (!value || typeof value === "string") {
    return value;
  }

  return await utilities.fileReader().readAsDataURL(value);
};

const GeneralInfo = ({
  project,
  name,
  subTitle,
  author,
  status,
  logo,
  thumbnail
}) => {
  const { data: thumbnailSrc } = useAsync({
    promiseFn: fileToDataUrl,
    watch: thumbnail,
    value: thumbnail.value
  });
  const { data: logoSrc } = useAsync({
    promiseFn: fileToDataUrl,
    watch: logo,
    value: logo.value
  });
  const { classes } = useStyles();

  return (
    <Card withBorder radius="md" className={classes.card}>
      <Card.Section>
        <EditableImage
          className={classes.thumbnail}
          label="Thumbnail"
          value={thumbnailSrc}
          onChange={thumbnail.onChange}
          height={345}
        />
        <Group className={classes.badgesContainer} spacing={10}>
          {project && <ProjectTypeBadge project={project} />}
          <ProjectStatusBadge status={status} />
        </Group>
      </Card.Section>
      <Group m="xs" align="center" noWrap>
        <div className={classes.logoWrapper}>
          <EditableImage
            className={classes.logo}
            label="Logo"
            value={logoSrc}
            onChange={logo.onChange}
            width={100}
            height={100}
          />
        </div>
        <Stack spacing={5}>
          <div className={classes.textInfoWrapper}>
            <EditableText className={classes.title} {...name} />
            <EditableText className={classes.subtitle} {...subTitle} />

            <EditableText
              className={classes.author}
              color="dimmed"
              value={author.value?.name || author.value}
              onChange={author.onChange}
            />
          </div>
        </Stack>
      </Group>
    </Card>
  );
};

const useStyles = createStyles((theme) => {
  return {
    card: {
      flex: 1
    },
    badgesContainer: {
      position: "absolute",
      top: theme.spacing.sm,
      left: theme.spacing.sm,
      pointerEvents: "none"
    },
    thumbnail: {
      width: "100%"
    },
    logoWrapper: {
      ".edit-btn": {
        top: -5,
        right: -5,
        width: 30,
        minWidth: 30,
        height: 30,
        minHeight: 30
      }
    },
    logo: {
      maxWidth: "100px"
    },
    textInfoWrapper: {
      paddingRight: 20,

      ".edit-btn": {
        top: 0,
        right: -30,
        width: 22,
        minWidth: 22,
        height: 22,
        minHeight: 22,

        ".edit-btn-icon": {
          width: "12px",
          height: "12px"
        }
      }
    },
    title: {
      fontSize: 22,
      fontWeight: "bold",

      input: {
        fontSize: 22,
        fontWeight: "bold"
      }
    },
    subtitle: {
      fontSize: 15,
      fontWeight: "bold",

      input: {
        fontSize: 15,
        fontWeight: "bold"
      }
    },
    author: {
      fontSize: 12,
      marginTop: 5,

      input: {
        marginTop: 5,
        fontSize: 12
      }
    }
  };
});

export default GeneralInfo;
