import React, { useEffect } from "react";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.min.css";
import "prismjs/components/prism-json.min";

const Code = ({ code, language, className = "", style = {} }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <div className={`Code ${className}`} style={style}>
      <pre>
        <code className={`language-${language}`}>{code}</code>
      </pre>
    </div>
  );
};

export default Code;
