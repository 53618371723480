import { memo } from "react";
import { withToggleOnEdit } from "../../../utils/contexts";
import { IconX } from "@tabler/icons";
import { createStyles } from "@mantine/core";

const ContractTab = ({ contract, onDeleteConfirmation }) => {
  const { classes } = useStyles();
  const EditIcon = withToggleOnEdit(IconX);

  const handleOnDelete = (e) => {
    e.stopPropagation();
    onDeleteConfirmation(contract);
  };

  return (
    <div className={classes.label}>
      <span>{contract.contractId}</span>
      <EditIcon
        className={classes.deleteBtnIcon}
        size={16}
        stroke={1.5}
        onClick={handleOnDelete}
      />
    </div>
  );
};

const useStyles = createStyles((theme) => {
  return {
    label: {
      display: "flex",
      alignItems: "center"
    },

    deleteBtnIcon: {
      color: theme.colors.gray[5],
      marginLeft: theme.spacing.xs,
      border: `1px solid ${theme.colors.gray[5]}`,
      borderRadius: "50%",

      "&:hover": {
        color: theme.colors.gray[3],
        border: `1px solid ${theme.colors.gray[3]}`
      }
    }
  };
});

export default memo(ContractTab);
