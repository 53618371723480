import WalletBase from "./WalletBase";
import { HashPackWallet } from "headstarter-crypto/hedera-wallet-bridges";
import HeadstarterLogo from "../../assets/media/headstarter_logo.png";

export const hpAppMetaData = {
  description: "HeadStarter | The Launchpad of the Hedera Hashgraph ecosystem",
  icon: HeadstarterLogo,
  name: "Headstarter"
};

class HashpackWallet extends WalletBase {
  static async getConnection() {
    const _wallet = await HashPackWallet.getConnection(false);
    return _wallet;
  }

  constructor({ network, onConnect, onDisconnect }) {
    super({ network, onConnect, onDisconnect });
  }

  async connect() {
    HashPackWallet.newConnection({
      appMetadata: hpAppMetaData,
      debug: false,
      networkName: this.network
    })
      .then((payload) => super.connect(payload))
      .catch((error) => {
        console.error("Could not initialize the connection", error);
      });
  }

  async disconnect() {
    super.disconnect();
  }

  async checkExtensionPresence() {
    return await HashPackWallet.checkExtensionPresence(false);
  }
}

export default HashpackWallet;
