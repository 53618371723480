import { useEffect } from "react";
import StatsField from "../../../components/reusable/StatsField";
import CopyableText from "../../../components/reusable/CopyableText";
import {
  IconBracketsContainEnd,
  IconBracketsContainStart,
  IconBusinessplan,
  IconCalendar,
  IconCoin,
  IconForbid,
  IconLetterCase,
  IconNews,
  IconOmega,
  IconPigMoney,
  IconReceipt2,
  IconRefresh,
  IconShare,
  IconSortAscendingNumbers,
  IconUsers,
  IconWallet,
  IconX,
  IconZoomMoney
} from "@tabler/icons";
import { Hbar } from "headstarter-crypto/hashgraph-sdk";
import {
  Button,
  Center,
  Paper,
  Space,
  Stack,
  Text,
  Switch,
  Group,
  Loader
} from "@mantine/core";
import ExpandableCard from "../../../components/reusable/ExpandableCard";
import HederaAddress from "../../../components/reusable/HederaAddress";
import ContractExecutorAnchor from "../../../components/reusable/ContractExecutorAnchor";
import { useToggleContractIngestionMutation } from "../../../APIs/Aws.api";
import { showNotification } from "@mantine/notifications";

const IDOContractInfo = ({ contract, onUpdate }) => {
  const { contractId, contractInfo } = contract || {};
  const [toggleContract, { isLoading: pendingToggle, error }] =
    useToggleContractIngestionMutation();

  const onToggleError = (e) => {
    return showNotification({
      id: "toggle-contract-ingestion",
      icon: <IconX />,
      autoClose: 4000,
      color: "red",
      title: `Failed to toggle the contract ingestion`,
      message: e.reason || e.message,
      loading: false
    });
  };

  const handleToggleContract = async (value) => {
    try {
      await toggleContract({ contractId, disabled: value });
      onUpdate(contractId);
    } catch (e) {
      console.error(e);
      return onToggleError(e);
    }
  };

  useEffect(() => {
    if (error?.error) onToggleError({ message: error.error });
  }, [error]);

  if (!contractInfo || !contractInfo.lastUpdated) {
    return (
      <Paper py="4rem" withBorder>
        <Center>
          <Stack spacing={5}>
            <Text size="lg" weight="bold" align="center">
              There is no info about this contract yet.
            </Text>
            <Text size="xs" color="dimmed" align="center">
              Check if the project is saved or the contract is already deployed.
            </Text>
            <Space mt="lg" />
            <Button
              leftIcon={<IconRefresh />}
              onClick={() => onUpdate(contractId)}
            >
              Refresh
            </Button>
          </Stack>
        </Center>
      </Paper>
    );
  }

  const { token } = contractInfo || {};

  return (
    <Stack>
      <ExpandableCard title="General">
        <Stack>
          <StatsField
            label="Contract ID"
            value={
              <Group position="apart" grow>
                <ContractExecutorAnchor
                  contractId={contractId}
                  abiFileName="MerkleIDOPoolWithCliffVesting"
                >
                  <CopyableText copy={contractId}>
                    <HederaAddress contractId={contractId} />
                  </CopyableText>
                </ContractExecutorAnchor>
                <Switch
                  label={
                    <Group>
                      <Text size="xs">Contract tracking</Text>
                      {pendingToggle && <Loader size="xs" />}
                    </Group>
                  }
                  onLabel="ON"
                  offLabel="OFF"
                  checked={
                    !contractInfo.disabled || contractInfo.disabled === false
                  }
                  onChange={(e) => handleToggleContract(!e.target.checked)}
                />
              </Group>
            }
            icon={IconNews}
          />
          <StatsField
            label="Last Updated"
            date={contractInfo.lastUpdated}
            icon={IconCalendar}
          />
          <StatsField
            label="Last Ingestion"
            date={contractInfo.lastIngestion}
            icon={IconCalendar}
          />

          <StatsField
            label="Available Tokens"
            value={contractInfo.availableTokens / Math.pow(10, token.decimals)}
            icon={IconPigMoney}
          />

          {parseInt(contractInfo.tokenClaimMin || 0) > 0 && (
            <StatsField
              label="Token Claim Min"
              value={contractInfo.tokenClaimMin / Math.pow(10, token.decimals)}
              icon={IconForbid}
            />
          )}

          <StatsField
            label="Token Claim Max"
            value={contractInfo.tokenClaimMax / Math.pow(10, token.decimals)}
            icon={IconForbid}
          />

          <StatsField
            label="Tokens Sold"
            value={contractInfo.tokensSold / Math.pow(10, token.decimals)}
            icon={IconZoomMoney}
          />

          <StatsField
            label="Max Allocation"
            value={Hbar.fromTinybars(
              Math.floor(
                (contractInfo.pricePerToken * contractInfo.tokenClaimMax) /
                  Math.pow(10, token.decimals)
              )
            ).toString()}
            icon={IconWallet}
          />

          <StatsField
            label="Joined Accounts"
            value={contractInfo.joinedAccounts}
            icon={IconUsers}
          />
        </Stack>
      </ExpandableCard>

      <ExpandableCard title="Timing" expanded={false}>
        <Stack>
          <StatsField
            label="Start Time"
            date={contractInfo.startTime}
            icon={IconBracketsContainStart}
          />
          <StatsField
            label="End Time"
            date={contractInfo.endTime}
            icon={IconBracketsContainEnd}
          />
          <StatsField
            label="Redeem Time"
            date={contractInfo.redeemTime}
            icon={IconShare}
          />
        </Stack>
      </ExpandableCard>

      <ExpandableCard title="Token Info" expanded={false}>
        <Stack>
          <StatsField
            label="Token ID"
            value={
              <CopyableText copy={token.tokenId}>
                <HederaAddress tokenId={token.tokenId} />
              </CopyableText>
            }
            icon={IconCoin}
          />
          <StatsField label="Name" value={token.name} icon={IconLetterCase} />
          <StatsField label="Symbol" value={token.symbol} icon={IconOmega} />
          <StatsField
            label="Decimals"
            value={token.decimals}
            icon={IconSortAscendingNumbers}
          />
          <StatsField
            label="Initial Supply"
            value={token.initialSupply / Math.pow(10, token.decimals)}
            icon={IconBusinessplan}
          />
          <StatsField
            label="Total Supply"
            value={token.totalSupply / Math.pow(10, token.decimals)}
            icon={IconBusinessplan}
          />
          <StatsField
            label="Price Per Token"
            value={Hbar.fromTinybars(contractInfo.pricePerToken).toString()}
            icon={IconReceipt2}
          />
        </Stack>
      </ExpandableCard>
    </Stack>
  );
};

export default IDOContractInfo;
