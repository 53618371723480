import { withFormValidators } from "./formUtils";
import { PROJECT_TYPES } from "../utils/constants";

const isInDevelopment = process.env.REACT_APP_ENV !== "production";

const ProjectForm = {
  initialValues: {
    general: {
      type: "group",
      label: "General Info",
      value: {
        name: {
          type: "string",
          label: "Project Name",
          value: isInDevelopment ? "Project Name Sample" : "",
          validator: (value) =>
            value.length < 2
              ? "Project Name must have at least 2 letters"
              : null
        },
        subtitle: {
          type: "string",
          label: "Project Subtitle",
          value: isInDevelopment ? "Subtitle goes here" : "",
          validator: (value) =>
            value.length < 2 ? "Subtitle must have at least 2 letters" : null
        },
        author: {
          type: "string",
          label: "Author (optional)",
          value: isInDevelopment ? "Vladutsss" : "",
          validator: null
        },
        totalRaiseUsd: {
          type: "number",
          label: "Total Raise USD",
          value: isInDevelopment ? 50000 : 0,
          validator: (value) =>
            value <= 0 ? "Total raise USD must be greater than 0" : null
        },
        saleLabel: {
          type: "string",
          label: "Sale Label (optional)",
          value: "",
          validator: null
        },
        privateAccessTo: {
          type: "media",
          label: "Private access to accounts (optional)",
          value: "",
          validator: null
        }
      }
    },
    core: {
      type: "group",
      label: "Project Type",
      value: {
        projectType: {
          type: "select",
          value: PROJECT_TYPES.ido.value,
          options: [PROJECT_TYPES.ido, PROJECT_TYPES.ino, PROJECT_TYPES.spool],
          validator: (value) =>
            !value
              ? "Project type is required in order to create a new one"
              : null
        },
        status: {
          type: "string",
          label: "Status",
          value: "DRAFTING",
          hidden: true,
          validator: null
        }
      }
    },
    projectTypeInfo: {
      type: "group",
      label: "Project Type Info",
      value: {
        maxAllocationUsd: {
          type: "number",
          label: "Max Allocation USD",
          value: isInDevelopment ? 100 : 0,
          validator: (value) =>
            !value ? "Max Allocation USD is required" : null,
          dependsOn: [
            { key: "core.projectType", toBe: PROJECT_TYPES.ido.value }
          ]
        },
        nftsOnSale: {
          type: "number",
          label: "NFTs On Sale",
          value: isInDevelopment ? 6666 : 0,
          validator: (value) => (!value ? "NFTs On Sale is required" : null),
          dependsOn: [
            { key: "core.projectType", toBe: PROJECT_TYPES.ino.value }
          ]
        },
        ipfsGateway: {
          type: "string",
          label: "IPFS Gateway (optional)",
          value: isInDevelopment ? "ipfs.io" : "",
          validator: null,
          dependsOn: [
            { key: "core.projectType", toBe: PROJECT_TYPES.ino.value }
          ]
        },
        initialMarketCapUsd: {
          type: "number",
          label: "Initial Market Cap (optional)",
          value: isInDevelopment ? 50000 : 0,
          validator: null,
          dependsOn: [
            { key: "core.projectType", toBe: PROJECT_TYPES.ido.value }
          ]
        },
        initialTokenCirculation: {
          type: "number",
          label: "Initial Token Circulation (optional)",
          value: isInDevelopment ? 800000 : 0,
          validator: null,
          dependsOn: [
            { key: "core.projectType", toBe: PROJECT_TYPES.ido.value }
          ]
        },
        pools: {
          type: "array",
          label: "Pools",
          value: [],
          hidden: true,
          dependsOn: [
            { key: "core.projectType", toBe: PROJECT_TYPES.ido.value }
          ]
        },
        whitelistUrl: {
          type: "string",
          label: "Allowlist Form URL",
          value: isInDevelopment
            ? "https://gleam.io/UynMZ/headstarter-main-round-ido-allowlist"
            : "",
          validator: null
        },
        whitelistMerkle: {
          type: "string",
          label: "Whitelist Merkle Root (optional)",
          value: isInDevelopment
            ? "0xf406ca37d1d768b2f339be522be8bfcd208f4fcce82ce23b99b2db901ceb5116"
            : "",
          validator: null
        }
      }
    },
    timeline: {
      type: "group",
      label: "Timeline",
      value: {
        whitelistStartDate: {
          type: "date",
          label: "Allowlist start date",
          value: Math.floor(Date.now() / 1000),
          validator: null
        },
        whitelistEndDate: {
          type: "date",
          label: "Allowlist end date",
          value: Math.floor(Date.now() / 1000),
          validator: null
        },
        startDate: {
          type: "date",
          label: "Start Date",
          value: Math.floor(Date.now() / 1000),
          validator: null,
          dependsOn: [
            { key: "core.projectType", toBe: PROJECT_TYPES.ido.value },
            { key: "core.projectType", toBe: PROJECT_TYPES.ino.value }
          ]
        },
        endDate: {
          type: "date",
          label: "End Date",
          value: Math.floor(Date.now() / 1000),
          validator: null
        },
        redeemDate: {
          type: "date",
          label: "Token Distribution Date",
          value: Math.floor(Date.now() / 1000),
          validator: null,
          dependsOn: [
            { key: "core.projectType", toBe: PROJECT_TYPES.ido.value }
          ]
        }
      }
    },
    tokenInfo: {
      type: "group",
      label: "Token Info",
      value: {
        name: {
          type: "string",
          label: "Token Name",
          value: isInDevelopment ? "HeadStarter" : "",
          validator: (value) => (!value ? "Token Name is required" : null),
          dependsOn: [
            { key: "core.projectType", toBe: PROJECT_TYPES.ido.value }
          ]
        },
        priceInUsd: {
          type: "number",
          label: "Price in USD",
          value: isInDevelopment ? 0.01 : 0,
          validator: (value) => (!value ? "Price in USD is required" : null),
          dependsOn: [
            { key: "core.projectType", toBe: PROJECT_TYPES.ido.value }
          ]
        },
        symbol: {
          type: "string",
          label: "Token Symbol",
          value: isInDevelopment ? "HST" : "",
          validator: (value) => (!value ? "Token Symbol is required" : null),
          dependsOn: [
            { key: "core.projectType", toBe: PROJECT_TYPES.ido.value }
          ]
        }
      },
      dependsOn: [{ key: "core.projectType", toBe: PROJECT_TYPES.ido.value }]
    },
    media: {
      type: "group",
      label: "Media",
      value: {
        logo: {
          type: "media",
          label: "Logo",
          value: null,
          validator: (value) => (!value ? "Logo is required" : null)
        },
        thumbnail: {
          type: "media",
          label: "Banner",
          value: null,
          validator: (value) => (!value ? "Thumbnail is required" : null)
        },
        description: {
          type: "media",
          label: "Description",
          value: null,
          validator: (value) => (!value ? "Description is required" : null)
        }
      }
    }
  },

  validate: {}
};

export default withFormValidators(ProjectForm);
