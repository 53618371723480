import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useEffect, useRef, useState } from "react";
import { IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { LoadingOverlay } from "@mantine/core";
import useDebounceEffect from "../../hooks/useDebounceEffect";
import utilities from "../../utils/utilities";

const DEBOUNCE_EFFECT_TIMEOUT = 200;

const ImageCropper = ({
  className,
  value,
  onChange,
  mime,
  aspect,
  ...props
}) => {
  const imgRef = useRef();
  const [fileSrc, setFileSrc] = useState();
  const [crop, setCrop] = useState({
    unit: "%",
    x: 0,
    y: 0,
    width: 100,
    height: 100
  });

  const getCroppedImg = async (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          resolve(blob);
        },
        mime,
        1
      );
    });
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: "px",
          width: width,
          height: width
        },
        aspect,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  };

  useEffect(() => {
    (async () => {
      if (value && IMAGE_MIME_TYPE.indexOf(value.type) > -1) {
        const dataURL = await utilities.fileReader().readAsDataURL(value);
        setFileSrc(dataURL);
      } else {
        setFileSrc(null);
      }
    })();
  }, [value]);

  useDebounceEffect(
    () => {
      const cropBeforeSave = async (_crop) => {
        const fileName = value.name || "image";
        const blob = await getCroppedImg(imgRef.current, crop, fileName);
        const file = new File([blob], fileName, { type: mime });

        onChange(file);
      };

      if (fileSrc) cropBeforeSave(crop);
    },
    [crop, fileSrc],
    DEBOUNCE_EFFECT_TIMEOUT
  );

  return (
    <ReactCrop
      className={className}
      crop={crop}
      onChange={(c) => setCrop(c)}
      aspect={aspect}
      {...props}
    >
      <LoadingOverlay visible={!fileSrc} overlayBlur={2} />
      <img ref={imgRef} src={fileSrc} alt={value.name} onLoad={onImageLoad} />
    </ReactCrop>
  );
};

export default ImageCropper;
