import {
  createStyles,
  Grid,
  LoadingOverlay,
  Paper,
  Stack,
  Text
} from "@mantine/core";
import GeneralInfo from "./components/GeneralInfo";
import EditableText from "../../components/editable/EditableText";
import TimelineComponent from "./components/Timeline";
import PoolsCard from "./components/PoolsCard";
import ProjectStats from "./components/ProjectStats";
import TokenStats from "./components/TokenStats";
import EditableDescription from "../../components/editable/EditableDescription";
import { PROJECT_STATUS } from "../../utils/constants";
import { useLazyGetContractInfoQuery } from "../../APIs/Aws.api";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";
import utilities from "../../utils/utilities";
import PrivateAccessAccounts from "./components/PrivateAccessAccounts";

const IDOProjectView = ({ project, form }) => {
  const { classes } = useStyles();
  const [getContractInfo] = useLazyGetContractInfoQuery();

  const handleUpdatePools = async (contractId) => {
    try {
      const { data: contractInfo, error } = await getContractInfo({
        contractId
      });
      const poolsField = form.getInputProps("pools");

      if (error && error.originalStatus === 404) {
        throw new Error("Could not find the contract");
      }

      const updatedPools = await Promise.allSettled(
        poolsField.value.map(async (pool) => {
          if (pool.contractId === contractId) {
            return {
              ...pool,
              contractInfo
            };
          }

          return pool;
        })
      ).then(utilities.filterSettledPromises);

      poolsField.onChange(updatedPools);
    } catch (error) {
      showNotification({
        id: "update-pools",
        color: "red",
        title: `Failed to update ${contractId} pool`,
        message: `${error?.error || error}. Check the console for more details`,
        icon: <IconX size={16} />,
        autoClose: 4000
      });
    }
  };

  return (
    <Grid m={0} mt={20}>
      <LoadingOverlay visible={!project} overlayBlur={2} />

      <Grid.Col md={6}>
        <Stack spacing={20}>
          <GeneralInfo
            project={project}
            name={form.getInputProps("name")}
            subTitle={form.getInputProps("subTitle")}
            author={form.getInputProps("author")}
            status={project?.status}
            logo={form.getInputProps("logo")}
            thumbnail={form.getInputProps("thumbnail")}
          />

          <Paper
            className={classes.basicCardWrapper}
            p="md"
            pr={45}
            radius="md"
            withBorder
          >
            <Text>
              Whitelist Merkle:
              <EditableText
                className={classes.basicCardText}
                {...form.getInputProps("whitelistMerkle")}
              />
            </Text>
          </Paper>

          <Paper
            className={classes.basicCardWrapper}
            p="md"
            pr={45}
            radius="md"
            withBorder
          >
            <Text>
              Whitelist URL:
              <EditableText
                className={classes.basicCardText}
                {...form.getInputProps("whitelistUrl")}
              />
            </Text>
          </Paper>

          <Paper
            className={classes.basicCardWrapper}
            p="md"
            pr={45}
            radius="md"
            withBorder
          >
            <Text>
              Sale Label:
              <EditableText
                className={classes.basicCardText}
                {...form.getInputProps("saleLabel")}
              />
            </Text>
          </Paper>

          <Paper
            className={classes.basicCardWrapper}
            p="md"
            radius="md"
            withBorder
          >
            <PrivateAccessAccounts {...form.getInputProps("privateAccessTo")} />
          </Paper>
        </Stack>
      </Grid.Col>

      <Grid.Col md={6}>
        <Stack spacing={20}>
          <Paper p="md" radius="md" withBorder>
            <TimelineComponent
              projectType={project?.type}
              contracts={form.getInputProps("pools")?.value}
              timelineStages={{
                whitelistStartDate: form.getInputProps("whitelistStartDate"),
                whitelistEndDate: form.getInputProps("whitelistEndDate"),
                startDate: form.getInputProps("startDate"),
                endDate: form.getInputProps("endDate"),
                redeemDate: form.getInputProps("redeemDate")
              }}
            />
          </Paper>

          <PoolsCard
            projectKey={form.getInputProps("key")}
            pools={form.getInputProps("pools")}
            canAddContract={project.status === PROJECT_STATUS.published}
            onUpdatePools={handleUpdatePools}
          />
        </Stack>
      </Grid.Col>

      <Grid.Col xs={12}>
        <Stack spacing={20}>
          <Paper p="md" radius="md" withBorder>
            <ProjectStats
              totalRaiseUsd={form.getInputProps("totalRaiseUsd")}
              maxAllocationUsd={form.getInputProps("maxAllocationUsd")}
              initialMarketCapUsd={form.getInputProps("initialMarketCapUsd")}
              initialTokenCirculation={form.getInputProps(
                "initialTokenCirculation"
              )}
            />
          </Paper>
          <Paper p="md" radius="md" withBorder>
            <TokenStats
              tokenName={form.getInputProps("tokenName")}
              tokenSymbol={form.getInputProps("tokenSymbol")}
              tokenPriceInUsd={form.getInputProps("tokenPriceInUsd")}
            />
          </Paper>
        </Stack>
      </Grid.Col>

      <Grid.Col className={classes.descriptionWrapper} xs={12}>
        <EditableDescription
          className="description"
          projectKey={project?.key}
          description={form.getInputProps("description")}
        />
      </Grid.Col>
    </Grid>
  );
};

const useStyles = createStyles(() => {
  return {
    basicCardWrapper: {
      paddingRight: 20,

      ".editable.mantine-Text-root": {
        wordBreak: "break-all"
      },

      ".edit-btn": {
        top: 0,
        right: -30,
        width: 22,
        minWidth: 22,
        height: 22,
        minHeight: 22,

        ".edit-btn-icon": {
          width: "12px",
          height: "12px"
        }
      }
    },
    basicCardText: {
      "& > div": {
        fontSize: 14
      }
    },
    descriptionWrapper: {
      ".editable-wrapper": {
        width: "100%"
      }
    }
  };
});

export default IDOProjectView;
