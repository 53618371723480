import { FileInput, Group, Text, useMantineTheme } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { IconFileText, IconUpload, IconX } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import utilities from "../../../utils/utilities";
import { useEffect, useState } from "react";

const AbiDropArea = ({ handleSetABI, ...props }) => {
  const theme = useMantineTheme();
  const [state, setState] = useState({
    file: null
  });

  const handleUploadFile = async (files) => {
    if (!files) {
      setState((prevState) => ({ ...prevState, file: null }));
      handleSetABI([]);

      return;
    }

    setState((prevState) => ({ ...prevState, file: files[0] }));
  };

  const handleRejectedFile = (files) => {
    const file = files[0];

    return showNotification({
      id: "upload-abi-json",
      icon: <IconX />,
      autoClose: 4000,
      color: "red",
      title: "Failed to upload ABI",
      message: `File ${file.name} has been rejected`,
      loading: false
    });
  };

  useEffect(() => {
    (async () => {
      if (!state.file) return;

      const fileContents = await utilities.fileReader().readAsText(state.file);

      try {
        handleSetABI(JSON.parse(fileContents));
      } catch (e) {
        console.error(e);

        return showNotification({
          id: "upload-abi-json",
          icon: <IconX />,
          autoClose: 4000,
          color: "red",
          title: "Failed to parse",
          message: `Unable to parse your .json file. Make sure it has the correct format`,
          loading: false
        });
      }
    })();
  }, [state.file, handleSetABI]);

  if (state.file) {
    return (
      <FileInput value={state.file} onChange={handleUploadFile} clearable />
    );
  }

  return (
    <Dropzone
      onDrop={handleUploadFile}
      onReject={handleRejectedFile}
      maxSize={5 * 1024 ** 2}
      multiple={false}
      accept={["application/json", "text/plain"]}
      {...props}
    >
      <Group
        position="center"
        spacing="xl"
        style={{ minHeight: "200px", pointerEvents: "none" }}
      >
        <Dropzone.Accept>
          <IconUpload
            size="3.2rem"
            stroke={1.5}
            color={
              theme.colors[theme.primaryColor][
                theme.colorScheme === "dark" ? 4 : 6
              ]
            }
          />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX
            size="3.2rem"
            stroke={1.5}
            color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
          />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <IconFileText size="3.2rem" stroke={1.5} />
        </Dropzone.Idle>

        <div>
          <Text size="xl" inline>
            Drag your ABI json here or click to select files
          </Text>
          <Text size="sm" color="dimmed" inline mt={7}>
            You can upload one file at a time. Make sure that the file size does
            not exceed 10MB.
          </Text>
        </div>
      </Group>
    </Dropzone>
  );
};

export default AbiDropArea;
