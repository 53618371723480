import { Fragment, useState } from "react";
import {
  ActionIcon,
  Button,
  Group,
  Stack,
  TextInput,
  Tooltip,
  Text,
  Paper,
  Divider,
  createStyles
} from "@mantine/core";
import {
  IconCheck,
  IconHash,
  IconLetterH,
  IconLetterS,
  IconRouter,
  IconSend,
  IconStar,
  IconTrash,
  IconX
} from "@tabler/icons";
import { showNotification, updateNotification } from "@mantine/notifications";
import { tryToDisplay } from "./contractUtils";
import {
  DEFAULT_NOTIFICATION_DELAY,
  REGEX_PATTERNS
} from "../../utils/constants";
import { AccountId } from "headstarter-crypto/hashgraph-sdk";
import FunctionFormMetaArgs from "./FunctionFormMetaArgs";
import BigNumber from "bignumber.js";
import Code from "../reusable/Code";

const getFunctionInputKey = (functionInfo, input, inputIndex) => {
  const name = input?.name ? input.name : "input_" + inputIndex + "_";
  return functionInfo.name + "_" + name + "_" + input.type;
};

const isReadable = (fn) =>
  fn.stateMutability === "view" || fn.stateMutability === "pure";

const FunctionForm = ({ contractFunction, functionInfo }) => {
  const { classes } = useStyles();

  const [form, setForm] = useState({});
  const [txValue, setTxValue] = useState("");
  const [metaArgsList, setMetaArgsList] = useState([]);
  const [returnValue, setReturnValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const inputs = functionInfo.inputs.map((input, inputIndex) => {
    const key = getFunctionInputKey(functionInfo, input, inputIndex);
    let buttons = [];

    if (input.type === "uint256") {
      buttons = (
        <Tooltip position="top" label="* 10 ** 8" key="to-decimals-btn">
          <ActionIcon
            size="sm"
            variant="filled"
            onClick={() => {
              setForm((prevState) => ({
                ...prevState,
                [key]: Math.floor(form[key] * Math.pow(10, 8))
              }));
            }}
          >
            <IconStar size={12} />
          </ActionIcon>
        </Tooltip>
      );
    } else if (input.type === "address") {
      const possibleAddress =
        form[key] && form[key].toLowerCase && form[key].toLowerCase().trim();

      if (possibleAddress) {
        if (new RegExp(REGEX_PATTERNS.hederaAccount).test(possibleAddress)) {
          buttons = [
            ...buttons,
            <Tooltip
              position="top"
              label="To solidity address"
              key="to-solidity-address-btn"
            >
              <ActionIcon
                size="sm"
                variant="filled"
                onClick={() => {
                  setForm((prevState) => ({
                    ...prevState,
                    [key]: AccountId.fromString(form[key]).toSolidityAddress()
                  }));
                }}
              >
                <IconLetterS size={12} />
              </ActionIcon>
            </Tooltip>
          ];
        } else if (`0x${possibleAddress}`.length === 42) {
          buttons = [
            ...buttons,
            <Tooltip
              position="top"
              label="To hedera address"
              key="to-hedera-address-btn"
            >
              <ActionIcon
                size="sm"
                variant="filled"
                onClick={() => {
                  try {
                    setForm((prevState) => ({
                      ...prevState,
                      [key]: AccountId.fromSolidityAddress(form[key]).toString()
                    }));
                  } catch (e) {
                    return showNotification({
                      id: "parse-address-from-solidity",
                      icon: <IconX />,
                      autoClose: 4000,
                      color: "red",
                      title: `Failed to execute`,
                      message: e.reason || e.message,
                      loading: false
                    });
                  }
                }}
              >
                <IconLetterH size={12} />
              </ActionIcon>
            </Tooltip>
          ];
        }
      }
    }

    return (
      <Stack spacing={5} key={key}>
        <Group spacing={5} position="right">
          {buttons}
        </Group>

        <TextInput
          placeholder={input.name ? input.type + " " + input.name : input.type}
          value={form[key] || ""}
          name={key}
          onChange={(e) => {
            const { name, value } = e.target;
            setForm((prevState) => ({ ...prevState, [name]: value }));
          }}
        />
      </Stack>
    );
  });

  if (functionInfo.payable) {
    inputs.push(
      <Stack spacing={5} key="transaction-value-input">
        <Group spacing={5} position="right">
          <Tooltip position="top" label="* 10 ** 8">
            <ActionIcon
              size="xs"
              variant="filled"
              onClick={() => {
                const floatValue = parseFloat(txValue);
                if (floatValue) {
                  setTxValue(Math.floor(floatValue * Math.pow(10, 8)));
                }
              }}
            >
              <IconStar size={12} />
            </ActionIcon>
          </Tooltip>
          <Tooltip position="top" label="number to hex">
            <ActionIcon
              size="xs"
              variant="filled"
              onClick={() => {
                setTxValue(new BigNumber(txValue).toString(16));
              }}
            >
              <IconHash size={12} />
            </ActionIcon>
          </Tooltip>
        </Group>
        <Stack spacing={10}>
          <TextInput
            placeholder="Value in ℏ"
            onChange={(e) => setTxValue(e.target.value)}
            value={txValue}
          />
        </Stack>
      </Stack>
    );
  }

  const handleProcessTransaction = async () => {
    if (returnValue) setReturnValue(null);

    const funcArgs = functionInfo.inputs
      .map((input, inputIndex) => {
        const key = getFunctionInputKey(functionInfo, input, inputIndex);
        let value = form[key];

        if (["array", "tuple"].includes(input.baseType)) {
          value = JSON.parse(value);
        } else if (input.type === "bool") {
          if (
            value === "true" ||
            value === "1" ||
            value === "0x1" ||
            value === "0x01" ||
            value === "0x0001"
          ) {
            value = 1;
          } else {
            value = 0;
          }
        }
        return value;
      })
      .filter(Boolean);

    const metaArgs = metaArgsList.reduce((acc, arg) => {
      let value = arg.value;

      if (!isNaN(arg.value)) {
        value = Number(value);
      } else if ("true" === value) {
        value = true;
      } else if ("false" === value) {
        value = false;
      }

      acc[arg.key] = value;
      return acc;
    }, {});

    try {
      if (txValue) metaArgs.amount = txValue;

      const args = [
        ...(Object.keys(metaArgs).length > 0 ? [metaArgs] : []),
        ...funcArgs
      ];

      setLoading(true);
      const returned = await contractFunction(...args);

      updateNotification({
        id: "contract-execution-processing",
        color: "teal",
        title: "Success",
        message: "The transaction has been successfully processed",
        icon: <IconCheck size={16} />,
        autoClose: DEFAULT_NOTIFICATION_DELAY
      });

      setForm({});
      setReturnValue(tryToDisplay(returned));
    } catch (err) {
      console.error(err);

      showNotification({
        id: "contract-execution-processing",
        icon: <IconX />,
        autoClose: 4000,
        color: "red",
        title: `Failed to execute`,
        message: err.reason || err.message,
        loading: false
      });
    }

    setLoading(false);
  };

  const button = isReadable(functionInfo) ? (
    <Tooltip position="top" label="Not implemented yet.">
      <div>
        <Button
          onClick={handleProcessTransaction}
          rightIcon={<IconRouter size={16} />}
          loading={loading}
          loaderPosition="right"
          disabled={true}
        >
          Read
        </Button>
      </div>
    </Tooltip>
  ) : (
    <Button
      onClick={handleProcessTransaction}
      rightIcon={<IconSend size={16} />}
      loading={loading}
      loaderPosition="right"
    >
      Send
    </Button>
  );

  return (
    <Paper p="md" radius="md" withBorder>
      <Stack spacing={5}>
        <Text size={20}>{functionInfo.name}</Text>

        {inputs.length > 0 && (
          <Fragment>
            <Divider my="xs" label="Function arguments" />
            {inputs}
            <FunctionFormMetaArgs
              key="meta-args-form"
              onSubmit={setMetaArgsList}
            />
          </Fragment>
        )}

        <Group spacing="xs">{button}</Group>
      </Stack>

      {returnValue !== null && (
        <Group className={classes.resultLog} spacing={0}>
          <div className={classes.resultLogHeader}>
            <span className="title">Out:</span>

            <Tooltip position="top" label="Clear logs">
              <ActionIcon
                size="xs"
                variant="filled"
                onClick={() => setReturnValue(null)}
              >
                <IconTrash size={12} />
              </ActionIcon>
            </Tooltip>
          </div>
          <div className={classes.resultLogBody}>
            <Code code={JSON.stringify(returnValue, null, 2)} language="json" />
          </div>
        </Group>
      )}
    </Paper>
  );
};

const useStyles = createStyles((theme) => ({
  resultLog: {
    background: theme.colors.dark[6],
    margin: "0.8rem 0",
    borderRadius: "5px",
    fontSize: "12px",

    pre: {
      background: "#232426",
      marginTop: 0
    }
  },

  resultLogHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0.5rem",

    ".title": {
      color: theme.colors.gray[5]
    }
  },

  resultLogBody: {
    width: "100%",
    overflow: "auto"
  }
}));

export default FunctionForm;
