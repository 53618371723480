import {
  createStyles,
  Group,
  Paper,
  Text,
  ThemeIcon,
  Stack,
  SimpleGrid
} from "@mantine/core";
import Utils from "../../../utils/utilities";
import EditableText from "../../../components/editable/EditableText";
import {
  IconBusinessplan,
  IconReceiptRefund,
  IconDeviceAnalytics,
  IconTrafficLights
} from "@tabler/icons";

const ProjectStats = ({
  totalRaiseUsd,
  maxAllocationUsd,
  initialMarketCapUsd,
  initialTokenCirculation,
  nftsOnSale
}) => {
  const { classes } = useStyles();

  const data = [
    totalRaiseUsd && {
      type: "number",
      title: "Fundraise goal",
      formField: totalRaiseUsd,
      formatterPrefix: "$",
      icon: IconBusinessplan
    },
    maxAllocationUsd && {
      type: "number",
      title: "Max allocation",
      formField: maxAllocationUsd,
      formatterPrefix: "$",
      icon: IconReceiptRefund
    },
    initialMarketCapUsd && {
      type: "number",
      title: "Initial Market Cap",
      formField: initialMarketCapUsd,
      formatterPrefix: "$",
      icon: IconDeviceAnalytics
    },
    initialTokenCirculation && {
      type: "number",
      title: "Initial Token Circulation",
      formField: initialTokenCirculation,
      formatterPrefix: "$",
      icon: IconTrafficLights
    },
    nftsOnSale && {
      type: "number",
      title: "NFTs On Sale",
      formField: nftsOnSale,
      formatterPrefix: "",
      icon: IconTrafficLights
    }
  ].filter(Boolean);

  const stats = data.map((stat) => {
    const IconComponent = stat.icon;
    const formField = stat.formField;

    return (
      <Paper withBorder p="md" radius="md" key={stat.title}>
        <Group position="apart" noWrap>
          <div className={classes.editableTextWrapper}>
            <Text
              color="dimmed"
              transform="uppercase"
              weight={700}
              size="xs"
              className={classes.label}
            >
              {stat.title}
            </Text>
            <EditableText
              className={classes.editableText}
              type={stat.type}
              formatted={Utils.formatValueWithPrefix(
                formField?.value,
                stat.formatterPrefix
              )}
              {...formField}
            />
          </div>
          <ThemeIcon
            color="gray"
            variant="light"
            sx={(theme) => ({ color: theme.colors.teal[6] })}
            size={38}
            radius="md"
          >
            <IconComponent size={28} stroke={1.5} />
          </ThemeIcon>
        </Group>
      </Paper>
    );
  });

  return (
    <Stack spacing={10}>
      <Text mb={20}>Project Stats</Text>
      <SimpleGrid cols={3} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
        {stats}
      </SimpleGrid>
    </Stack>
  );
};

const useStyles = createStyles((theme) => ({
  label: {
    fontFamily: theme.fontFamily
  },
  editableTextWrapper: {
    ".edit-btn": {
      top: 0,
      right: -30,
      width: 22,
      minWidth: 22,
      height: 22,
      minHeight: 22,
      transform: "translateY(25%)",

      ".edit-btn-icon": {
        width: "12px",
        height: "12px"
      }
    }
  },
  editableText: {
    fontSize: 20,
    fontWeight: 700,

    input: {
      fontSize: 20,
      fontWeight: 700
    }
  }
}));

export default ProjectStats;
