import {
  createStyles,
  Card,
  Image,
  Text,
  Badge,
  Group,
  Grid,
  ActionIcon,
  Tooltip
} from "@mantine/core";
import { IconTrash, IconEdit, IconFileOff } from "@tabler/icons";
import { NavLink, Link, useNavigate } from "react-router-dom";
import ProjectStatusBadge from "../../../components/reusable/ProjectStatusBadge";
import { PROJECT_STATUS } from "../../../utils/constants";
import ProjectTypeBadge from "../../../components/reusable/ProjectTypeBadge";

const ProjectCard = ({ className, project, onClose, onDelete, ...others }) => {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const { key, name, thumbnail, subTitle } = project;

  return (
    <Card
      withBorder
      radius="md"
      className={cx(classes.card, className)}
      {...others}
    >
      <Card.Section>
        <NavLink to={`/projects/view/${key}`}>
          <Image src={thumbnail} height={180} />
        </NavLink>
      </Card.Section>

      <Group className={classes.badgesContainer} spacing={10}>
        <ProjectTypeBadge project={project} />
        <ProjectStatusBadge status={project?.status} />
      </Group>

      <Text
        className={classes.title}
        weight={500}
        component={Link}
        to={`/projects/view/${key}`}
      >
        {name}
      </Text>

      <Text size="sm" color="dimmed" lineClamp={4}>
        {subTitle || "No subtitle"}
      </Text>

      <Group mt="xs" spacing={10}>
        <Grid className={classes.gridSpacer} />

        <Tooltip label="Edit Project" withArrow>
          <ActionIcon
            variant="default"
            radius="md"
            color="red"
            size={36}
            onClick={() => navigate(`/projects/edit/${key}`)}
          >
            <IconEdit size={18} className={classes.edit} stroke={1.5} />
          </ActionIcon>
        </Tooltip>

        {project.status !== PROJECT_STATUS.closed && (
          <Tooltip label="Close Project" withArrow>
            <ActionIcon
              variant="default"
              radius="md"
              color="red"
              size={36}
              onClick={() => onClose(key, name)}
            >
              <IconFileOff size={18} className={classes.close} stroke={1.5} />
            </ActionIcon>
          </Tooltip>
        )}

        <Tooltip label="Delete Project" withArrow>
          <ActionIcon
            variant="default"
            radius="md"
            color="red"
            size={36}
            onClick={() => onDelete(key, name)}
          >
            <IconTrash size={18} className={classes.trash} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Card>
  );
};

const useStyles = createStyles((theme) => {
  const isDarkMode = theme.colorScheme === "dark";

  return {
    card: {
      position: "relative",
      backgroundColor: isDarkMode ? theme.colors.dark[7] : theme.white
    },

    badgesContainer: {
      position: "absolute",
      top: theme.spacing.xs,
      right: theme.spacing.xs,
      pointerEvents: "none",
      justifyContent: "flex-end"
    },

    title: {
      display: "block",
      marginTop: theme.spacing.md,
      marginBottom: theme.spacing.xs / 2
    },

    gridSpacer: {
      flex: 1
    },

    edit: {
      color: theme.colors.gray[5]
    },

    close: {
      color: theme.colors.gray[5]
    },

    trash: {
      color: theme.colors.red[6]
    }
  };
});

export default ProjectCard;
