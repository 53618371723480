import { useEffect, useState } from "react";
import { SUPPORTED_WALLETS } from "../WalletConnectBtn";
import {
  Avatar,
  Button,
  createStyles,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
  Loader
} from "@mantine/core";

const WalletConnectOptions = ({ network, onConnect, walletConnected }) => {
  const [walletOptions, setWalletOptions] = useState([]);

  useEffect(() => {
    const composeWalletButtons = async () => {
      const _buttons = await Promise.all(
        SUPPORTED_WALLETS.filter((wallet) => wallet.enabled).map(
          async (wallet) => {
            const { name, icon, wallet: Wallet, enabled } = wallet || {};
            const walletInstance = enabled
              ? new Wallet({ network, onConnect })
              : null;
            const extensionExists =
              await walletInstance?.checkExtensionPresence();

            return {
              name,
              icon,
              onConnect: walletInstance?.connect,
              extensionExists
            };
          }
        )
      );

      setWalletOptions(_buttons);
    };

    composeWalletButtons();
  }, [network, onConnect]);

  return (
    <Stack spacing={5}>
      <LoadingOverlay visible={walletOptions.length === 0} overlayBlur={2} />
      {walletOptions?.length > 0 &&
        walletOptions.map((option) => {
          return (
            <WalletButton
              key={option.name}
              connected={walletConnected}
              {...option}
            />
          );
        })}
    </Stack>
  );
};

const WalletButton = ({
  name,
  icon,
  onConnect,
  connected,
  extensionExists
}) => {
  const [connecting, setConnecting] = useState(false);
  const { cx, classes } = useStyles();

  const handleConnect = () => {
    setConnecting(true);
    onConnect();
  };

  useEffect(() => {
    if (connected) {
      setConnecting(false);
    }
  }, [connected]);

  return (
    <Paper
      key={name}
      className={cx(classes.walletBtn, !extensionExists ? "disabled" : "")}
      p="xs"
      radius="md"
      onClick={extensionExists && handleConnect}
      withBorder
    >
      <Grid justify="space-between" align="center" p={5} pr={10}>
        <Group>
          <Avatar size={45} src={icon} radius={0} />
          <Text size="sm" weight={500}>
            {name}
          </Text>
        </Group>
        {connecting && <Loader size="sm" />}
        {!extensionExists && <Button>INSTALL</Button>}
      </Grid>
    </Paper>
  );
};

const useStyles = createStyles((theme) => ({
  walletBtn: {
    ":not(.disabled):hover": {
      cursor: "pointer",
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
          : theme.colors[theme.primaryColor][0]
    }
  }
}));

export default WalletConnectOptions;
