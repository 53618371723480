import { createStyles, Group, Text } from "@mantine/core";
import {
  IconInfoCircle,
  IconCheck,
  IconAlertCircle,
  IconAlertTriangle
} from "@tabler/icons";

const Icons = {
  error: IconAlertTriangle,
  warning: IconAlertCircle,
  success: IconCheck,
  info: IconInfoCircle
};

const SmallBanner = ({ children, variant = "info" }) => {
  const { classes, cx } = useStyles();
  const IconComponent = Icons[variant];

  return (
    <Group className={cx(classes.banner, variant)}>
      <Text className={classes.label}>
        <IconComponent className="icon" />
        {children}
      </Text>
    </Group>
  );
};

const useStyles = createStyles((theme) => {
  return {
    banner: {
      display: "flex",
      justifyContent: "center",
      padding: "0.2rem 1rem",
      background: theme.colors.blue[4],

      "&.error": {
        background: theme.colors.pink[5]
      },

      "&.warning": {
        background: theme.colors.orange[4]
      },

      "&.success": {
        background: theme.colors.teal[5]
      }
    },
    label: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing.xs / 2,
      fontSize: theme.fontSizes.xs,
      fontWeight: "bold",
      color: theme.white,
      textAlign: "center",

      ".icon": {
        width: theme.fontSizes.md,
        height: theme.fontSizes.md
      }
    }
  };
});

export default SmallBanner;
