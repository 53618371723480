import { Modal } from "@mantine/core";
import AddStakepoolForm from "./AddStakepoolForm";

const AddStakepoolModal = ({ addForm, opened, onCreate, onClose }) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Add new pool"
      centered
      withCloseButton
    >
      <AddStakepoolForm form={addForm} onCreate={onCreate} />
    </Modal>
  );
};

export default AddStakepoolModal;
