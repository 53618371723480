class WalletBase {
  constructor({ network, onConnect, onDisconnect }) {
    this.network = network;
    this.onConnect = onConnect;
    this.onDisconnect = onDisconnect;

    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.setNetwork = this.setNetwork.bind(this);
    this.getNetwork = this.getNetwork.bind(this);
  }

  async connect(wallet) {
    this.onConnect(wallet);
  }

  async disconnect() {
    this.onDisconnect();
  }

  async checkExtensionPresence() {
    return false;
  }

  setNetwork(network) {
    this.network = network;
  }

  getNetwork() {
    return this.network;
  }
}

export default WalletBase;
