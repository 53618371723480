import { Fragment } from "react";
import { Paper, Text } from "@mantine/core";
import BreadcrumbsComponent from "../../components/reusable/BreadcrumbsComponent";
import { useNavigate } from "react-router-dom";
import ProjectForm from "../../forms/projectForm";
import useCreateForm from "../../hooks/useCreateForm";
import { findInFormValues } from "../../forms/formUtils";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons";
import utilities from "../../utils/utilities";
import {
  DEFAULT_NOTIFICATION_DELAY,
  MEDIA_DEFAULT_NAMES,
  PROJECT_TYPES
} from "../../utils/constants";
import {
  useCreateProjectMutation,
  useUploadMediaMutation
} from "../../APIs/Aws.api";

const CreateProject = () => {
  const navigate = useNavigate();
  const [uploadMedia] = useUploadMediaMutation();
  const [createProject] = useCreateProjectMutation();

  const breadcrumbsItems = [
    { title: "Projects", href: "/projects" },
    { title: "Create", href: "#", disabled: true }
  ];

  const uploadMediaFiles = async (payload) => {
    const { key, logo, thumbnail } = payload;
    await uploadMedia({
      projectKey: key,
      file: logo,
      fileName: MEDIA_DEFAULT_NAMES.LOGO
    });
    await uploadMedia({
      projectKey: key,
      file: thumbnail,
      fileName: MEDIA_DEFAULT_NAMES.THUMBNAIL
    });

    return true;
  };

  const getFormValue = (form, path) => {
    try {
      return findInFormValues(form, path).value;
    } catch (e) {
      console.error("Unable to find form value for: ", path);
    }
  };

  const handleOnSubmit = async (payload) => {
    const projectType = getFormValue(payload, "core.projectType");
    const projectName = getFormValue(payload, "general.name");
    const descriptionFile = getFormValue(payload, "media.description");
    const descriptionString = await utilities
      .fileReader()
      .readAsText(descriptionFile);

    const optionalFields = {
      author: getFormValue(payload, "general.author"),
      saleLabel: getFormValue(payload, "general.saleLabel"),
      privateAccessTo: getFormValue(payload, "general.privateAccessTo"),
      initialMarketCapUsd: getFormValue(
        payload,
        "projectTypeInfo.initialMarketCapUsd"
      ),
      initialTokenCirculation: getFormValue(
        payload,
        "projectTypeInfo.initialTokenCirculation"
      ),
      ipfsGateway: getFormValue(payload, "projectTypeInfo.ipfsGateway"),
      whitelistStartDate: getFormValue(payload, "timeline.whitelistStartDate"),
      whitelistEndDate: getFormValue(payload, "timeline.whitelistEndDate"),
      whitelistUrl: getFormValue(payload, "projectTypeInfo.whitelistUrl"),
      whitelistMerkle: getFormValue(payload, "projectTypeInfo.whitelistMerkle"),
      startDate: getFormValue(payload, "timeline.startDate"),
      endDate: getFormValue(payload, "timeline.endDate"),
      redeemDate: getFormValue(payload, "timeline.redeemDate")
    };

    const commonFields = {
      ...(optionalFields.author && { author: optionalFields.author }),
      ...(optionalFields.saleLabel && { saleLabel: optionalFields.saleLabel }),
      ...(optionalFields.privateAccessTo && {
        privateAccessTo: optionalFields.privateAccessTo
      }),
      ...(optionalFields.startDate && { startDate: optionalFields.startDate }),
      ...(optionalFields.endDate && { endDate: optionalFields.endDate }),
      ...(optionalFields.whitelistStartDate && {
        whitelistStartDate: optionalFields.whitelistStartDate
      }),
      ...(optionalFields.whitelistEndDate && {
        whitelistEndDate: optionalFields.whitelistEndDate
      }),
      ...(optionalFields.whitelistUrl && {
        whitelistUrl: optionalFields.whitelistUrl
      }),
      ...(optionalFields.whitelistMerkle && {
        whitelistMerkle: optionalFields.whitelistMerkle
      }),
      name: projectName,
      type: projectType.toUpperCase(),
      key: projectName.toLowerCase().replaceAll(" ", "-"),
      status: getFormValue(payload, "core.status"),
      subTitle: getFormValue(payload, "general.subtitle"),
      totalRaiseUsd: getFormValue(payload, "general.totalRaiseUsd"),
      logo: getFormValue(payload, "media.logo"),
      thumbnail: getFormValue(payload, "media.thumbnail"),
      description: utilities.escapeMarkdownString(descriptionString)
    };

    const payloadMapping = {
      [PROJECT_TYPES.ido.value]: {
        ...commonFields,
        ...(optionalFields.initialMarketCapUsd && {
          initialMarketCapUsd: optionalFields.initialMarketCapUsd
        }),
        ...(optionalFields.initialTokenCirculation && {
          initialTokenCirculation: optionalFields.initialTokenCirculation
        }),
        ...(optionalFields.redeemDate && {
          redeemDate: optionalFields.redeemDate
        }),
        tokenName: getFormValue(payload, "tokenInfo.name"),
        tokenPriceInUsd: getFormValue(payload, "tokenInfo.priceInUsd"),
        tokenSymbol: getFormValue(payload, "tokenInfo.symbol"),
        whitelistUrl: getFormValue(payload, "projectTypeInfo.whitelistUrl"),
        maxAllocationUsd: getFormValue(
          payload,
          "projectTypeInfo.maxAllocationUsd"
        )
      },
      [PROJECT_TYPES.ino.value]: {
        ...commonFields,
        ...(optionalFields.ipfsGateway && {
          ipfsGateway: optionalFields.ipfsGateway
        }),
        nftsOnSale: getFormValue(payload, "projectTypeInfo.nftsOnSale")
      },
      [PROJECT_TYPES.spool.value]: {
        ...commonFields
      }
    };

    payload = payloadMapping[projectType];

    try {
      showNotification({
        id: "create-project",
        loading: true,
        title: "Creating a new project",
        message: "This process can take a few seconds",
        autoClose: false,
        disallowClose: true
      });

      await uploadMediaFiles(payload);

      //Get rid of unnecessary fields and create the project
      delete payload.logo;
      delete payload.thumbnail;

      await createProject({ payload })
        .unwrap()
        .then(() => {
          updateNotification({
            id: "create-project",
            color: "teal",
            title: "Success",
            message: "The project has been successfully created",
            icon: <IconCheck size={16} />,
            autoClose: DEFAULT_NOTIFICATION_DELAY
          });

          navigate("/projects");
        })
        .then((error) => {
          if (error) {
            updateNotification({
              id: "create-project",
              color: "orange",
              title: "Conflict",
              message: error?.error || error,
              icon: <IconX size={16} />,
              autoClose: DEFAULT_NOTIFICATION_DELAY
            });

            return error;
          }

          return null;
        });
    } catch (e) {
      console.error(e);
      updateNotification({
        id: "create-project",
        color: "red",
        title: "Cannot create the project",
        message: `${e.message}. Check the console for more details`,
        icon: <IconX size={16} />,
        autoClose: 4000
      });
    }
  };

  const createForm = useCreateForm({
    formSchema: ProjectForm,
    onSubmit: handleOnSubmit
  });

  return (
    <Fragment>
      <BreadcrumbsComponent routes={breadcrumbsItems} />

      <Paper p="md" mt={20} mb={20}>
        <Text size={22} weight="bold">
          Create new project
        </Text>
        {createForm}
      </Paper>
    </Fragment>
  );
};

export default CreateProject;
