import { Fragment, useRef, useState } from "react";
import {
  Modal,
  Button,
  MultiSelect,
  ActionIcon,
  createStyles,
  Paper,
  Text,
  TextInput,
  Tooltip,
  Center
} from "@mantine/core";
import TabbedContent from "../../../components/reusable/TabbedContent";
import { IconPlus, IconTrash, IconX } from "@tabler/icons";
import { useForm } from "@mantine/form";
import {
  DEFAULT_NOTIFICATION_DELAY,
  POOL_TYPES,
  REGEX_PATTERNS
} from "../../../utils/constants";
import { showNotification, updateNotification } from "@mantine/notifications";
import { openConfirmModal } from "@mantine/modals";
import { withToggleOnEdit } from "../../../utils/contexts";
import { useDeleteContractInfoMutation } from "../../../APIs/Aws.api";
import ContractTab from "./ContractTab";
import IDOContractInfo from "./IDOContractInfo";

const PoolsCard = ({
  projectKey,
  canAddContract,
  onUpdatePools,
  pools = []
}) => {
  const { classes } = useStyles();
  const tabbedContentRef = useRef();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteContractInfo] = useDeleteContractInfoMutation();

  const newPoolForm = useForm({
    initialValues: {
      contractId: "",
      name: "",
      type: POOL_TYPES.basicPool.value
    },

    validate: {
      contractId: (value) =>
        new RegExp(REGEX_PATTERNS.hederaAccount).test(value)
          ? null
          : "Invalid contract address",
      name: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      type: (value) => (value.length === 0 ? "Pool type is required" : null)
    }
  });

  const handleCreateNewPool = (values) => {
    const exists = pools.value.find(
      (pool) => pool.contractId === values.contractId
    );

    if (exists) {
      showNotification({
        id: "create-pool",
        color: "orange",
        title: "Conflict",
        message: "There is 1 existing pool for this contract ID",
        icon: <IconX size={16} />,
        autoClose: 4000
      });

      return;
    }

    pools.onChange([...pools.value, values]);

    newPoolForm.reset();
    setCreateModalOpen(false);
  };

  const handleDeletePool = async (key, contractId) => {
    try {
      showNotification({
        id: "delete-pool",
        loading: true,
        title: `Removing pool for contract ID: ${contractId}`,
        message: "This process can take a few seconds",
        autoClose: false,
        disallowClose: true
      });

      const res = await deleteContractInfo({ projectKey: key, contractId });

      if (res) {
        updateNotification({
          id: "delete-pool",
          color: "teal",
          title: "Successfully removed",
          icon: <IconTrash size={16} />,
          autoClose: DEFAULT_NOTIFICATION_DELAY
        });

        const updatedPools = pools.value.filter(
          (pool) => pool.contractId !== contractId
        );

        // using the imperative handler that exposes activeTab and setActiveTab from TabbedContent component
        // now we have access to update the activeTab on the child component
        if (tabbedContentRef.current) {
          const { current: tabbedContent } = tabbedContentRef;

          if (tabbedContent.setActiveTab === "function") {
            const index =
              tabbedContent.activeTab > updatedPools.length - 1
                ? updatedPools.length
                : tabbedContent.activeTab;

            tabbedContentRef.current.setActiveTab(index);
          }
        }
      }
    } catch (e) {
      console.error("Could not delete de pool.", e);

      updateNotification({
        id: "delete-pool",
        color: "orange",
        title: "Conflict",
        message: e.message,
        icon: <IconX size={16} />,
        autoClose: DEFAULT_NOTIFICATION_DELAY
      });
    }
  };

  const onDeleteConfirmation = ({ name, contractId }) =>
    openConfirmModal({
      title: "Please confirm your action",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete {name}? You can't undo this action
          afterwards.
        </Text>
      ),
      labels: {
        confirm: "Delete pool",
        cancel: "No don't delete it"
      },
      confirmProps: { color: "red" },
      onConfirm: () => handleDeletePool(projectKey.value, contractId)
    });

  const poolTypeOptions = Object.keys(POOL_TYPES).map((key) => ({
    value: POOL_TYPES[key].value,
    label: POOL_TYPES[key].label
  }));

  const poolsTabs = pools.value?.map((pool) => ({
    key: pool.contractId,
    label: (
      <ContractTab
        contract={pool}
        onDeleteConfirmation={onDeleteConfirmation}
      />
    ),
    content: <IDOContractInfo contract={pool} onUpdate={onUpdatePools} />
  }));

  const AddButton = withToggleOnEdit(ActionIcon);

  return (
    <Fragment>
      <Modal
        opened={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        title="Create new pool"
        centered
        withCloseButton
      >
        <form onSubmit={newPoolForm.onSubmit(handleCreateNewPool)}>
          <TextInput
            label="Contract ID"
            placeholder="0.0.xxxxxxx"
            {...newPoolForm.getInputProps("contractId")}
          />
          <TextInput
            mt="sm"
            label="Name"
            placeholder="Basic pool"
            {...newPoolForm.getInputProps("name")}
          />
          <MultiSelect
            label="Pool type"
            placeholder="-"
            mt="sm"
            data={poolTypeOptions}
            defaultValue={[POOL_TYPES.basicPool.value]}
            {...newPoolForm.getInputProps("type")}
          />
          <Button type="submit" mt="sm">
            Create
          </Button>
        </form>
      </Modal>
      <Paper p="md" radius="md" className={classes.card} withBorder>
        <Text mb={20}>Pools</Text>
        <Tooltip
          label={`${canAddContract ? "Add a new pool" : "The project must be published in order to add a new storefront"}`}
        >
          <AddButton
            className={classes.addBtn}
            variant="default"
            radius="md"
            size={36}
            onClick={() => setCreateModalOpen(true)}
            disabled={!canAddContract}
          >
            <IconPlus size={18} className={classes.addBtnIcon} stroke={1.5} />
          </AddButton>
        </Tooltip>
        {pools.value?.length > 0 ? (
          <TabbedContent ref={tabbedContentRef} tabs={poolsTabs} />
        ) : (
          <Paper py="4rem" withBorder>
            <Center>
              <Text size="lg" color="dimmed" weight="bold">
                There are no pools.
              </Text>
            </Center>
          </Paper>
        )}
      </Paper>
    </Fragment>
  );
};

const useStyles = createStyles((theme) => {
  return {
    card: {
      position: "relative"
    },

    addBtn: {
      position: "absolute",
      top: theme.spacing.xs,
      right: theme.spacing.xs + 2
    },

    addBtnIcon: {
      color: theme.colors.gray[5]
    }
  };
});

export default PoolsCard;
