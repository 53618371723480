import {
  Timeline,
  Text,
  createStyles,
  Group,
  ActionIcon,
  Tooltip,
  Select,
  Button,
  Stack,
  Space,
  Center
} from "@mantine/core";
import { Fragment, useState } from "react";
import {
  IconAlertTriangle,
  IconClipboardList,
  IconFileOff,
  IconHandStop,
  IconRefresh,
  IconReportMoney,
  IconTruckDelivery,
  IconWreckingBall,
  IconX
} from "@tabler/icons";
import EditableDate from "../../../components/editable/EditableDate";
import { withToggleOnEdit } from "../../../utils/contexts";
import { openModal, closeAllModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { PROJECT_TYPES } from "../../../utils/constants";

const TimelineComponent = ({ projectType, timelineStages, contracts = [] }) => {
  const {
    whitelistStartDate,
    whitelistEndDate,
    startDate,
    endDate,
    redeemDate
  } = timelineStages || {};
  const { classes } = useStyles();
  const hasContractInfo = contracts.some((contract) => !!contract.contractInfo);

  const composeTimelineData = () => {
    const _stages = [
      {
        label: "Under preparation",
        date: null,
        icon: IconWreckingBall
      },
      whitelistStartDate && {
        label: "Allowlist Open",
        date: whitelistStartDate,
        icon: IconClipboardList
      },
      whitelistEndDate && {
        label: "Allowlist Closed",
        date: whitelistEndDate,
        icon: IconFileOff
      },
      startDate && {
        syncWithContract: "startTime",
        label: "Sale Start Time",
        date: startDate,
        icon: IconReportMoney
      },
      endDate && {
        syncWithContract:
          projectType === PROJECT_TYPES.ido.label ? "endTime" : "saleEndTime",
        label: "Sale End Time",
        date: endDate,
        icon: IconHandStop
      },
      redeemDate && {
        syncWithContract: "redeemTime",
        label: "Token distribution",
        date: redeemDate,
        icon: IconTruckDelivery
      }
    ].filter(Boolean);

    const now = Math.floor(Date.now() / 1000);
    const closestDate = Math.max.apply(
      null,
      _stages.map(({ date }) => date?.value || 0).filter((v) => v <= now)
    );
    const currentStage = _stages.find(
      (stage) => stage.date?.value === (closestDate || null)
    );

    return {
      stages: _stages,
      current: _stages.indexOf(currentStage) || 0
    };
  };

  const checkDateSync = ({ syncWithContract, date }) => {
    if (!contracts.length || !syncWithContract)
      return {
        sync: [],
        notSync: []
      };

    return {
      sync: contracts
        .filter(
          (contract) => contract.contractInfo?.[syncWithContract] === date.value
        )
        .map(({ contractId }) => contractId),
      notSync: contracts
        .filter(
          (contract) => contract.contractInfo?.[syncWithContract] !== date.value
        )
        .map(({ contractId }) => contractId)
    };
  };

  const handleSyncDate = ({ syncWithContract, date }) => {
    const onSync = (_contractId) => {
      const contract = contracts.find(
        ({ contractId }) => contractId === _contractId
      );

      if (!contract) {
        showNotification({
          id: "sync-dates",
          color: "red",
          title: "Sync dates",
          message: `Couldn't find any contract with: ${_contractId}`,
          icon: <IconX size={16} />,
          autoClose: 4000
        });

        return;
      }

      const dateToSyncWith = contract.contractInfo[syncWithContract];

      if (!dateToSyncWith) {
        showNotification({
          id: "sync-dates",
          color: "red",
          title: "Sync dates",
          message: `Unable to find '${syncWithContract}' on contract ${_contractId}`,
          icon: <IconX size={16} />,
          autoClose: 4000
        });

        return;
      }

      date.onChange(dateToSyncWith);
    };

    openModal({
      title: "Sync with contract date",
      children: <DateSyncModal contracts={contracts} onSync={onSync} />
    });
  };

  const { stages, current } = composeTimelineData();
  const SyncDatesButton = withToggleOnEdit(ActionIcon);

  return (
    <Fragment>
      <Text mb={20}>Timeline</Text>
      {
        <Timeline active={current} bulletSize={26} lineWidth={2} pr={30}>
          {stages.map((stage, index) => {
            const { label, date, icon } = stage;
            const IconComponent = icon;
            const isUnderPreparation = date === null;
            const availableDate = date?.value
              ? new Date(Math.floor(date.value * 1000)).toString()
              : "-";
            //TODO: use moment.js to pretty print the date
            const dateFormat = isUnderPreparation ? "N/A" : availableDate;

            const contractDates = hasContractInfo && checkDateSync(stage);
            const tooltipLabel =
              contractDates &&
              (() => {
                switch (true) {
                  case contractDates.sync.length === 0:
                    return `There is not contract in sync with this date. It must be synchronized.`;
                  case contractDates.sync.length > 0 &&
                    contractDates.notSync.length > 0:
                    return (
                      <Center>
                        <Stack spacing={5}>
                          <Text weight="bold">Synchronised:</Text>
                          <Stack spacing={0}>
                            {contractDates.sync.map((contractId) => (
                              <Text key={contractId}>{contractId}</Text>
                            ))}
                          </Stack>
                          <Space />
                          <Text weight="bold">Not synchronized:</Text>
                          <Stack spacing={0}>
                            {contractDates.notSync.map((contractId) => (
                              <Text key={contractId}>{contractId}</Text>
                            ))}
                          </Stack>
                        </Stack>
                      </Center>
                    );
                  default:
                    return "This date must be in sync with the contract";
                }
              })();

            const title = (
              <Group spacing={8}>
                <Text>{label}</Text>
                {contractDates && contractDates.notSync.length > 0 && (
                  <Group spacing={5}>
                    <Tooltip label={tooltipLabel}>
                      <ActionIcon
                        size="xs"
                        variant="filled"
                        color={`${contractDates.sync.length > 0 ? "yellow" : "red"}`}
                      >
                        <IconAlertTriangle size="1.2rem" />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label={`Sync with contract date`}>
                      <SyncDatesButton
                        size="xs"
                        variant="subtle"
                        color="green"
                        onClick={() => handleSyncDate(stage)}
                      >
                        <IconRefresh />
                      </SyncDatesButton>
                    </Tooltip>
                  </Group>
                )}
              </Group>
            );

            return (
              <Timeline.Item
                key={`checkpoint-${index}`}
                title={title}
                bullet={<IconComponent size={18} />}
              >
                <EditableDate className={classes.editableDate} {...date}>
                  <Text size="xs" mt={4}>
                    {dateFormat}
                  </Text>
                </EditableDate>
              </Timeline.Item>
            );
          })}
        </Timeline>
      }
    </Fragment>
  );
};

const DateSyncModal = ({ contracts, onSync }) => {
  const [contractToSyncDates, setContractToSyncDates] = useState(
    contracts?.[0].contractId || null
  );

  const handleOnChange = (contractId) => setContractToSyncDates(contractId);

  const handleOnSync = () => {
    onSync(contractToSyncDates);
    closeAllModals();
  };

  const selectData = contracts.map((contract) => ({
    value: contract.contractId,
    label: `${contract.contractId} (${contract.contractInfo.token.symbol})`
  }));

  return (
    <Fragment>
      <Select
        label="Contract ID:"
        placeholder="Select contract"
        data={selectData}
        value={contractToSyncDates}
        onChange={handleOnChange}
      />
      <Button
        mt="md"
        onClick={handleOnSync}
        disabled={!contractToSyncDates}
        fullWidth
      >
        Sync
      </Button>
    </Fragment>
  );
};

const useStyles = createStyles(() => {
  return {
    editableDate: {
      ".edit-btn": {
        top: 0,
        right: -30,
        width: 22,
        minWidth: 22,
        height: 22,
        minHeight: 22,
        transform: "translateY(10%)",

        ".edit-btn-icon": {
          width: "12px",
          height: "12px"
        }
      }
    }
  };
});

export default TimelineComponent;
