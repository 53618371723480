import { Fragment, useRef, useState } from "react";
import {
  Modal,
  Button,
  ActionIcon,
  createStyles,
  Paper,
  Text,
  TextInput,
  MultiSelect,
  Tooltip,
  Center
} from "@mantine/core";
import TabbedContent from "../../../components/reusable/TabbedContent";
import { IconPlus, IconTrash, IconX } from "@tabler/icons";
import { useForm } from "@mantine/form";
import {
  DEFAULT_NOTIFICATION_DELAY,
  REGEX_PATTERNS,
  STOREFRONT_TYPES
} from "../../../utils/constants";
import { showNotification, updateNotification } from "@mantine/notifications";
import { openConfirmModal } from "@mantine/modals";
import { withToggleOnEdit } from "../../../utils/contexts";
import { useDeleteContractInfoMutation } from "../../../APIs/Aws.api";
import ContractTab from "./ContractTab";
import INOContractInfo from "./INOContractInfo";

const StorefrontsCard = ({
  projectKey,
  canAddContract,
  onUpdateStorefronts,
  storefronts = []
}) => {
  const { classes } = useStyles();
  const tabbedContentRef = useRef();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteContractInfo] = useDeleteContractInfoMutation();

  const newStorefrontForm = useForm({
    initialValues: { contractId: "", type: STOREFRONT_TYPES.default.value },

    validate: {
      contractId: (value) =>
        new RegExp(REGEX_PATTERNS.hederaAccount).test(value)
          ? null
          : "Invalid contract address",
      type: (value) =>
        value.length === 0 ? "Storefront type is required" : null
    }
  });

  const handleCreateNewStorefront = (values) => {
    const exists = storefronts.value.find(
      (storefront) => storefront.contractId === values.contractId
    );

    if (exists) {
      showNotification({
        id: "create-storefront",
        color: "orange",
        title: "Conflict",
        message: "There is 1 existing storefront for this contract ID",
        icon: <IconX size={16} />,
        autoClose: 4000
      });

      return;
    }

    storefronts.onChange([...storefronts.value, values]);

    newStorefrontForm.reset();
    setCreateModalOpen(false);
  };

  const handleDeleteStorefront = async (key, contractId) => {
    try {
      showNotification({
        id: "delete-storefront",
        loading: true,
        title: `Removing storefront for contract ID: ${contractId}`,
        message: "This process can take a few seconds",
        autoClose: false,
        disallowClose: true
      });

      const res = await deleteContractInfo({ projectKey: key, contractId });

      if (res) {
        updateNotification({
          id: "delete-storefront",
          color: "teal",
          title: "Successfully removed",
          icon: <IconTrash size={16} />,
          autoClose: DEFAULT_NOTIFICATION_DELAY
        });

        const updatedStorefonts = storefronts.value.filter(
          (storefront) => storefront.contractId !== contractId
        );

        // using the imperative handler that exposes activeTab and setActiveTab from TabbedContent component
        // now we have access to update the activeTab on the child component
        if (tabbedContentRef.current) {
          const { current: tabbedContent } = tabbedContentRef;

          if (tabbedContent.setActiveTab === "function") {
            const index =
              tabbedContent.activeTab > updatedStorefonts.length - 1
                ? updatedStorefonts.length
                : tabbedContent.activeTab;

            tabbedContentRef.current.setActiveTab(index);
          }
        }
      }
    } catch (e) {
      console.error("Could not delete the storefront.", e);

      updateNotification({
        id: "delete-storefront",
        color: "orange",
        title: "Conflict",
        message: e.message,
        icon: <IconX size={16} />,
        autoClose: DEFAULT_NOTIFICATION_DELAY
      });
    }
  };

  const onDeleteConfirmation = ({ name, contractId }) =>
    openConfirmModal({
      title: "Please confirm your action",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete {contractId}? You can't undo this
          action afterwards.
        </Text>
      ),
      labels: {
        confirm: "Delete storefront",
        cancel: "No don't delete it"
      },
      confirmProps: { color: "red" },
      onConfirm: () => handleDeleteStorefront(projectKey.value, contractId)
    });

  const storefrontTypeOptions = Object.keys(STOREFRONT_TYPES).map((key) => ({
    value: STOREFRONT_TYPES[key].value,
    label: STOREFRONT_TYPES[key].label
  }));

  const storefrontsTabs = storefronts.value?.map((storefront) => ({
    key: storefront.contractId,
    label: (
      <ContractTab
        contract={storefront}
        onDeleteConfirmation={onDeleteConfirmation}
      />
    ),
    content: (
      <INOContractInfo contract={storefront} onUpdate={onUpdateStorefronts} />
    )
  }));

  const AddButton = withToggleOnEdit(ActionIcon);

  return (
    <Fragment>
      <Modal
        opened={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        title="Create new storefront"
        centered
        withCloseButton
      >
        <form onSubmit={newStorefrontForm.onSubmit(handleCreateNewStorefront)}>
          <TextInput
            label="Contract ID"
            placeholder="0.0.xxxxxxx"
            {...newStorefrontForm.getInputProps("contractId")}
          />
          <MultiSelect
            label="Pool type"
            placeholder="-"
            mt="sm"
            data={storefrontTypeOptions}
            defaultValue={[STOREFRONT_TYPES.default.value]}
            {...newStorefrontForm.getInputProps("type")}
          />
          <Button type="submit" mt="sm">
            Create
          </Button>
        </form>
      </Modal>
      <Paper p="md" radius="md" className={classes.card} withBorder>
        <Text mb={20}>Storefronts</Text>
        <Tooltip
          label={`${canAddContract ? "Add a new storefront" : "The project must be published in order to add a new contract"}`}
        >
          <AddButton
            className={classes.addBtn}
            variant="default"
            radius="md"
            size={36}
            onClick={() => setCreateModalOpen(true)}
            disabled={!canAddContract}
          >
            <IconPlus size={18} className={classes.addBtnIcon} stroke={1.5} />
          </AddButton>
        </Tooltip>

        {storefronts.value?.length > 0 ? (
          <TabbedContent ref={tabbedContentRef} tabs={storefrontsTabs} />
        ) : (
          <Paper py="4rem" withBorder>
            <Center>
              <Text size="lg" color="dimmed" weight="bold">
                There are no storefronts.
              </Text>
            </Center>
          </Paper>
        )}
      </Paper>
    </Fragment>
  );
};

const useStyles = createStyles((theme) => {
  return {
    card: {
      position: "relative"
    },

    addBtn: {
      position: "absolute",
      top: theme.spacing.xs,
      right: theme.spacing.xs + 2
    },

    addBtnIcon: {
      color: theme.colors.gray[5]
    }
  };
});

export default StorefrontsCard;
