import { useMemo, useState } from "react";
import WithEditableField from "../../HOCs/withEditableField";
import DateAndTimePicker from "../reusable/DateAndTimePicker";

const EditableDate = ({
  className,
  value,
  onChange,
  onFocus,
  children,
  ...props
}) => {
  const [isEdit, setEdit] = useState(false);
  const Editable = useMemo(() => WithEditableField(TextComponent, "value"), []);

  const handleOnChange = (date) => {
    onChange(date);
    setEdit(false);
  };

  return isEdit ? (
    <DateAndTimePicker
      className={`${className} editable`}
      placeholder={children}
      value={value}
      onChange={handleOnChange}
      onClose={() => setEdit(false)}
      onFocus={onFocus}
      autoFocus={true}
      {...props}
    />
  ) : (
    <Editable
      className={`${className} editable`}
      value={value}
      onEdit={() => setEdit(true)}
    >
      {children}
    </Editable>
  );
};

const TextComponent = ({ children }) => <div>{children}</div>;

export default EditableDate;
