import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Tabs } from "@mantine/core";

const TabbedContent = forwardRef(({ tabs = [], onTabChange = null }, ref) => {
  const [activeTab, setActiveTab] = useState(tabs[0]?.key);

  const handleOnTabChange = (index) => {
    setActiveTab(index);

    if ("function" === typeof onTabChange) {
      onTabChange(index);
    }
  };

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(tabs[0]?.key);
    }
  }, [tabs, activeTab]);

  useImperativeHandle(ref, () => ({
    activeTab,
    setActiveTab: (index) => setActiveTab(index)
  }));

  return (
    <Tabs
      color="teal"
      ref={ref}
      value={activeTab}
      onTabChange={handleOnTabChange}
    >
      <Tabs.List>
        {tabs.map((tab) => {
          return (
            <Tabs.Tab key={tab.key} value={tab.key} color="blue">
              {tab.label}
            </Tabs.Tab>
          );
        })}
      </Tabs.List>

      {tabs.map((tab) => {
        return (
          tab.key === activeTab && (
            <Tabs.Panel key={tab.key} pt="xs" value={tab.key} color="blue">
              {tab.content}
            </Tabs.Panel>
          )
        );
      })}
    </Tabs>
  );
});

export default TabbedContent;
