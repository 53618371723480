import { Fragment } from "react";
import { Menu, Button, Group, Text, Loader } from "@mantine/core";
import { IconCaretDown, IconPencil } from "@tabler/icons";
import {
  withToggleOnView,
  withOnPageToggleView
} from "../../../utils/contexts";
import { NavLink } from "react-router-dom";

const ProjectActions = ({
  hasChanges,
  actions,
  initialProjectStatus,
  projectKey,
  projectStatus,
  onSave,
  onReset
}) => {
  const menuItems = actions.slice(
    actions.findIndex((item) => item.value === initialProjectStatus) || 0,
    actions.length
  );

  const ToggledEditButton = withToggleOnView(EditBtn);
  const ToggledSaveActions = withOnPageToggleView(SaveActions, [
    "/projects/create",
    "/projects/edit"
  ]);

  return (
    <Menu shadow="md" position="bottom-end">
      <Group mt="sm" pl="sm" pr="sm" position="right" spacing={10}>
        <ToggledEditButton projectKey={projectKey} />
        <ToggledSaveActions
          actions={actions}
          projectStatus={projectStatus}
          initialProjectStatus={initialProjectStatus}
          canReset={hasChanges}
          onSave={onSave}
          onReset={onReset}
        />
      </Group>

      <Menu.Dropdown>
        {menuItems.map((item) => {
          return (
            <Menu.Item
              key={item.value}
              icon={<item.icon size={22} />}
              onClick={() => projectStatus?.onChange(item.value)}
              disabled={item.disabled}
            >
              <Group noWrap>
                <div>
                  <Text size="sm">{item.label}</Text>
                  <Text size="xs" color="dimmed">
                    {item.description}
                  </Text>
                </div>
              </Group>
            </Menu.Item>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
};

const EditBtn = ({ projectKey }) => {
  return (
    <Button component={NavLink} to={`/projects/edit/${projectKey.value}`}>
      <IconPencil size={18} />
      <Text ml={5}>Edit</Text>
    </Button>
  );
};

const SaveActions = ({
  actions,
  projectStatus,
  initialProjectStatus,
  canReset,
  onSave,
  onReset
}) => {
  const selected =
    actions.find((item) => item.value === projectStatus.value) || null;
  const isPublished =
    initialProjectStatus === actions[actions.length - 1].value;

  return selected ? (
    <Fragment>
      {typeof onReset === "function" && (
        <Button
          variant="outline"
          color="gray"
          onClick={onReset}
          disabled={!canReset}
        >
          Reset
        </Button>
      )}

      {isPublished ? (
        <Button type="submit" onClick={onSave}>
          <selected.icon size={18} />
          <Text ml={5}>{selected?.label}</Text>
        </Button>
      ) : (
        <Button.Group>
          <Button type="submit" onClick={onSave}>
            <selected.icon size={18} />
            <Text ml={5}>{selected?.label}</Text>
          </Button>

          <Menu.Target>
            <Button size="sm" p={5}>
              <IconCaretDown />
            </Button>
          </Menu.Target>
        </Button.Group>
      )}
    </Fragment>
  ) : (
    <Loader size={25} />
  );
};

export default ProjectActions;
