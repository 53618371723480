import { Breadcrumbs, Anchor, createStyles } from "@mantine/core";
import { Link } from "react-router-dom";

const BreadcrumbsComponent = ({ routes }) => {
  const useStyles = createStyles((theme) => ({
    last: {
      cursor: "not-allowed",
      pointerEvents: "none",
      color: theme.colors.gray[6]
    }
  }));

  const { classes } = useStyles();

  const items = routes.map((item, index) => (
    <Anchor
      className={index === routes.length - 1 ? classes.last : ""}
      key={index}
      component={Link}
      to={item.href}
    >
      {item.title}
    </Anchor>
  ));

  return <Breadcrumbs>{items}</Breadcrumbs>;
};

export default BreadcrumbsComponent;
