import { ActionIcon, CopyButton, Group, Tooltip } from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons";

const CopyableText = ({
  text,
  copy,
  children,
  size = "xs",
  iconSize = "1rem"
}) => {
  return (
    <CopyButton value={copy || text} timeout={2000}>
      {({ copied, copy }) => (
        <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
          <Group spacing={5}>
            {text ? text : children}
            <ActionIcon
              size={size}
              color={copied ? "teal" : "gray"}
              variant="subtle"
              onClick={copy}
            >
              {copied ? (
                <IconCheck size={iconSize} />
              ) : (
                <IconCopy size={iconSize} />
              )}
            </ActionIcon>
          </Group>
        </Tooltip>
      )}
    </CopyButton>
  );
};

export default CopyableText;
