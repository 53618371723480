import { Group, Stack, Text } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons";

const StatsField = ({ label, value, date, icon }) => {
  const IconComponent = icon ?? IconInfoCircle;

  const formattedValue = date
    ? new Date(Math.floor(date * 1000)).toString()
    : value;

  return (
    <Group spacing={10} size="1.2rem" noWrap>
      <IconComponent />
      <Stack sx={{ flex: 1 }} spacing={0}>
        <Text size="xs" color="dimmed">
          {label || "Date:"}
        </Text>
        <Text size="xs">{formattedValue}</Text>
      </Stack>
    </Group>
  );
};

export default StatsField;
