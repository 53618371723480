const TOKEN_ADDRESS = process.env.REACT_APP_TOKEN_ADDRESS || "";
const TOKEN_SYMBOL = process.env.REACT_APP_TOKEN_SYMBOL || "";

const WalletUtils = {
  getAccountId: function (wallet) {
    if (!wallet) {
      console.error("A wallet is needed in order to retrieve the account ID");
      return;
    }

    return {
      value: wallet.getAccountId(),
      toString: () => wallet.getAccountId().toString() || "-"
    };
  },

  getHbarBalance: function (_balance) {
    if (!_balance?.hbars) {
      return null;
    }

    const { hbars } = _balance;

    return {
      toString: () => hbars.toBigNumber().toNumber().toFixed(3) + " ℏ",
      toNumber: () => hbars.toBigNumber().toNumber()
    };
  },

  getHSTBalance: function (_balance) {
    const { tokenDecimals, tokens } = _balance;
    const hstToken = tokens?.size > 0 && tokens.get(TOKEN_ADDRESS);
    let hstTokenBalance = 0;

    if (hstToken) {
      const hstTokenDecimals = tokenDecimals.get(TOKEN_ADDRESS);
      hstTokenBalance = hstToken.toNumber() / Math.pow(10, hstTokenDecimals);

      return {
        toString: () => `${hstTokenBalance.toFixed(3)} ${TOKEN_SYMBOL}`,
        toNumber: () => hstTokenBalance
      };
    }

    return {
      toString: () => `0 ${TOKEN_SYMBOL}`,
      toNumber: () => 0
    };
  },

  getPlainBalances: function (balance) {
    const hbarBalance = balance && this.getHbarBalance(balance).toString();
    const hstBalance = balance && this.getHSTBalance(balance).toString();

    return {
      hbarBalance,
      hstBalance
    };
  }
};

export default WalletUtils;
