import withEditProjectContext from "../HOCs/withEditProjectContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { forwardRef, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";
import utilities from "./utilities";

const REDIRECT_TIMEOUT = 4000;

/**
 * Exports a component with location, navigate and params
 * @param Component
 * @returns {WrappedComponent}
 */
export const withRouter = (Component) => {
  const ComponentWithRouterProp = forwardRef((props, ref) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <Component {...props} ref={ref} router={{ location, navigate, params }} />
    );
  });

  ComponentWithRouterProp.displayName = `ComponentWithRouterProp(${utilities.getDisplayName(Component)})`;

  return ComponentWithRouterProp;
};

/**
 * Easy redirect to home when mounted.
 * @param router
 * @returns {null}
 * @constructor
 */
const EditRouteRedirecterComponent = ({ router }) => {
  const redirectToHome = useCallback(
    () => router.navigate("/", { replace: true }),
    [router]
  );

  const debouncedRedirect = debounce(redirectToHome, REDIRECT_TIMEOUT);

  useEffect(() => {
    if (!router) {
      console.error(
        "The 'withEditRouteRedirecter' HOC needs to be inside a withRouter to redirect"
      );
    } else {
      showNotification({
        id: "delete-project",
        color: "red",
        title: "Unauthorized",
        message:
          "You are not authorized to view this page. Redirecting to home...",
        icon: <IconX size={16} />,
        autoClose: REDIRECT_TIMEOUT
      });

      debouncedRedirect();
    }
  }, [router, debouncedRedirect]);

  return null;
};

/**
 * Verify if the EditContext has editing flag enabled and mount the component. Otherwise will redirect the user to home
 * @param WrappedComponent
 * @returns {JSX.Element}
 */
const withEditRouteRedirecter = (WrappedComponent) => (props) => {
  return props.editingEnabled ? (
    <WrappedComponent {...props} />
  ) : (
    <EditRouteRedirecterComponent {...props} />
  );
};

/**
 * Will toggle the route according to 'editingEnabled' from EditContext
 * @param WrappedComponent
 * @returns {function(*)}
 */
//TODO: better use 'compose' instead of chaining all the HOCs
export const withEditRouteToggle = (WrappedComponent) =>
  withRouter(withEditProjectContext(withEditRouteRedirecter(WrappedComponent)));

/**
 * Show the component only on a provided piece of pathname
 *
 * Usage: having pagesHash = ['/projects/edit'] and router.location.pathname = '/projects/edit/new-project-name,
 * that means pathname includes the first indexOf pagesHash and will render the component.
 *
 * @param Component
 * @param pagesHash
 * @returns {function(*): JSX.Element|null}
 */
const onPageToggleView = (Component, pagesPathname) => {
  const WithToggleView = forwardRef(({ router, ...rest }, ref) => {
    const {
      location: { pathname }
    } = router;
    let show = false;

    pagesPathname.forEach((hash) => {
      if (pathname.indexOf(hash) > -1) {
        show = true;
      }
    });

    return show ? <Component {...rest} ref={ref} /> : null;
  });

  WithToggleView.displayName = `WithToggleView(${utilities.getDisplayName(Component)})`;

  return WithToggleView;
};
export const withOnPageToggleView = (Component, pagesPathname) =>
  withRouter(onPageToggleView(Component, pagesPathname));

export const withToggleOnCreate = (Component) =>
  withOnPageToggleView(Component, ["/projects/create"]);
export const withToggleOnView = (Component) =>
  withOnPageToggleView(Component, ["/projects/view"]);
export const withToggleOnEdit = (Component) =>
  withOnPageToggleView(Component, ["/projects/edit", "/merkle-whitelist"]);
