import ProjectDescription from "../../views/ProjectPage/components/ProjectDescription";
import { useState, useMemo } from "react";
import WithEditableField from "../../HOCs/withEditableField";

const EditableDescription = ({
  className,
  projectKey,
  description,
  ...props
}) => {
  const [isEdit, setEdit] = useState(false);
  const Editable = useMemo(
    () => WithEditableField(DescriptionComponent, "description"),
    []
  );

  return (
    projectKey && (
      <Editable
        className={`${className} editable`}
        projectKey={projectKey}
        description={description}
        isEdit={isEdit}
        onEdit={() => setEdit(!isEdit)}
        {...props}
      />
    )
  );
};

const DescriptionComponent = (props) => <ProjectDescription {...props} />;

export default EditableDescription;
