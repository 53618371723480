const tryToDisplay = (thing) => {
  if (thing && thing.toNumber) {
    try {
      return thing.toNumber();
    } catch (e) {
      return "ℏ" + thing; //TODO: review this
    }
  }

  if (
    thing &&
    thing.indexOf &&
    thing.indexOf("0x") === 0 &&
    thing.length === 42
  ) {
    return thing;
  }

  if (thing && thing.constructor && thing.constructor.name === "Array") {
    const mostReadable = (v) =>
      ["number", "boolean"].includes(typeof v) ? v : tryToDisplayAsText(v);
    return thing.map(mostReadable);
  }

  return thing;
};

const tryToDisplayAsText = (thing) => tryToDisplay(thing, true);

export { tryToDisplay, tryToDisplayAsText };
