import { cloneDeep } from "lodash";

/**
 * Dynamic append validators for each initial form values
 * @param form
 * @returns formWithValidators
 */
export const withFormValidators = (form) => {
  let validate = cloneDeep(form.initialValues);

  const walkFields = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key].type === "group") {
        obj[key] = { value: obj[key].value };
        return walkFields(obj[key].value);
      } else {
        const validator = obj[key].validator;
        const dependsOn = obj[key].dependsOn;

        obj[key] = {
          value: (value, values) => {
            let shouldValidate = true;

            dependsOn?.length &&
              dependsOn.forEach((dependency) => {
                const field = findInFormValues(values, dependency.key);

                if (field.value.toString() !== dependency.toBe.toString()) {
                  shouldValidate = false;
                }
              });

            if (shouldValidate === false) return null;
            return validator?.(value, values);
          }
        };
      }
    });
  };

  walkFields(validate);

  return {
    ...form,
    validate
  };
};

export const withDynamicFields = (form) => {
  let values = cloneDeep(form.values);

  const walkFields = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key].type === "group") {
        return walkFields(obj[key].value);
      } else {
        if (obj[key].dependsOn?.length) {
          let show = false;

          obj[key].dependsOn.forEach((dependency) => {
            const field = findInFormValues(values, dependency.key);

            if (field.value.toString() === dependency.toBe.toString()) {
              show = true;
            }
          });

          if (show === false) {
            delete obj[key];
          }
        }
      }
    });
  };

  walkFields(values);

  return {
    ...form,
    values
  };
};

/**
 * Insert a new element between each elements in array
 * @param arr
 * @param newElement
 * @returns {*[]}
 */
export const interleave = (arr, newElement) => {
  return [].concat(...arr.map((n) => [n, newElement])).slice(0, -1);
};

/**
 * Deeply extract properties from a form
 * @param values
 * @param path
 * @returns {*}
 */
export const findInFormValues = function (values, path) {
  path = path.replace(/\[(\w+)\]/g, ".$1");
  path = path.replace(/^\./, "");

  let elements = path.split(".");
  elements = interleave(elements, "value");

  for (let i = 0, n = elements.length; i < n; ++i) {
    const k = elements[i];

    if (k in values) {
      values = values[k];
    } else {
      return;
    }
  }

  return {
    element: values,
    value: values.value
  };
};
