import { Stack, Text, Textarea } from "@mantine/core";
import DropzoneInput from "../DropzoneInput";
import TabbedContent from "../TabbedContent";

const CsvEntriesFormField = ({
  field,
  inputProps,
  onClearState,
  csvFile,
  onUploaded,
  ...rest
}) => {
  const tabs = [
    {
      key: "upload-description-markdown",
      label: "Upload CSV",
      content: (
        <DropzoneInput
          accept={{ "text/csv": [".csv"] }}
          multiple={false}
          passClearStateHandler={onClearState}
          {...rest}
          value={csvFile}
          onChange={onUploaded}
        />
      )
    },
    {
      key: "compose-description",
      label: "Text Input",
      content: <Textarea {...inputProps} {...rest} autosize />
    }
  ];

  return (
    <Stack key={field.formRef} pt={5} spacing={5}>
      <Text size="sm" weight={500}>
        {field.value.label}
      </Text>
      <TabbedContent tabs={tabs} />
    </Stack>
  );
};

export default CsvEntriesFormField;
