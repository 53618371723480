import { Fragment } from "react";
import { Accordion, Button, Stack, Text, TextInput } from "@mantine/core";
import TabbedContent from "../../components/reusable/TabbedContent";
import Code from "../../components/reusable/Code";

const ContractExecutorConfig = ({
  executorConfig,
  abiEditorTabs,
  handleChangeContractId,
  handleOnExecute,
  handleChangeAbiInputType
}) => {
  const unableToExecute =
    executorConfig.contractId.length === 0 ||
    executorConfig.abi.length === 0 ||
    executorConfig.errors.contractId ||
    executorConfig.errors.abi;

  return (
    <Fragment>
      <TextInput
        placeholder="0.0.xxxxxx"
        label="Contract ID:"
        value={executorConfig.contractId}
        onChange={handleChangeContractId}
        error={executorConfig.errors.contractId}
      />

      <Stack spacing={5}>
        <Text size={14} weight="bold">
          ABI:
        </Text>
        <TabbedContent
          tabs={abiEditorTabs}
          onTabChange={handleChangeAbiInputType}
        />
      </Stack>

      <Button onClick={handleOnExecute} disabled={unableToExecute}>
        Execute
      </Button>

      <Stack>
        <Accordion>
          <Accordion.Item value="abi-preview">
            <Accordion.Control>
              ABI Preview:{" "}
              {Array.isArray(executorConfig.abi)
                ? `(${executorConfig.abi.length} functions)`
                : ""}
            </Accordion.Control>
            <Accordion.Panel>
              <Code
                style={{ fontSize: "12px" }}
                code={JSON.stringify(executorConfig.abi, null, 2)}
                language="json"
              />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Stack>
    </Fragment>
  );
};

export default ContractExecutorConfig;
