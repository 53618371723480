import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import ProjectUtils from "../utils/projectUtils";

const getFetchOptions = (options = {}) => {
  const headers = {
    ...options.headers,
    Accept: options.contentType || "application/json; charset=UTF-8",
    "x-api-key": process.env.REACT_APP_X_API_KEY
  };

  // Inject basic-authentication component only for lower envs
  // and only if provided through the environment
  if (
    process.env.REACT_APP_ENV !== "production" &&
    process.env.REACT_APP_BASIC_AUTH !== undefined
  ) {
    headers["Authorization"] = process.env.REACT_APP_BASIC_AUTH;
  }

  return {
    method: "GET",
    mode: "cors",
    headers
  };
};

export const awsApi = createApi({
  reducerPath: "AwsAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: "include"
  }),
  keepUnusedDataFor: 10,
  endpoints: (builder) => ({
    getProjectsList: builder.query({
      query: (args = {}) => {
        return {
          url: "/admin/projects/?include=all",
          ...getFetchOptions(args.options)
        };
      },
      providesTags: ["Projects"],
      transformResponse: (projects) => {
        return projects.map((project) => ProjectUtils.withMediaAssets(project));
      }
    }),
    getProjectByKey: builder.query({
      query: (args = {}) => {
        return {
          url: "/admin/projects/" + args.key,
          ...getFetchOptions(args.options)
        };
      },
      providesTags: ["Projects"],
      transformResponse: async (project, _, queryArg) => {
        project = ProjectUtils.withMediaAssets(project);

        if (queryArg.withContractInfo) {
          project = await ProjectUtils.withContractInfo(project);
        }

        return project;
      }
    }),
    createProject: builder.mutation({
      query: (args = {}) => {
        return {
          ...getFetchOptions(args.options),
          url: "/projects",
          body: args.payload,
          method: "POST"
        };
      },
      invalidatesTags: ["Projects"]
    }),
    updateProject: builder.mutation({
      query: (args = {}) => {
        return {
          ...getFetchOptions(args.options),
          url: "/projects/" + args.payload.key,
          body: args.payload,
          method: "PUT"
        };
      },
      invalidatesTags: ["Projects", "ProjectDescription"]
    }),
    deleteProject: builder.mutation({
      query: (args = {}) => {
        return {
          ...getFetchOptions(args.options),
          url: "/projects/" + args.key,
          method: "DELETE"
        };
      },
      invalidatesTags: ["Projects"]
    }),
    uploadMedia: builder.mutation({
      query: ({ projectKey, file, fileName, options } = {}) => {
        return {
          ...getFetchOptions({
            ...options,
            headers: {
              "Content-Type": file.type
            }
          }),
          url: "/projects/" + projectKey + "/media/" + fileName,
          body: file,
          method: "PUT"
        };
      }
    }),
    getDescription: builder.query({
      query: ({ projectKey, fileName, options } = {}) => {
        return {
          url: "/admin/projects/" + projectKey + "/" + fileName,
          ...getFetchOptions({
            ...options,
            "Content-Type": "application/json"
          }),
          responseHandler: (response) => response.text()
        };
      },
      providesTags: ["ProjectDescription"],
      transformResponse: (description) => {
        while (description.endsWith("\n")) {
          description = description.trim();
        }

        return description;
      },
      async onQueryStarted(
        { projectKey, ...patch },
        { dispatch, queryFulfilled }
      ) {
        let patchResult = null;

        try {
          const { data: description } = await queryFulfilled;

          patchResult = dispatch(
            awsApi.util.updateQueryData(
              "getProjectByKey",
              { key: projectKey, withContractInfo: true },
              (draft) => {
                Object.assign(draft, { description });
              }
            )
          );
        } catch (err) {
          console.error(err);
          patchResult?.undo();
        }
      }
    }),
    getContractInfo: builder.query({
      query: ({ contractId, options }) => {
        return {
          url: "/contracts/" + contractId + "?includefields=all",
          ...getFetchOptions(options)
        };
      },
      providesTags: ["Contracts"]
    }),
    toggleContractIngestion: builder.mutation({
      query: ({ contractId, disabled, options } = {}) => {
        return {
          ...getFetchOptions(options),
          url: "/contracts/" + contractId + "/ingestion-toggle",
          body: { disabled },
          method: "POST"
        };
      }
    }),
    uploadContractInfo: builder.mutation({
      query: ({ projectKey, payload, options } = {}) => {
        return {
          ...getFetchOptions(options),
          url: "/projects/" + projectKey + "/contracts",
          body: payload,
          method: "POST"
        };
      }
    }),
    deleteContractInfo: builder.mutation({
      query: ({ projectKey, contractId, options } = {}) => {
        return {
          ...getFetchOptions(options),
          url: "/projects/" + projectKey + "/contracts/" + contractId,
          method: "DELETE"
        };
      },
      invalidatesTags: ["Projects"]
    }),
    uploadMerkleWhitelist: builder.mutation({
      query: ({ projectType, payload, options } = {}) => {
        return {
          ...getFetchOptions(options),
          url: "/merkle/whitelist/" + projectType,
          body: payload,
          method: "POST"
        };
      }
    })
  })
});

export const {
  useGetProjectsListQuery,
  useGetProjectByKeyQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useUploadMediaMutation,
  useGetDescriptionQuery,
  useLazyGetContractInfoQuery,
  useToggleContractIngestionMutation,
  useUploadContractInfoMutation,
  useDeleteContractInfoMutation,
  useUploadMerkleWhitelistMutation
} = awsApi;
