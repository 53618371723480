import { Fragment, useState, createRef, useEffect } from "react";
import { useMantineTheme } from "@mantine/core";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor/dist/theme/toastui-editor-dark.css";
import { Editor, Viewer } from "@toast-ui/react-editor";
import { debounce } from "lodash";

const DEBOUNCE_TIMEOUT = 500;

const MarkdownEditor = ({
  initValue,
  onChange,
  onUploadImage,
  previewOnly = false
}) => {
  const [shouldLoadEditor, setShouldLoadEditor] = useState(true);
  const editorRef = createRef();
  const theme = useMantineTheme();

  const debouncedOnChange = debounce(() => {
    const editorInstance = editorRef.current?.getInstance();

    if (editorInstance) {
      const markdown = editorInstance.getMarkdown();
      onChange(markdown);
    }
  }, DEBOUNCE_TIMEOUT);

  useEffect(() => {
    setShouldLoadEditor(false);

    //TODO: find a better way to re-mount the editor component, and update it's theme
    setTimeout(() => {
      setShouldLoadEditor(true);
    }, 0);
  }, [theme.colorScheme]);

  useEffect(() => {
    return () => {
      const editor = editorRef;
      const instance = editor.current?.getInstance();

      if (instance) {
        instance.destroy();
      }
    };
  }, [editorRef]);

  return (
    shouldLoadEditor && (
      <Fragment>
        {previewOnly ? (
          <Viewer initialValue={initValue} theme={theme.colorScheme} />
        ) : (
          <Editor
            initialValue={initValue}
            previewStyle="tab"
            height="600px"
            initialEditType="markdown"
            usageStatistics={false}
            theme={theme.colorScheme}
            ref={editorRef}
            onChange={debouncedOnChange}
            hooks={{
              addImageBlobHook: onUploadImage
            }}
          />
        )}
      </Fragment>
    )
  );
};

export default MarkdownEditor;
