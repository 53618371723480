import { useState, useContext, useEffect, useCallback } from "react";
import {
  Center,
  CloseButton,
  Group,
  Paper,
  Stack,
  Text,
  ActionIcon,
  Tooltip,
  CopyButton
} from "@mantine/core";
import AbiSelector from "./AbiSelector/AbiSelector";
import { StratoContext } from "../../core/providers/StratoProvider";
import LiveContractExecutor from "../../components/LiveContractExecutor/LiveContractExecutor";
import AbiTextInput from "./AbiSelector/AbiTextInput";
import { REGEX_PATTERNS } from "../../utils/constants";
import { IconCheck, IconEdit, IconShare, IconX } from "@tabler/icons";
import ContractExecutorConfig from "./ContractExecutorConfig";
import AbiDropArea from "./AbiSelector/AbiDropArea";
import utilities from "../../utils/utilities";
import { showNotification } from "@mantine/notifications";

export const ABI_INPUT_TYPE = {
  STATIC_DEFINED: "from-static-defined",
  FILE_INPUT: "from-file-input",
  TEXT_INPUT: "from-text-input"
};

const Executor = ({
  identifier,
  contractId,
  abiFileNameParam,
  selfExecute,
  onDispose
}) => {
  const {
    strato: { session }
  } = useContext(StratoContext);
  const [state, setState] = useState({
    contractId,
    abi: [],
    abiInputType: ABI_INPUT_TYPE.STATIC_DEFINED,
    liveContract: null,
    shareOptions: { contract: contractId },
    errors: {}
  });

  const handleChangeContractId = (e) => {
    const contractId = e.target.value;
    const isContractIdValid = new RegExp(REGEX_PATTERNS.hederaAccount).test(
      contractId
    );

    setState((prevState) => ({
      ...prevState,
      contractId,
      shareOptions: {
        ...prevState.shareOptions,
        contract: contractId
      },
      errors: {
        ...prevState.errors,
        contractId: isContractIdValid ? null : "Invalid contract ID"
      }
    }));
  };

  const handleSetABI = (abi = []) => {
    setState((prevState) => ({ ...prevState, abi }));
  };

  const handleChangeAbiInputType = (abiInputType) => {
    setState((prevState) => ({ ...prevState, abiInputType, errors: {} }));
  };

  const handleUpdateShareLink = useCallback(
    (abiFileNameParam = null) => {
      setState((prevState) => ({
        ...prevState,
        shareOptions: {
          ...prevState.shareOptions,
          ...(abiFileNameParam && { abiFileName: abiFileNameParam }),
          selfExecute: !!state.liveContract
        }
      }));
    },
    [state.liveContract]
  );

  const handleOnExecute = useCallback(async () => {
    try {
      const liveContract = await session.getLiveContract({
        id: state.contractId,
        abi: state.abi
      });

      setState((prevState) => ({
        ...prevState,
        liveContract
      }));
    } catch (e) {
      showNotification({
        id: "get-description",
        color: "red",
        title: "Failed to get live contract",
        message: `${e?.reason || e?.message}. Check the console for more details`,
        icon: <IconX size={16} />,
        autoClose: 4000
      });
    }
  }, [session, state.abi, state.contractId]);

  const handleResetExecution = () => {
    setState((prevState) => ({ ...prevState, liveContract: null }));
  };

  useEffect(() => {
    if (state.abiInputType === ABI_INPUT_TYPE.STATIC_DEFINED) {
      handleUpdateShareLink(null);
    }
  }, [state.liveContract, state.abiInputType, handleUpdateShareLink]);

  useEffect(() => {
    if (session && state.abi?.length && selfExecute) {
      handleOnExecute();
    }
  }, [session, selfExecute, state.abi, handleOnExecute]);

  const abiEditorTabs = [
    {
      key: ABI_INPUT_TYPE.STATIC_DEFINED,
      label: "Static defined",
      content: (
        <Stack>
          <AbiSelector
            abiFileNameParam={abiFileNameParam}
            onConfirmSelection={handleSetABI}
            onUpdateShareLink={handleUpdateShareLink}
          />
        </Stack>
      )
    },
    {
      key: ABI_INPUT_TYPE.FILE_INPUT,
      label: "Upload JSON",
      content: <AbiDropArea handleSetABI={handleSetABI} />
    },
    {
      key: ABI_INPUT_TYPE.TEXT_INPUT,
      label: "Text Input",
      content: (
        <AbiTextInput
          initialValue={JSON.stringify(state.abi, null, 2)}
          onUpdateJSON={(abi) => {
            handleSetABI(abi);
            setState((prevState) => ({
              ...prevState,
              errors: { ...prevState.errors, abi: null }
            }));
          }}
          error={state.errors.abi}
          onError={(e) =>
            setState((prevState) => ({
              ...prevState,
              errors: { ...prevState.errors, abi: e }
            }))
          }
        />
      )
    }
  ];

  const shareLink =
    state.abiInputType === ABI_INPUT_TYPE.STATIC_DEFINED
      ? (() => {
          const baseLink =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
          const queryParams = utilities.objToContractExecutorShareParams(
            state.shareOptions
          );

          return `${baseLink}?${queryParams}`;
        })()
      : null;

  return (
    <Paper mt="lg" p="sm" radius="md" withBorder>
      <Group position="right" spacing={5}>
        {!selfExecute && state.liveContract && (
          <Tooltip label="Edit executor config">
            <ActionIcon onClick={handleResetExecution}>
              <IconEdit size="1rem" />
            </ActionIcon>
          </Tooltip>
        )}

        {state.contractId &&
          state.abiInputType === ABI_INPUT_TYPE.STATIC_DEFINED && (
            <CopyButton value={shareLink}>
              {({ copied, copy }) => (
                <Tooltip label="Get share link">
                  <ActionIcon onClick={copy}>
                    {copied ? (
                      <IconCheck size="1rem" color="teal" />
                    ) : (
                      <IconShare size="1rem" />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          )}

        <Tooltip label="Remove executor">
          <CloseButton
            aria-label="Dispose executor"
            onClick={() => onDispose(identifier)}
          />
        </Tooltip>
      </Group>

      <Stack size="md">
        <Center>
          <Text size={18} weight="bold">
            Contract Executor{" "}
            {state.liveContract ? " - " + state.contractId : ""}
          </Text>
        </Center>
        {!state.liveContract ? (
          <ContractExecutorConfig
            executorConfig={state}
            abiEditorTabs={abiEditorTabs}
            handleChangeContractId={handleChangeContractId}
            handleOnExecute={handleOnExecute}
            handleChangeAbiInputType={handleChangeAbiInputType}
          />
        ) : (
          <LiveContractExecutor contract={state.liveContract} />
        )}
      </Stack>
    </Paper>
  );
};

export default Executor;
