import { useState } from "react";
import { ActionIcon, Collapse, Divider, Grid, Stack } from "@mantine/core";
import { IconCaretDown, IconCaretUp } from "@tabler/icons";

function ExpandableCard({ children, expanded = true, title = "Expandable" }) {
  const [opened, setOpened] = useState(expanded);

  const handleExpand = () => setOpened((opened) => !opened);

  const expandButton = (
    <ActionIcon size="sm" onClick={handleExpand}>
      {opened ? <IconCaretUp fill="white" /> : <IconCaretDown fill="white" />}
    </ActionIcon>
  );

  return (
    <Stack>
      <Grid columns={10}>
        <Grid.Col span={9}>
          <Divider label={title} />
        </Grid.Col>
        <Grid.Col span={1}>{expandButton}</Grid.Col>
      </Grid>

      <Collapse in={opened}>{children}</Collapse>
    </Stack>
  );
}

export default ExpandableCard;
