import { Box, Navbar, NavLink } from "@mantine/core";
import {
  IconBoxMultiple1,
  IconFileSymlink,
  IconHierarchy3
} from "@tabler/icons";
import { Link, useLocation } from "react-router-dom";

const MENU_ITEMS = [
  { label: "Projects", to: "/projects", Icon: IconBoxMultiple1 },
  {
    label: "Contracts executor",
    to: "/contracts-executor",
    Icon: IconFileSymlink
  },
  { label: "Merkle whitelist", to: "/merkle-whitelist", Icon: IconHierarchy3 }
];

const MainMenu = ({ opened }) => {
  const location = useLocation();

  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      hidden={!opened}
      width={{ sm: 200, lg: 300 }}
    >
      <Box>
        {MENU_ITEMS.map(({ label, to, Icon }, index) => {
          return (
            <NavLink
              key={`menu-item-${to}`}
              label={label}
              icon={<Icon size={20} stroke={1.5} />}
              component={Link}
              to={to}
              active={location.pathname === to}
            />
          );
        })}
      </Box>
    </Navbar>
  );
};

export default MainMenu;
