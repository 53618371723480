import { useEffect, useState } from "react";
import { Select, Stack } from "@mantine/core";
import LocalDefinedABIs from "headstarter-crypto/ABIs";

export const MAX_EXCLUDE_PATHS_COUNT = 5;

const AbiSelector = ({
  abiFileNameParam,
  onConfirmSelection,
  onUpdateShareLink
}) => {
  const [state, setState] = useState({
    selectedLocalDefinedABIKey: null
  });

  const localDefinedABIKeys = Object.keys(LocalDefinedABIs);
  const basicSelectorOptions = localDefinedABIKeys.map((key) => ({
    value: key,
    label: key + ".json"
  }));

  const basicSelectorValue =
    state.selectedLocalDefinedABIKey ||
    localDefinedABIKeys.find((key) => key === abiFileNameParam) ||
    localDefinedABIKeys[0];

  const handleChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      selectedLocalDefinedABIKey: value
    }));

    handleSubmitSelection(value);
  };

  const handleSubmitSelection = (selectedAbiFileName = null) => {
    let currentAbiFileName = null;

    if (selectedAbiFileName) {
      currentAbiFileName = selectedAbiFileName;
    } else if (abiFileNameParam) {
      currentAbiFileName = abiFileNameParam;
    } else {
      currentAbiFileName = localDefinedABIKeys[0];
    }

    onConfirmSelection(LocalDefinedABIs[currentAbiFileName]);
    onUpdateShareLink(currentAbiFileName);
  };

  // eslint-disable-next-line
  useEffect(handleSubmitSelection, [abiFileNameParam]);

  return (
    <Stack>
      <Stack>
        <Select
          data={basicSelectorOptions}
          value={basicSelectorValue}
          onChange={handleChange}
        />
      </Stack>
    </Stack>
  );
};

export default AbiSelector;
