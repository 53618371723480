import { forwardRef, Fragment, useEffect, useState } from "react";
import { Button, Group, LoadingOverlay, Select, Text } from "@mantine/core";
import { useApolloClient } from "@apollo/client";
import { STAKEPOOLS_QUERY } from "./queries";
import { mirrorNodeApi } from "../../../../APIs/MirrorNode.api";
import { AccountId } from "headstarter-crypto/hashgraph-sdk";
import { dispatchAction } from "../../../../core/redux/reduxStore";
import Utils from "../../../../utils/utilities";
import { Alert } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";

const AddStakepoolForm = ({ form, onCreate }) => {
  const apolloClient = useApolloClient();
  const [state, setState] = useState({
    loading: false,
    stakepools: []
  });

  const handleOnChangePool = (_address) => {
    const address = form.getInputProps("address");
    const rewardTokenAddress = form.getInputProps("rewardTokenAddress");
    const rewardTokenSymbol = form.getInputProps("rewardTokenSymbol");

    address.onChange(_address);
    rewardTokenAddress.onChange(
      state.stakepools.find(({ address }) => address === _address)
        .rewardTokenAddress
    );
    rewardTokenSymbol.onChange(
      state.stakepools.find(({ address }) => address === _address)
        .rewardTokenSymbol
    );
  };

  useEffect(() => {
    if (state.stakepools.length) return;

    (async () => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const { loading: loadingSPs, data: sPsData } = await apolloClient.query(
          { query: STAKEPOOLS_QUERY }
        );

        if (!loadingSPs && sPsData.stakepools) {
          const promises = await Promise.allSettled(
            sPsData.stakepools.map(async ({ id, rewardToken }) => {
              const rewardTokenId = AccountId.fromSolidityAddress(rewardToken);
              const { isSuccess: hasTokenInfo, data: tokenInfo } =
                await dispatchAction(
                  mirrorNodeApi.endpoints.getFromPath.initiate(
                    `/api/v1/tokens/${rewardTokenId.toString()}`
                  )
                );

              return {
                address: id,
                rewardTokenSymbol: hasTokenInfo ? tokenInfo.symbol : "Unknown"
              };
            })
          );

          setState((prevState) => ({
            ...prevState,
            stakepools: Utils.filterSettledPromises(promises) || []
          }));
        }
      } catch (e) {
        console.error("Unable to query stakepools and reward token info");
      } finally {
        setState((prevState) => ({ ...prevState, loading: false }));
      }
    })();
  }, [apolloClient, state.stakepools.length]);

  const selectData =
    (state.stakepools.length &&
      state.stakepools.map(({ address, rewardTokenSymbol }) => {
        return {
          label: rewardTokenSymbol,
          value: address,
          description: address
        };
      })) ||
    [];

  return (
    <Fragment>
      {!state.loading && state.stakepools.length === 0 ? (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          title="Bummer!"
          color="red"
        >
          Unable to query stakepools and reward token info. Try again.
        </Alert>
      ) : (
        <Fragment>
          <LoadingOverlay visible={state.loading} overlayBlur={2} />
          <form onSubmit={form.onSubmit(onCreate)}>
            <Select
              label="Stakepools"
              placeholder="Choose"
              mt="sm"
              itemComponent={StakepoolSelectItem}
              data={selectData}
              {...form.getInputProps("address")}
              onChange={handleOnChangePool}
            />
            <Button type="submit" mt="sm">
              Add
            </Button>
          </form>
        </Fragment>
      )}
    </Fragment>
  );
};

const StakepoolSelectItem = forwardRef(
  ({ label, value, description, ...others }, ref) => {
    return (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            <Text size="xs" opacity={0.65}>
              {description}
            </Text>
          </div>
        </Group>
      </div>
    );
  }
);

export default AddStakepoolForm;
