import { Button, Group, Text } from "@mantine/core";
import { IconWallet } from "@tabler/icons";
import { openModal, closeAllModals } from "@mantine/modals";
import HashpackIcon from "../../../assets/media/wallets/hashpack.jpg";
import BladeWalletIcon from "../../../assets/media/wallets/bladewallet.png";
import HashpackWallet from "../../../core/wallets/HashpackWallet";
import { useContext } from "react";
import walletUtils from "../../../utils/walletUtils";
import WalletConnectOptions from "./components/WalletConnectOptions";
import { AuthContext } from "../../../core/providers/AuthProvider";

export const SUPPORTED_WALLETS = [
  {
    icon: HashpackIcon,
    name: "Hashpack Wallet",
    wallet: HashpackWallet,
    enabled: true
  },
  {
    icon: BladeWalletIcon,
    name: "BladeWallet",
    wallet: null,
    enabled: false
  }
];

const WalletConnectBtn = ({
  className,
  modalTitle = "Connect wallet",
  connectLabel = "Connect wallet",
  disconnectLabel = "Disconnect",
  withBalance = false
}) => {
  const {
    network,
    connectedWallet,
    connected,
    balance,
    onConnect,
    onDisconnect
  } = useContext(AuthContext);

  const handleConnect = async (payload) => {
    await onConnect(payload);
    closeAllModals();
  };

  const handleDisconnect = async () => {
    await onDisconnect();
  };

  const handleOpenModal = () => {
    openModal({
      title: modalTitle,
      centered: true,
      children: (
        <WalletConnectOptions
          network={network}
          onConnect={handleConnect}
          walletConnected={!!connectedWallet}
        />
      )
    });
  };

  const getBalances = () => {
    const accountId =
      connectedWallet && walletUtils.getAccountId(connectedWallet).toString();
    const { hbarBalance, hstBalance } =
      (balance && walletUtils.getPlainBalances(balance)) || {};

    return (
      <Group spacing={10}>
        {/*TODO: find a better way to display this section*/}
        <Text size="xs">{accountId}</Text>
        <Text size="xs">{hstBalance}</Text>
        <Text size="xs">{hbarBalance}</Text>
      </Group>
    );
  };

  return !connected ? (
    <Button
      className={className}
      leftIcon={<IconWallet size={18} />}
      onClick={handleOpenModal}
    >
      {connectLabel}
    </Button>
  ) : (
    <Group className={className}>
      {withBalance && getBalances()}
      <Button
        className={className}
        leftIcon={<IconWallet size={18} />}
        onClick={handleDisconnect}
      >
        {disconnectLabel}
      </Button>
    </Group>
  );
};

export default WalletConnectBtn;
