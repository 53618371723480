import { Link, useLocation } from "react-router-dom";
import {
  Grid,
  Container,
  Box,
  Text,
  NavLink,
  Center,
  createStyles,
  LoadingOverlay
} from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { openConfirmModal } from "@mantine/modals";
import { IconX, IconPlus, IconTrash, IconCheck } from "@tabler/icons";
import ProjectCard from "./components/ProjectCard";
import {
  DEFAULT_NOTIFICATION_DELAY,
  PROJECT_STATUS
} from "../../utils/constants";
import {
  useDeleteProjectMutation,
  useGetProjectsListQuery,
  useUpdateProjectMutation
} from "../../APIs/Aws.api";
import { useEffect } from "react";
import { groupBy } from "lodash";

const ProjectsList = () => {
  const location = useLocation();
  const {
    data: projects,
    isLoading,
    isError,
    error,
    refetch
  } = useGetProjectsListQuery();
  const [updateProject] = useUpdateProjectMutation();
  const [deleteProject] = useDeleteProjectMutation();
  const { classes } = useStyles();

  const groupedProjects = groupBy(projects, (project) => project.status);

  Object.entries(groupedProjects).forEach(([key, value]) => {
    groupedProjects[key] = value.sort((a, b) => {
      if (b.lastUpdatedDate === undefined) return 1;
      if (a.lastUpdatedDate === undefined) return -1;

      return b.lastUpdatedDate - a.lastUpdatedDate;
    });
  });

  let sortedProjects = [];
  Object.values(PROJECT_STATUS).forEach((status) => {
    if (!groupedProjects[status]) {
      groupedProjects[status] = [];
    }

    sortedProjects.push(...groupedProjects[status]);
  });

  const withConfirmation = (props) =>
    openConfirmModal({
      title: "Please confirm your action",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to {props.action} {props.name} project? You
          can't undo this action afterwards.
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: `No, don't ${props.action} it` },
      confirmProps: { color: props.color || "red" },
      onConfirm: () => props.callback()
    });

  const handleOnClose = async (key, name) => {
    withConfirmation({
      key,
      name,
      action: "close",
      color: "orange",
      callback: async () => {
        showNotification({
          id: "close-project",
          loading: true,
          title: "Closing project",
          message: "This process can take a few seconds",
          autoClose: false,
          disallowClose: true
        });

        const project = projects.find((project) => project.key === key);
        const payload = {
          ...project,
          status: PROJECT_STATUS.closed
        };

        await updateProject({ payload })
          .unwrap()
          .then(() => {
            updateNotification({
              id: "close-project",
              color: "teal",
              title: "Success",
              message: "The project has been successfully closed",
              icon: <IconCheck size={16} />,
              autoClose: DEFAULT_NOTIFICATION_DELAY
            });

            refetch();
          })
          .then((error) => {
            if (error) {
              updateNotification({
                id: "close-project",
                color: "orange",
                title: "Conflict",
                message: error?.error || error,
                icon: <IconX size={16} />,
                autoClose: DEFAULT_NOTIFICATION_DELAY
              });
            }
          });
      }
    });
  };

  const handleOnDelete = async (key, name) => {
    withConfirmation({
      key,
      name,
      action: "delete",
      callback: async () => {
        showNotification({
          id: "delete-project",
          loading: true,
          title: `Delete ${key}`,
          message: "This process can take a few seconds",
          autoClose: false,
          disallowClose: true
        });

        await deleteProject({ key })
          .unwrap()
          .then(() => {
            updateNotification({
              id: "delete-project",
              color: "teal",
              title: "Success",
              message: name + " has been successfully deleted",
              icon: <IconTrash size={16} />,
              autoClose: DEFAULT_NOTIFICATION_DELAY
            });

            refetch();
          })
          .then((error) => {
            if (error) {
              updateNotification({
                id: "delete-project",
                color: "orange",
                title: "Conflict",
                message: error?.error || error,
                icon: <IconX size={16} />,
                autoClose: DEFAULT_NOTIFICATION_DELAY
              });
            }
          });
      }
    });
  };

  useEffect(() => {
    if (isError) {
      showNotification({
        id: "load-projects",
        color: "red",
        title: "Unable to load projects",
        message: `${error?.error || error}. Check the console for more details`,
        icon: <IconX size={16} />,
        autoClose: 2000
      });
    }
  }, [isError, error]);

  const newProjectCard = (
    <Center className={classes.add}>
      <NavLink
        className={classes.navLink}
        component={Link}
        label={
          <Box>
            <Center>
              <IconPlus className="icon" size={40} stroke={1.5} />
              <Text className="label">Create a new project</Text>
            </Center>
          </Box>
        }
        to="/projects/create"
        active={location.pathname === "/projects/create"}
      ></NavLink>
    </Center>
  );

  return (
    <Container my="md" mx={0} size="full">
      <div style={{ position: "relative" }}>
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
        {
          <Grid>
            <Grid.Col sm={6} lg={4}>
              {newProjectCard}
            </Grid.Col>
            {sortedProjects &&
              sortedProjects.map((project) => {
                return (
                  <Grid.Col sm={6} lg={4} key={project.key}>
                    <ProjectCard
                      className="project-card"
                      project={project}
                      onDelete={handleOnDelete}
                      onClose={handleOnClose}
                    />
                  </Grid.Col>
                );
              })}
          </Grid>
        }
      </div>
    </Container>
  );
};

const useStyles = createStyles((theme) => {
  const isDarkMode = theme.colorScheme === "dark";

  return {
    add: {
      height: "310px",
      cursor: "pointer"
    },
    navLink: {
      height: "100%",
      textAlign: "center",
      border: `1px solid ${isDarkMode ? theme.colors.dark[4] : theme.colors.gray[3]}`,
      borderRadius: "10px",
      backgroundColor: isDarkMode ? theme.colors.dark[6] : theme.colors.gray[0],
      ...theme.fn.hover({
        backgroundColor: isDarkMode
          ? theme.colors.dark[5]
          : theme.colors.gray[1]
      }),
      ".icon, .label": {
        color: isDarkMode ? theme.colors.dark[2] : theme.colors.gray[5]
      }
    }
  };
});

export default ProjectsList;
