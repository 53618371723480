import { Stack } from "@mantine/core";
import DropzoneInput from "../DropzoneInput";
import { IMAGE_MIME_TYPE } from "@mantine/dropzone";

const MediaFormField = ({ field, inputProps, onClearState }) => {
  return (
    <Stack pb="md">
      <DropzoneInput
        label={field.value.label}
        accept={IMAGE_MIME_TYPE}
        {...inputProps}
        passClearStateHandler={onClearState}
        multiple={false}
      />
    </Stack>
  );
};

export default MediaFormField;
