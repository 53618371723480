import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Paper, Stack, Text, Center } from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import Executor from "./Executor";
import utilities from "../../utils/utilities";

const ContractsExecutor = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const contracts = queryParams.getAll("contract") ?? "";
  const contractsString = contracts.toString();

  const [state, setState] = useState({
    executors: []
  });

  const handleAddExecutor = ({
    contractId = "",
    abiFileNameParam = "",
    selfExecute = false
  }) => {
    setState((prevState) => ({
      ...prevState,
      executors: [
        ...prevState.executors,
        {
          identifier: utilities.alphaNumericUid(8),
          contractId,
          abiFileNameParam,
          selfExecute,
          Component: Executor
        }
      ]
    }));
  };

  const handleOnDisposeExecutor = (_identifier) => {
    setState((prevState) => ({
      ...prevState,
      executors: prevState.executors.filter(
        ({ identifier }) => identifier !== _identifier
      )
    }));
  };

  useEffect(() => {
    if (contracts?.length) {
      contracts.forEach((contract) => {
        const contractId = contract.split("::")[0];
        const queryParamsRegex = /(?<![^:])([^:=]+)=([^:]+)(?:::|$)/g;
        const params = Object.fromEntries(
          Array.from(contract.matchAll(queryParamsRegex), (m) => [m[1], m[2]])
        );

        if (
          state.executors.some((executor) => executor.contractId === contractId)
        ) {
          return;
        }

        handleAddExecutor({
          contractId,
          abiFileNameParam: params.abiFileName,
          selfExecute: params.selfExecute && Boolean(params.selfExecute)
        });
      });
    } else {
      setState({ executors: [] });
    }

    // eslint-disable-next-line
  }, [contractsString]);

  return (
    <Container my="md" mx={0} size="full">
      {state.executors.map(
        ({
          identifier,
          contractId,
          abiFileNameParam,
          selfExecute,
          Component
        }) => {
          return (
            <Component
              key={identifier}
              identifier={identifier}
              contractId={contractId}
              abiFileNameParam={abiFileNameParam}
              selfExecute={selfExecute}
              onDispose={handleOnDisposeExecutor}
            />
          );
        }
      )}

      <Paper
        mt="lg"
        p="lg"
        radius="md"
        sx={() => ({ cursor: "pointer" })}
        onClick={handleAddExecutor}
        withBorder
      >
        <Stack size="md">
          <Center>
            <Stack align="center">
              <Text>Add new executor</Text>
              <IconPlus className="icon" size={40} stroke={1.5} />
            </Stack>
          </Center>
        </Stack>
      </Paper>
    </Container>
  );
};

export default ContractsExecutor;
