import { Fragment, useEffect } from "react";
import { LoadingOverlay, Paper, Text } from "@mantine/core";
import MarkdownEditor from "../../../components/reusable/MarkdownEditor";
import withEditableConfig from "../../../HOCs/withEditableField";
import { MEDIA_DEFAULT_NAMES, URLs } from "../../../utils/constants";
import utilities from "../../../utils/utilities";
import { isNil } from "lodash";
import {
  useGetDescriptionQuery,
  useUploadMediaMutation
} from "../../../APIs/Aws.api";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";

const UPLOADED_IMAGE_PREFIX_LENGTH = 6;

const ProjectDescription = ({ projectKey, description, isEdit }) => {
  const [uploadMedia] = useUploadMediaMutation();
  const { isLoading, error, isError } = useGetDescriptionQuery({
    projectKey,
    fileName: MEDIA_DEFAULT_NAMES.DESCRIPTION
  });

  const handleUploadImage = async (file, cb) => {
    try {
      const uid = utilities.alphaNumericUid(UPLOADED_IMAGE_PREFIX_LENGTH);
      const [name, extension] = file.name.split(".");
      const fileName = `${name}-${uid}.${extension}`;

      await uploadMedia({ projectKey, file, fileName });
      cb(`${URLs.MEDIA}/media/${projectKey}/${fileName}`, fileName);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isError) {
      showNotification({
        id: "get-description",
        color: "red",
        title: "Failed to fetch description",
        message: `${error?.error || error}. Check the console for more details`,
        icon: <IconX size={16} />,
        autoClose: 4000
      });
    }
  }, [isError, error]);

  return (
    <Paper p="md" radius="md" style={{ width: "100%" }} withBorder>
      <Text mb={20}>Description</Text>
      <div style={{ position: "relative" }}>
        <LoadingOverlay visible={isLoading} overlayBlur={2} />

        {!isNil(description.value) ? (
          <Fragment>
            <MarkdownEditor
              initValue={description.value}
              onChange={description.onChange}
              onUploadImage={handleUploadImage}
              previewOnly={!isEdit}
            />
          </Fragment>
        ) : (
          <Text size={22} weight="bold">
            No description
          </Text>
        )}
      </div>
    </Paper>
  );
};

export default withEditableConfig(ProjectDescription, "description");
