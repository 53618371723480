import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback
} from "react";
import { StratoContext } from "./StratoProvider";
import AuthPage from "../../views/AuthPage";
import { Container, Loader, createStyles } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";
import { DEFAULT_NOTIFICATION_DELAY } from "../../utils/constants";

export const AuthContext = createContext({});

//TODO: better read the wallet connection state from wallet-bridge
const isHPConnectionStateAvailable = async () => {
  return !!localStorage.getItem("hashconnectData");
};

const AuthProvider = ({ children }) => {
  const { classes } = useStyles();

  const {
    network,
    wallet: {
      payload: connectedWallet,
      connect,
      disconnect,
      connected,
      balance
    }
  } = useContext(StratoContext);

  const [auth, setAuth] = useState({
    loading: true,
    authenticated: false,
    status: "Loading..."
  });

  const authenticate = useCallback(async () => {
    try {
      const authenticated = await connectedWallet?.authenticate();

      setAuth((prevState) => ({
        ...prevState,
        authenticated: !!authenticated,
        loading: false,
        status: "Successfully authenticated!"
      }));
    } catch (e) {
      console.error("From provider", e);
      setAuth((prevState) => ({
        ...prevState,
        loading: false,
        status: "Error"
      }));

      showNotification({
        id: "authentication",
        title: "Failed to authenticate",
        message:
          "There has been an error trying to authenticate. Make sure your address is whitelisted then try again",
        icon: <IconX size={16} />,
        color: "orange",
        autoClose: DEFAULT_NOTIFICATION_DELAY
      });
    }
  }, [connectedWallet]);

  const handleConnect = useCallback(
    async (payload) => {
      await connect(payload);
    },
    [connect]
  );

  const handleAuthenticate = useCallback(async () => {
    setAuth((prevState) => ({
      ...prevState,
      loading: true,
      status: "Performing authentication..."
    }));

    authenticate();
  }, [authenticate]);

  const handleDisconnect = async () => {
    await connectedWallet.logout();
    await disconnect();
    setAuth({
      loading: false,
      authenticated: false,
      status: "Disconnected"
    });
  };

  useEffect(() => {
    if (connectedWallet) {
      handleAuthenticate();
    }
  }, [connectedWallet, handleAuthenticate]);

  useEffect(() => {
    const checkWalletConnectionState = async () => {
      const isWCSAvailable = await isHPConnectionStateAvailable();
      setAuth((prevState) => ({
        ...prevState,
        loading: isWCSAvailable
      }));
    };

    checkWalletConnectionState();
  }, []);

  const value = useMemo(
    () => ({
      ...auth,
      network,
      connectedWallet,
      connected,
      balance,
      onConnect: handleConnect,
      onAuthenticate: handleAuthenticate,
      onDisconnect: handleDisconnect
      // eslint-disable-next-line
    }),
    [
      auth,
      network,
      connectedWallet,
      connected,
      balance,
      handleConnect,
      handleAuthenticate
    ]
  );

  return (
    <AuthContext.Provider value={value}>
      {auth.loading ? (
        <Container className={classes.loadingContainer}>
          <div className={classes.loadingSpinnerWrapper}>
            <Loader />
            <span>{auth.status}</span>
          </div>
        </Container>
      ) : (
        <Fragment>{auth.authenticated ? children : <AuthPage />}</Fragment>
      )}
    </AuthContext.Provider>
  );
};

const useStyles = createStyles((theme) => ({
  loadingContainer: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center"
  },

  loadingSpinnerWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",

    span: {
      fontSize: "14px"
    }
  }
}));

export default AuthProvider;
