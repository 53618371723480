import { createSlice } from "@reduxjs/toolkit";
import update from "immutability-helper";
import ProjectUtils from "../../../utils/projectUtils";

export const projectsReducers = createSlice({
  name: "projects",
  initialState: [],
  reducers: {
    setProjectsList: (state, action) => {
      const projectsList = action.payload.map((project) =>
        ProjectUtils.withMediaAssets(project)
      );
      return update(state, { $set: projectsList });
    },
    updateProject: (state, action) => {
      return state.map((project) =>
        project.key === action.payload.key
          ? ProjectUtils.withMediaAssets(action.payload)
          : project
      );
    },
    updateProjectProperty: (state, action) => {
      const { projectKey, property, value } = action.payload;
      return state.map((project) =>
        project.key === projectKey ? { ...project, [property]: value } : project
      );
    }
  }
});

export const { setProjectsList, updateProject, updateProjectProperty } =
  projectsReducers.actions;
export default projectsReducers.reducer;
