import { useEffect } from "react";
import StatsField from "../../../components/reusable/StatsField";
import {
  IconNews,
  IconCalendar,
  IconActivity,
  IconCoin,
  IconZoomMoney,
  IconLetterCase,
  IconOmega,
  IconBusinessplan,
  IconReceipt2,
  IconCircle,
  IconBracketsContainEnd,
  IconBracketsContainStart,
  IconForbid,
  IconFlame,
  IconRefresh,
  IconX
} from "@tabler/icons";
import CopyableText from "../../../components/reusable/CopyableText";
import {
  Badge,
  Button,
  Center,
  Group,
  Loader,
  Paper,
  Space,
  Stack,
  Switch,
  Text
} from "@mantine/core";
import { Hbar } from "headstarter-crypto/hashgraph-sdk";
import ExpandableCard from "../../../components/reusable/ExpandableCard";
import HederaAddress from "../../../components/reusable/HederaAddress";
import ContractExecutorAnchor from "../../../components/reusable/ContractExecutorAnchor";
import { useToggleContractIngestionMutation } from "../../../APIs/Aws.api";
import { showNotification } from "@mantine/notifications";

const INOContractInfo = ({ contract, onUpdate }) => {
  const { contractId, contractInfo } = contract || {};
  const [toggleContract, { isLoading: pendingToggle, error }] =
    useToggleContractIngestionMutation();

  const onToggleError = (e) => {
    return showNotification({
      id: "toggle-contract-ingestion",
      icon: <IconX />,
      autoClose: 4000,
      color: "red",
      title: `Failed to toggle the contract ingestion`,
      message: e.reason || e.message,
      loading: false
    });
  };

  const handleToggleContract = async (value) => {
    try {
      await toggleContract({ contractId, disabled: value });
      onUpdate(contractId);
    } catch (e) {
      console.error(e);
      return onToggleError(e);
    }
  };

  useEffect(() => {
    if (error?.error) onToggleError({ message: error.error });
  }, [error]);

  if (!contractInfo || !contractInfo.lastUpdated) {
    return (
      <Paper py="4rem" px="1rem" withBorder>
        <Center>
          <Stack spacing={5}>
            <Text size="lg" weight="bold" align="center">
              There is no info about this contract yet.
            </Text>
            <Text size="xs" color="dimmed" align="center">
              Check if the project is saved or the contract is already deployed.
            </Text>
            <Space mt="lg" />
            <Button
              leftIcon={<IconRefresh />}
              onClick={() => onUpdate(contractId)}
            >
              Refresh
            </Button>
          </Stack>
        </Center>
      </Paper>
    );
  }

  const { token } = contractInfo || {};

  return (
    <Stack>
      <ExpandableCard title="General">
        <Stack>
          <StatsField
            label="Contract ID"
            value={
              <Group position="apart" grow>
                <ContractExecutorAnchor
                  contractId={contractId}
                  abiFileName="StandardizedMerkleStorefront"
                >
                  <CopyableText copy={contractId}>
                    <HederaAddress contractId={contractId} />
                  </CopyableText>
                </ContractExecutorAnchor>
                <Switch
                  label={
                    <Group>
                      <Text size="xs">Contract tracking</Text>
                      {pendingToggle && <Loader size="xs" />}
                    </Group>
                  }
                  onLabel="ON"
                  offLabel="OFF"
                  checked={
                    !contractInfo.disabled || contractInfo.disabled === false
                  }
                  onChange={(e) => handleToggleContract(!e.target.checked)}
                />
              </Group>
            }
            icon={IconNews}
          />
          <StatsField
            label="Last Updated"
            date={contractInfo.lastUpdated}
            icon={IconCalendar}
          />
          <StatsField
            label="Last Ingestion"
            date={contractInfo.lastIngestion}
            icon={IconCalendar}
          />

          <StatsField
            label="Token Claim Max"
            value={contractInfo.tokenClaimMax}
            icon={IconForbid}
          />

          <StatsField
            label="Tokens sold"
            value={contractInfo.tokensSold}
            icon={IconZoomMoney}
          />
        </Stack>
      </ExpandableCard>

      <ExpandableCard title="Config" expanded={false}>
        <Stack>
          <Group grow>
            <StatsField
              label="Minting Enabled"
              value={
                <Badge
                  size="xs"
                  color={contractInfo.mintingEnabled ? "green" : "red"}
                >
                  {contractInfo.mintingEnabled.toString()}
                </Badge>
              }
              icon={IconActivity}
            />
            {contractInfo.hasOwnProperty("burningEnabled") && (
              <StatsField
                label="Burning Enabled"
                value={
                  <Badge
                    size="xs"
                    color={contractInfo.burningEnabled ? "green" : "red"}
                  >
                    {contractInfo.burningEnabled.toString()}
                  </Badge>
                }
                icon={IconFlame}
              />
            )}
          </Group>
        </Stack>
      </ExpandableCard>

      <ExpandableCard title="Timing" expanded={false}>
        <Stack>
          <StatsField
            label="Start Time"
            date={contractInfo.startTime}
            icon={IconBracketsContainStart}
          />
          <StatsField
            label="Sale Start Time"
            date={contractInfo.saleStartTime}
            icon={IconBracketsContainStart}
          />
          <StatsField
            label="Token Gated Start Time"
            date={contractInfo.tokenGatedStartTime}
            icon={IconBracketsContainStart}
          />
          <StatsField
            label="Sale End Time"
            date={contractInfo.saleEndTime}
            icon={IconBracketsContainEnd}
          />
        </Stack>
      </ExpandableCard>

      <ExpandableCard title="Token Info" expanded={false}>
        <Stack>
          <StatsField
            label="Token ID"
            value={
              <CopyableText copy={token.tokenId}>
                <HederaAddress tokenId={token.tokenId} />
              </CopyableText>
            }
            icon={IconCoin}
          />
          <StatsField label="Name" value={token.name} icon={IconLetterCase} />
          <StatsField label="Symbol" value={token.symbol} icon={IconOmega} />
          <StatsField
            label="Initial Supply"
            value={token.initialSupply}
            icon={IconBusinessplan}
          />
          <StatsField
            label="Max Supply"
            value={token.maxSupply}
            icon={IconBusinessplan}
          />
          <StatsField
            label="Max Supply For Sale"
            value={token.maxSupplyForSale}
            icon={IconBusinessplan}
          />
          <StatsField
            label="Total Supply"
            value={token.totalSupply}
            icon={IconBusinessplan}
          />
          <StatsField
            label="Price Per Token"
            value={Hbar.fromTinybars(contractInfo.pricePerToken).toString()}
            icon={IconReceipt2}
          />
          <StatsField
            label="Metadata"
            value={<CopyableText text={token.metadata} />}
            icon={IconCoin}
          />
          <StatsField
            label="Metadata Unique"
            value={
              <Badge size="xs" color={token.metadataUnique ? "green" : "blue"}>
                {token.metadataUnique.toString()}
              </Badge>
            }
            icon={IconCircle}
          />
        </Stack>
      </ExpandableCard>
    </Stack>
  );
};

export default INOContractInfo;
