import utilities from "../../utils/utilities";
import { Anchor, Group, Text } from "@mantine/core";
import { Fragment } from "react";
import { IconExternalLink } from "@tabler/icons";

const HederaAddress = ({
  children,
  tokenId = null,
  accountId = null,
  contractId = null,
  transactionId = null,
  asText = false
}) => {
  const withHashscanLink = (key, value) => {
    if (!value) return null;

    return {
      address: value,
      hashscanUrl: utilities.getHashscanUrl({ [key]: value })
    };
  };

  const hederaAsset = [
    withHashscanLink("tokenId", tokenId) ?? null,
    withHashscanLink("accountId", accountId) ?? null,
    withHashscanLink("contractId", contractId) ?? null,
    withHashscanLink("transactionId", transactionId) ?? null
  ].filter(Boolean)[0];

  return (
    <Fragment>
      {hederaAsset ? (
        <Fragment>
          {asText ? (
            <Text size="xs">{hederaAsset.address}</Text>
          ) : (
            <Anchor
              href={hederaAsset.hashscanUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Group spacing={4} align="center">
                <Text size="xs">{hederaAsset.address}</Text>
                <IconExternalLink size="1rem" />
              </Group>
            </Anchor>
          )}
        </Fragment>
      ) : (
        children
      )}
    </Fragment>
  );
};

export default HederaAddress;
