import { IconBulldozer, IconChecks, IconPencil } from "@tabler/icons";

export const HEDERA_NETWORK = process.env.REACT_APP_HEDERA_NETWORK;
export const IS_MAINNET = HEDERA_NETWORK === "mainnet";

export const DEFAULT_NOTIFICATION_DELAY = 4000;

export const URLs = {
  API: process.env.REACT_APP_API_URL,
  MEDIA: process.env.REACT_APP_MEDIA_URL
};

export const REGEX_PATTERNS = {
  hederaAccount: /^0\.0\.\d+$/gm
};

export const PROJECT_TYPES = {
  default: {
    value: "default",
    label: "NO TYPE",
    badgeGradient: { from: "gray", to: "gray" }
  },
  ido: {
    value: "ido",
    label: "IDO",
    badgeGradient: { from: "purple", to: "blue" }
  },
  ino: {
    value: "ino",
    label: "INO",
    badgeGradient: { from: "darkBlue", to: "pink" }
  },
  spool: {
    value: "spool",
    label: "STAKEPOOL",
    badgeGradient: { from: "blue", to: "cyan" }
  }
};

export const MEDIA_DEFAULT_NAMES = {
  LOGO: "logo",
  THUMBNAIL: "thumbnail",
  DESCRIPTION: "description"
};

export const DESCRIPTION_CONFIG = {
  DESCRIPTION_MIME_TYPE: "text/markdown",
  DESCRIPTION_EXTENSIONS: [".md", ".mdx"],
  DESCRIPTION_DEFAULT_NAME: "description.md"
};

/**
 * Defines the desired order for displaying projects list.
 */
export const PROJECT_STATUS = {
  drafting: "DRAFTING",
  underPreparation: "UNDER_PREPARATION",
  published: "PUBLISHED",
  closed: "CLOSED"
};

export const PROJECT_STATUS_BADGE = {
  [PROJECT_STATUS.drafting]: {
    label: "Draft",
    color: "gray"
  },
  [PROJECT_STATUS.underPreparation]: {
    label: "Under Preparation",
    color: "yellow"
  },
  [PROJECT_STATUS.published]: {
    label: "Published",
    color: "teal"
  },
  [PROJECT_STATUS.closed]: {
    label: "Closed",
    color: "red"
  }
};

export const PROJECT_ACTIONS = {
  CLOSED: [
    {
      icon: IconChecks,
      label: "Publish",
      value: "PUBLISHED",
      description:
        "Promote the project to mature status, ready for public interaction. Certain fields won't be editable past this"
    },
    {
      icon: IconChecks,
      label: "Save as closed",
      value: "CLOSED",
      description: "Keep the project as closes"
    }
  ],
  CREATE: [
    {
      icon: IconPencil,
      label: "Save as draft",
      value: "DRAFTING",
      description:
        "The project won't be visible for anyone except you allowing further edits before making it public"
    },
    {
      icon: IconBulldozer,
      label: 'Release "Under preparation"',
      value: "UNDER_PREPARATION",
      description:
        'Create a new project and make it publicly visible with the "Under preparation" badge'
    },
    {
      icon: IconChecks,
      label: "Publish",
      value: "PUBLISHED",
      description:
        "You will be able to publish it once you have at least one pool defined which you can do following the initial creation",
      disabled: true
    }
  ],
  DRAFTING: [
    {
      icon: IconPencil,
      label: "Save",
      value: "DRAFTING",
      description: "Keep the project hidden from outside until further notice"
    },
    {
      icon: IconBulldozer,
      label: "Make draft public",
      value: "UNDER_PREPARATION",
      description:
        'Keep it as a draft but open it to the public under the "Under preparation" label'
    },
    {
      icon: IconChecks,
      label: "Publish",
      value: "PUBLISHED",
      description:
        "Open your project to the world. Once this is done, you won't be able to change certain fields."
    }
  ],
  UNDER_PREPARATION: [
    {
      icon: IconBulldozer,
      label: "Save",
      value: "UNDER_PREPARATION",
      description: "Keep the project as a public draft for now"
    },
    {
      icon: IconChecks,
      label: "Publish",
      value: "PUBLISHED",
      description:
        "Promote the project to mature status, ready for public interaction. Certain fields won't be editable past this"
    }
  ],
  PUBLISHED: [
    {
      icon: IconChecks,
      label: "Save",
      value: "PUBLISHED"
    }
  ]
};

export const POOL_TYPES = {
  allowlistPool: {
    value: "AllowlistPool",
    label: "Allowlist Pool"
  },
  basicPool: {
    value: "BasicPool",
    label: "Basic Pool"
  },
  vestingPool: {
    value: "VestingPool",
    label: "Vesting Pool"
  }
};

export const STOREFRONT_TYPES = {
  default: {
    value: "Default",
    label: "Default"
  },
  tokenGated: {
    value: "TokenGated",
    label: "Token Gated"
  }
};
