import { Fragment } from "react";
import { ActionIcon, Group, Tooltip } from "@mantine/core";
import { IconSettings } from "@tabler/icons";
import { useNavigate } from "react-router-dom";

const BASE_EXECUTOR_URL = "/contracts-executor";

const ContractExecutorAnchor = ({
  contractId,
  children,
  abiFileName = "",
  selfExecute = false
}) => {
  const navigate = useNavigate();

  const handleExecuteContract = () => {
    const config = {
      contract: contractId,
      ...(abiFileName && { abiFileName }),
      ...(selfExecute && { selfExecute })
    };

    const params = Object.keys(config).reduce((acc, configKey) => {
      const value = Array.isArray(config[configKey])
        ? config[configKey].join(";")
        : config[configKey];
      acc = [...acc, `${configKey}=${value}`];

      return acc;
    }, []);

    navigate(`${BASE_EXECUTOR_URL}?${params.join("::")}`);
  };

  return (
    <Fragment>
      <Group spacing={0} align="center">
        {children ?? contractId}
        <Tooltip label="Execute" position="right">
          <ActionIcon onClick={handleExecuteContract}>
            <IconSettings size="1rem" />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Fragment>
  );
};

export default ContractExecutorAnchor;
