import { REGEX_PATTERNS } from "../../utils/constants";

export const validateWhitelistData = (isINOWhitelist, values) => {
  const uniqueEntries = new Set();
  const duplicateEntries = new Set();

  values.forEach(([account, , discountBps]) => {
    const entryKey = isINOWhitelist
      ? [account, discountBps].join("_")
      : account;

    if (uniqueEntries.has(entryKey)) {
      duplicateEntries.add(entryKey);
    } else {
      uniqueEntries.add(entryKey);
    }
  });

  return values.map(([account, amount, discountBps], index) => {
    const entryKey = isINOWhitelist
      ? [account, discountBps].join("_")
      : account;

    const errors = [];

    if (!account || !new RegExp(REGEX_PATTERNS.hederaAccount).test(account))
      errors.push("The account is invalid.");

    if (isINOWhitelist) {
      if (!amount || amount <= 0)
        errors.push("The amount should be a numerical value greater than 0.");
      if (!discountBps || isNaN(discountBps) || discountBps < 0)
        errors.push(
          "The discount should be a numerical value equal to or greater than 0."
        );
    }

    if (duplicateEntries.has(entryKey)) errors.push("The field is duplicated.");

    return [account, ...(isINOWhitelist ? [amount, discountBps] : []), errors];
  });
};

export const hasValidationErrors = (data) =>
  data.some((entry) => entry[entry.length - 1].length > 0);
