import {
  useMantineColorScheme,
  SegmentedControl,
  Group,
  Center,
  Box
} from "@mantine/core";
import { IconSun, IconMoon } from "@tabler/icons";

const ThemeSwitcher = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <Group position="center">
      <SegmentedControl
        value={colorScheme}
        onChange={(value) => toggleColorScheme(value)}
        data={[
          {
            value: "light",
            label: (
              <Center>
                <IconSun size={16} stroke={1.5} />
                <Box ml={10}>Light</Box>
              </Center>
            )
          },
          {
            value: "dark",
            label: (
              <Center>
                <IconMoon size={16} stroke={1.5} />
                <Box ml={10}>Dark</Box>
              </Center>
            )
          }
        ]}
      />
    </Group>
  );
};

export default ThemeSwitcher;
