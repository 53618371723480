import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const mirrorNetwork =
  process.env.REACT_APP_HEDERA_NETWORK === "mainnet"
    ? "mainnet-public"
    : process.env.REACT_APP_HEDERA_NETWORK;

export const mirrorNodeApi = createApi({
  reducerPath: "mirrorNodeAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://${mirrorNetwork}.mirrornode.hedera.com`
  }),
  keepUnusedDataFor: 10,
  endpoints: (builder) => ({
    getFromPath: builder.query({
      query: (path) => path
    })
  })
});

export const { useGetFromPathQuery } = mirrorNodeApi;
