import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

//TODO: replace with .env var
const BASE_URL = "http://185.237.253.110:8200/subgraphs/name/headstarter";

const HSTGraphProvider = ({ endpoint = "", children }) => {
  const client = new ApolloClient({
    uri: BASE_URL + endpoint,
    cache: new InMemoryCache()
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default HSTGraphProvider;
