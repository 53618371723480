import { Fragment, useEffect, useRef, useState } from "react";
import {
  ActionIcon,
  Center,
  createStyles,
  LoadingOverlay,
  Paper,
  Text
} from "@mantine/core";
import TabbedContent from "../../../../components/reusable/TabbedContent";
import { IconPlus, IconTrash, IconX } from "@tabler/icons";
import { useForm } from "@mantine/form";
import { DEFAULT_NOTIFICATION_DELAY } from "../../../../utils/constants";
import { showNotification, updateNotification } from "@mantine/notifications";
import { openConfirmModal } from "@mantine/modals";
import { withToggleOnEdit } from "../../../../utils/contexts";
import { useDeleteContractInfoMutation } from "../../../../APIs/Aws.api";
import AddStakepoolModal from "./AddStakepoolModal";
import StakepoolTab from "./StakepoolTab";
import { getStakepoolById } from "./queries";
import { dispatchAction } from "../../../../core/redux/reduxStore";
import { mirrorNodeApi } from "../../../../APIs/MirrorNode.api";
import { useApolloClient } from "@apollo/client";
import { AccountId } from "headstarter-crypto/hashgraph-sdk";
import Utils from "../../../../utils/utilities";
import StakepoolTabContent from "./StakepoolTabContent";

const StakepoolsCard = ({ projectKey, stakepools = {} }) => {
  const apolloClient = useApolloClient();
  const { classes } = useStyles();
  const tabbedContentRef = useRef();
  const [state, setState] = useState({
    spTabs: [],
    loading: false,
    createModalOpen: false
  });
  const [deleteContractInfo] = useDeleteContractInfoMutation();

  const newStakepoolForm = useForm({
    initialValues: {
      address: "",
      rewardTokenSymbol: ""
    },

    validate: {
      address: (value) =>
        value.length === 0 ? "Stakepool address is required" : null,
      rewardTokenSymbol: (value) =>
        value.length === 0 ? "RewardTokenSymbol address is required" : null
    }
  });

  const handleAddNewStakepool = (values) => {
    const exists = stakepools.value?.find(
      (stakepool) => stakepool.address === values.address
    );

    if (exists) {
      showNotification({
        id: "add-stakepool",
        color: "orange",
        title: "Conflict",
        message: "There is 1 existing pool with the same address",
        icon: <IconX size={16} />,
        autoClose: 4000
      });

      return;
    }

    stakepools.onChange([...stakepools.value, values]);

    newStakepoolForm.reset();
    setState((prevState) => ({
      ...prevState,
      createModalOpen: false
    }));
  };

  const handleDeleteStakepool = async (projectKey, address) => {
    try {
      showNotification({
        id: "delete-spool",
        loading: true,
        title: `Removing stakepool with address: ${address}`,
        message: "This process can take a few seconds",
        autoClose: false,
        disallowClose: true
      });

      const res = await deleteContractInfo({ projectKey, contractId: address });

      if (res) {
        updateNotification({
          id: "delete-spool",
          color: "teal",
          title: "Successfully removed",
          icon: <IconTrash size={16} />,
          autoClose: DEFAULT_NOTIFICATION_DELAY
        });

        const updatedStakepools = stakepools.value.filter(
          (stakepool) => stakepool.address !== address
        );

        // using the imperative handler that exposes activeTab and setActiveTab from TabbedContent component
        // now we have access to update the activeTab on the child component
        if (tabbedContentRef.current) {
          const { current: tabbedContent } = tabbedContentRef;

          if (tabbedContent.setActiveTab === "function") {
            const index =
              tabbedContent.activeTab > updatedStakepools.length - 1
                ? updatedStakepools.length
                : tabbedContent.activeTab;

            tabbedContentRef.current.setActiveTab(index);
          }
        }
      }
    } catch (e) {
      console.error("Could not delete the stakepool.", e);

      updateNotification({
        id: "delete-spool",
        color: "orange",
        title: "Conflict",
        message: e.message,
        icon: <IconX size={16} />,
        autoClose: DEFAULT_NOTIFICATION_DELAY
      });
    }
  };

  const onDeleteConfirmation = (address) =>
    openConfirmModal({
      title: "Please confirm your action",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete {address}? You can't undo this action
          afterwards.
        </Text>
      ),
      labels: {
        confirm: "Delete pool",
        cancel: "No don't delete it"
      },
      confirmProps: { color: "red" },
      onConfirm: () => handleDeleteStakepool(projectKey.value, address)
    });

  useEffect(() => {
    (async () => {
      if (!stakepools.value?.length || state.spTabs.length || state.loading)
        return;
      setState((prevState) => ({ ...prevState, loading: true }));

      try {
        const promises = await Promise.allSettled(
          stakepools.value.map(async ({ address }) => {
            const { loading: loadingSp, data: spData } =
              await apolloClient.query({ query: getStakepoolById(address) });
            const rewardTokenId = !loadingSp
              ? AccountId.fromSolidityAddress(spData.stakepool.rewardToken)
              : "";
            const { isSuccess: hasTokenInfo, data: tokenInfo } =
              await dispatchAction(
                mirrorNodeApi.endpoints.getFromPath.initiate(
                  `/api/v1/tokens/${rewardTokenId.toString()}`
                )
              );

            const rewardTokenAddress = hasTokenInfo
              ? rewardTokenId.toString()
              : "Unknown";
            const rewardTokenSymbol = hasTokenInfo
              ? tokenInfo.symbol
              : "Unknown";

            return {
              key: address,
              label: (
                <StakepoolTab
                  label={rewardTokenSymbol}
                  onDelete={() => onDeleteConfirmation(address)}
                />
              ),
              content: (
                <StakepoolTabContent
                  data={{
                    address,
                    rewardTokenAddress,
                    rewardTokenSymbol
                  }}
                />
              )
            };
          })
        );

        setState((prevState) => ({
          ...prevState,
          spTabs: Utils.filterSettledPromises(promises) || []
        }));
      } catch (e) {
        console.error("Unable to query stakepools and reward token info");
      } finally {
        setState((prevState) => ({ ...prevState, loading: false }));
      }
    })();
    // eslint-disable-next-line
  }, [apolloClient]);

  const AddButton = withToggleOnEdit(ActionIcon);

  return (
    <Fragment>
      <AddStakepoolModal
        addForm={newStakepoolForm}
        opened={state.createModalOpen}
        onCreate={handleAddNewStakepool}
        onClose={() =>
          setState((prevState) => ({ ...prevState, createModalOpen: false }))
        }
      />
      <Paper p="md" radius="md" className={classes.card} withBorder>
        <LoadingOverlay visible={state.loading} overlayBlur={2} />
        <Text mb={20}>Stakepools</Text>
        <AddButton
          className={classes.addBtn}
          variant="default"
          radius="md"
          size={36}
          onClick={() =>
            setState((prevState) => ({ ...prevState, createModalOpen: true }))
          }
        >
          <IconPlus size={18} className={classes.addBtnIcon} stroke={1.5} />
        </AddButton>

        {stakepools.value?.length > 0 ? (
          <TabbedContent ref={tabbedContentRef} tabs={state.spTabs} />
        ) : (
          <Paper py="4rem" withBorder>
            <Center>
              <Text size="lg" color="dimmed" weight="bold">
                There are no stakepools.
              </Text>
            </Center>
          </Paper>
        )}
      </Paper>
    </Fragment>
  );
};

const useStyles = createStyles((theme) => {
  return {
    card: {
      position: "relative"
    },

    addBtn: {
      position: "absolute",
      top: theme.spacing.xs,
      right: theme.spacing.xs + 2
    },

    addBtnIcon: {
      color: theme.colors.gray[5]
    }
  };
});

export default StakepoolsCard;
