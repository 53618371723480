import { withToggleOnEdit } from "../../../../utils/contexts";
import { IconX } from "@tabler/icons";
import { createStyles } from "@mantine/core";

const StakepoolTab = ({ label, onDelete }) => {
  const { classes } = useStyles();
  const EditIcon = withToggleOnEdit(IconX);

  return (
    <div className={classes.tabLabel}>
      <span>{label}</span>
      <EditIcon
        className={classes.deleteBtnIcon}
        size={16}
        stroke={1.5}
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      />
    </div>
  );
};

const useStyles = createStyles((theme) => {
  return {
    tabLabel: {
      display: "flex",
      alignItems: "center"
    },

    deleteBtnIcon: {
      color: theme.colors.gray[5],
      marginLeft: theme.spacing.xs,
      border: `1px solid ${theme.colors.gray[5]}`,
      borderRadius: "50%",

      "&:hover": {
        color: theme.colors.gray[3],
        border: `1px solid ${theme.colors.gray[3]}`
      }
    }
  };
});

export default StakepoolTab;
