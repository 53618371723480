import { useEffect, useRef } from "react";
import { useDebounce } from "use-debounce";
import { isEqual } from "lodash";

const useDeepCompareMemoize = (value) => {
  const ref = useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

const useDebouncedEffect = (effect, deps, debounceTime = 0) => {
  const [debouncedDeps] = useDebounce(deps, debounceTime, {
    equalityFn: isEqual
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, useDeepCompareMemoize(debouncedDeps));
};

export default useDebouncedEffect;
