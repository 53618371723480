import { useState } from "react";
import { Provider } from "react-redux";
import { reduxStore } from "./core/redux/reduxStore";
import {
  AppShell,
  Box,
  ColorSchemeProvider,
  MantineProvider,
  Stack
} from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import { useLocalStorage } from "@mantine/hooks";
import StratoProvider from "./core/providers/StratoProvider";
import AuthProvider from "./core/providers/AuthProvider";
import MainMenu from "./components/MainMenu";
import AppFooter from "./components/AppFooter";
import AppHeader from "./components/AppHeader";
import SmallBanner from "./components/reusable/SmallBanner";
import { HEDERA_NETWORK, IS_MAINNET } from "./utils/constants";

const COLOR_SCHEMA = {
  LIGHT: "light",
  DARK: "dark"
};

const Main = ({ children }) => {
  const [opened, setOpened] = useState(false);
  const [colorScheme, setColorScheme] = useLocalStorage({
    key: "color-scheme",
    defaultValue: "light",
    getInitialValueInEffect: true
  });

  const toggleColorScheme = (value) => {
    setColorScheme(
      value ||
        (colorScheme === COLOR_SCHEMA.DARK
          ? COLOR_SCHEMA.LIGHT
          : COLOR_SCHEMA.DARK)
    );
  };

  return (
    <Provider store={reduxStore}>
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <NotificationsProvider>
          <MantineProvider
            theme={{ colorScheme }}
            withGlobalStyles
            withNormalizeCSS
          >
            <ModalsProvider>
              <StratoProvider network={HEDERA_NETWORK}>
                <AuthProvider>
                  <AppShell
                    styles={(theme) => ({
                      main: {
                        background:
                          theme.colorScheme === "dark"
                            ? theme.colors.dark[8]
                            : theme.colors.gray[0]
                      }
                    })}
                    padding={0}
                    navbarOffsetBreakpoint="sm"
                    asideOffsetBreakpoint="sm"
                    navbar={<MainMenu opened={opened} />}
                    footer={<AppFooter />}
                    header={
                      <AppHeader opened={opened} toggleMenu={setOpened} />
                    }
                  >
                    <Stack>
                      {!IS_MAINNET && (
                        <SmallBanner variant="error">
                          You are currently using {HEDERA_NETWORK}
                        </SmallBanner>
                      )}
                      <Box px="sm">{children}</Box>
                    </Stack>
                  </AppShell>
                </AuthProvider>
              </StratoProvider>
            </ModalsProvider>
          </MantineProvider>
        </NotificationsProvider>
      </ColorSchemeProvider>
    </Provider>
  );
};

export default Main;
