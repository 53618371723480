import { useState, useContext } from "react";
import {
  Paper,
  Stack,
  Text,
  Center,
  Box,
  Divider,
  Alert,
  CopyButton,
  ActionIcon,
  Tooltip,
  Group,
  Select
} from "@mantine/core";
import TabbedContent from "../../components/reusable/TabbedContent";
import MerkleUploader from "./MerkleUploader";
import utilities from "../../utils/utilities";
import MerkleWhitelistEditor from "./MerkleWhitelistEditor";
import { useUploadMerkleWhitelistMutation } from "../../APIs/Aws.api";
import { AuthContext } from "../../core/providers/AuthProvider";
import walletUtils from "../../utils/walletUtils";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX, IconCopy, IconInfoCircle } from "@tabler/icons";
import {
  hasValidationErrors,
  validateWhitelistData
} from "./MerkleWhitelistHelper";
import { PROJECT_TYPES } from "../../utils/constants";
import { openConfirmModal } from "@mantine/modals";

const discountTooltipInfo = (
  <Stack spacing={0}>
    <Text>100 = 1% discount</Text>
    <Text>1000 = 10% discount</Text>
    <Text>5000 = 50% discount</Text>
    <Text>10000 = 100% discount</Text>
  </Stack>
);

export const MERKLE_ALL_CSV_HEADERS = {
  ACCOUNT: {
    value: "account"
  },
  AMOUNT: {
    value: "amount"
  },
  DISCOUNT_BPS: {
    value: "discountBps",
    icon: (
      <Tooltip label={discountTooltipInfo}>
        <ActionIcon size="1rem">
          <IconInfoCircle />
        </ActionIcon>
      </Tooltip>
    )
  }
};

export const MERKLE_CSV_HEADERS = {
  [PROJECT_TYPES.ino.value]: MERKLE_ALL_CSV_HEADERS,
  [PROJECT_TYPES.ido.value]: {
    ACCOUNT: MERKLE_ALL_CSV_HEADERS.ACCOUNT
  }
};

const MerkleWhitelist = () => {
  const [uploadMerkleWhitelist] = useUploadMerkleWhitelistMutation();
  const { connectedWallet } = useContext(AuthContext);
  const [state, setState] = useState({
    projectType: PROJECT_TYPES.ido.value,
    file: null,
    data: [],
    loading: false,
    response: null
  });

  const isINOWhitelist = state.projectType === PROJECT_TYPES.ino.value;

  const mapRows = (row, index) => {
    const arrRow = row.split(",");

    if (index === 0) return null;

    return isINOWhitelist ? arrRow : [arrRow[0]];
  };

  const filterNonEmptyRows = (row) =>
    Array.isArray(row) ? row.filter(Boolean).length > 0 : Boolean(row);

  const handleReadCSV = async (file) => {
    try {
      const result = await utilities.fileReader().readAsText(file);

      return result
        .split(/\r\n|\r/)
        .map(mapRows)
        .filter(filterNonEmptyRows);
    } catch (e) {
      console.error(e);
    }
  };

  const handleUploadCSV = async (file) => {
    const data = file ? await handleReadCSV(file) : [];

    setState((prevState) => ({
      ...prevState,
      file,
      data: validateWhitelistData(isINOWhitelist, data)
    }));
  };

  const handleChangeProjectType = (projectType) => {
    setState((prevState) => ({
      ...prevState,
      projectType
    }));
  };

  const handleSubmitWhitelist = async () => {
    const validatedData = validateWhitelistData(isINOWhitelist, state.data);

    if (hasValidationErrors(validatedData)) {
      setState((prevState) => ({
        ...prevState,
        data: validatedData
      }));

      showNotification({
        id: "whitelist-validation",
        color: "red",
        title: "Oops! Something's not quite right 🤔",
        message:
          "Let's double-check together! Hover over the alert icon on each field for details on what needs fixing. 🚀",
        icon: <IconX size={16} />,
        autoClose: 10000
      });

      return;
    }

    const payload = state.data.reduce(
      (acc, cur) => {
        acc += cur.join(",") + "\n";

        return acc;
      },
      Object.keys(MERKLE_CSV_HEADERS[state.projectType])
        .map((key) => MERKLE_CSV_HEADERS[state.projectType][key].value)
        .join(",") + ",\n"
    );

    try {
      setState((prevState) => ({
        ...prevState,
        loading: true
      }));

      const params = {
        projectType: state.projectType,
        payload,
        options: {
          headers: {
            "X-Connected-Wallet-Id": walletUtils
              .getAccountId(connectedWallet)
              .toString()
          }
        }
      };

      const response = await uploadMerkleWhitelist(params).unwrap();

      setState((prevState) => ({
        ...prevState,
        response
      }));
    } catch (e) {
      console.error(e);

      showNotification({
        id: "upload-whitelist",
        color: "red",
        title: "Failed to upload the whitelist",
        message: `${e?.message || e}. Check the console for more details`,
        icon: <IconX size={16} />,
        autoClose: 4000
      });
    } finally {
      setState((prevState) => ({
        ...prevState,
        loading: false
      }));
    }
  };

  const handleReset = () => {
    setState((prevState) => ({
      ...prevState,
      file: null,
      data: [],
      loading: false,
      response: null
    }));
  };

  const handleClearResponse = () => {
    setState((prevState) => ({
      ...prevState,
      response: null
    }));
  };

  const onChangeProjectTypeWithConfirmation = (projectType) =>
    openConfirmModal({
      title: "Please confirm your action",
      centered: true,
      children: (
        <Text size="sm">
          It looks like you've already set up a whitelist configuration, and
          changing the project type will reset your current progress. Are you
          sure you want to discard your current progress?
        </Text>
      ),
      labels: { confirm: "Switch", cancel: "Close" },
      onConfirm: () => {
        handleReset();
        handleChangeProjectType(projectType);
      }
    });

  const uploadOptionsTabs = [
    {
      key: "upload-json",
      label: "Upload CSV",
      content: (
        <div>
          <MerkleUploader value={state.file} onChange={handleUploadCSV} />

          {state.data.length > 0 && (
            <Box>
              <Divider my="1rem" />

              <MerkleWhitelistEditor
                projectType={state.projectType}
                data={state.data}
                onChange={setState}
                onSubmit={handleSubmitWhitelist}
                onReset={handleReset}
                loading={state.loading}
                previewOnly={true}
              />
            </Box>
          )}
        </div>
      )
    },
    {
      key: "csv-editor",
      label: "Editor",
      content: (
        <MerkleWhitelistEditor
          projectType={state.projectType}
          data={state.data}
          onChange={setState}
          loading={state.loading}
          onSubmit={handleSubmitWhitelist}
          onReset={handleReset}
        />
      )
    }
  ];

  return (
    <Paper mt="lg" p="lg" radius="md" withBorder>
      <Stack>
        <Center>
          <Text>Merkle whitelist</Text>
        </Center>
        <Select
          label="Project type:"
          data={[
            { value: PROJECT_TYPES.ido.value, label: PROJECT_TYPES.ido.label },
            { value: PROJECT_TYPES.ino.value, label: PROJECT_TYPES.ino.label }
          ]}
          value={state.projectType}
          onChange={
            state.data.length
              ? onChangeProjectTypeWithConfirmation
              : handleChangeProjectType
          }
        />
        <TabbedContent tabs={uploadOptionsTabs} />
        {state.response && (
          <Alert
            icon={<IconCheck size="1rem" />}
            title="Hooray!"
            color="green"
            onClose={handleClearResponse}
            withCloseButton
          >
            <Group spacing={5}>
              <Text>{state.response.message}</Text>
              <CopyButton value={state.response.root} timeout={2000}>
                {({ copied, copy }) => (
                  <Tooltip
                    label={copied ? "Copied" : "Copy"}
                    withArrow
                    position="right"
                  >
                    <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                      {copied ? (
                        <IconCheck size="1rem" />
                      ) : (
                        <IconCopy size="1rem" />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Group>
          </Alert>
        )}
      </Stack>
    </Paper>
  );
};

export default MerkleWhitelist;
