import {
  Button,
  createStyles,
  Box,
  Table,
  Space,
  Text,
  ActionIcon,
  Group,
  Tooltip,
  Stack
} from "@mantine/core";
import { MERKLE_CSV_HEADERS } from "./MerkleWhitelist";
import {
  IconAlertTriangle,
  IconCheck,
  IconPlus,
  IconRefresh,
  IconRocket,
  IconTrash
} from "@tabler/icons";
import EditableText from "../../components/editable/EditableText";
import {
  hasValidationErrors,
  validateWhitelistData
} from "./MerkleWhitelistHelper";
import { PROJECT_TYPES } from "../../utils/constants";

const MerkleWhitelistEditor = ({
  projectType,
  data,
  onChange,
  onSubmit,
  onReset,
  loading,
  previewOnly = false
}) => {
  const { classes, cx } = useStyles();
  const hasErrors = hasValidationErrors(data);
  const isINOWhitelist = projectType === PROJECT_TYPES.ino.value;

  const handleAddField = () => {
    onChange((prevState) => ({
      ...prevState,
      data: [
        ...prevState.data,
        isINOWhitelist ? ["0.0.0", "0", "0", []] : ["0.0.0", []]
      ]
    }));
  };

  const handleCellChange = (rowIndex, cellIndex, value) => {
    onChange((prevState) => ({
      ...prevState,
      data: prevState.data.map((row, i) => {
        if (i === rowIndex) {
          return row.map((cell, j) => (j === cellIndex ? value : cell));
        }
        return row;
      })
    }));
  };

  const handleValidate = () => {
    onChange((prevState) => ({
      ...prevState,
      data: validateWhitelistData(isINOWhitelist, prevState.data)
    }));
  };

  const handleRemoveRow = (index) => {
    onChange((prevState) => ({
      ...prevState,
      data: data.filter((_, i) => i !== index)
    }));
  };

  return (
    <Box>
      <Table striped>
        <thead>
          <tr>
            {Object.keys(MERKLE_CSV_HEADERS[projectType]).map((key) => {
              const { value, icon } = MERKLE_CSV_HEADERS[projectType][key];

              return (
                <th key={value}>
                  <Group spacing={5} align="center">
                    {value}
                    {icon}
                  </Group>
                </th>
              );
            })}
            {hasErrors && <th>validation</th>}
            {!previewOnly && <th>actions</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((entry, rowIndex) => {
            const [account, amount, discount] = entry;
            const errors = entry[entry.length - 1];

            const errorsTooltip = hasErrors && (
              <Stack spacing={0}>
                {entry[entry.length - 1]?.map((error, index) => {
                  return (
                    <Text key={`${rowIndex}-error-${index}`}>{error}</Text>
                  );
                })}
              </Stack>
            );

            return (
              <tr
                className={cx(
                  classes.tableRow,
                  `${errors.length > 0 ? "error" : ""}`
                )}
                key={`row-${rowIndex}`}
              >
                {(isINOWhitelist ? [account, amount, discount] : [account]).map(
                  (cell, cellIndex) => {
                    return (
                      <td
                        key={`cell-${cellIndex}`}
                        className={classes.editableTextWrapper}
                      >
                        <Group>
                          {previewOnly ? (
                            <Text>{cell || "-"}</Text>
                          ) : (
                            <EditableText
                              className="editable-cell"
                              value={cell || "-"}
                              onChange={(value) =>
                                handleCellChange(rowIndex, cellIndex, value)
                              }
                              onBlur={handleValidate}
                            />
                          )}
                        </Group>
                      </td>
                    );
                  }
                )}
                {hasErrors && (
                  <td>
                    {errors?.length > 0 ? (
                      <Tooltip
                        position="top"
                        color="gray"
                        label={errorsTooltip}
                      >
                        <ActionIcon variant="filled" color="orange" size="sm">
                          <IconAlertTriangle />
                        </ActionIcon>
                      </Tooltip>
                    ) : (
                      <IconCheck color="green" />
                    )}
                  </td>
                )}
                {!previewOnly && (
                  <td>
                    <ActionIcon
                      variant="filled"
                      color="red"
                      size="sm"
                      onClick={() => handleRemoveRow(rowIndex)}
                    >
                      <IconTrash size="1rem" />
                    </ActionIcon>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {!previewOnly && (
        <Box>
          <Space my="1rem" />
          <Button variant="light" p="xs" onClick={handleAddField} fullWidth>
            <IconPlus size="1rem" />
          </Button>
        </Box>
      )}

      <Space my="1rem" />
      <Stack>
        <Button
          loading={loading}
          onClick={onSubmit}
          disabled={!data.length || hasErrors}
          leftIcon={<IconRocket />}
          fullWidth
        >
          Submit
        </Button>
        {data.length > 0 && (
          <Button
            color="gray"
            onClick={onReset}
            leftIcon={<IconRefresh />}
            fullWidth
          >
            Reset
          </Button>
        )}
      </Stack>
    </Box>
  );
};

const useStyles = createStyles(() => ({
  tableRow: {
    "&.error": {
      color: "red"
    }
  },
  editableTextWrapper: {
    ".edit-btn": {
      top: 0,
      right: -30,
      width: 22,
      minWidth: 22,
      height: 22,
      minHeight: 22,

      ".edit-btn-icon": {
        width: "12px",
        height: "12px"
      }
    },

    ".editable-cell": {
      minHeight: "1.4rem"
    }
  },
  editableText: {
    fontSize: 20,
    fontWeight: 700,

    input: {
      fontSize: 20,
      fontWeight: 700
    }
  }
}));

export default MerkleWhitelistEditor;
