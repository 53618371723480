import { useCallback, useState } from "react";
import { Textarea } from "@mantine/core";
import { throttle } from "lodash";

const THROTTLE_DELAY = 500;
const INVALID_JSON_ERROR = "Invalid JSON syntax";

const AbiTextInput = ({
  onUpdateJSON = null,
  onUpdateText = null,
  error = null,
  onError = null,
  initialValue = ""
}) => {
  const [state, setState] = useState({ value: initialValue });

  // eslint-disable-next-line
  const throttledUpdateABIText = useCallback(
    throttle(
      (abi) => "function" === typeof onUpdateText && onUpdateText(abi),
      THROTTLE_DELAY
    ),
    [onUpdateText]
  );

  // eslint-disable-next-line
  const throttledUpdateABIJson = useCallback(
    throttle(
      (abi) => "function" === typeof onUpdateJSON && onUpdateJSON(abi),
      THROTTLE_DELAY
    ),
    [onUpdateJSON]
  );

  const handleOnChange = (e) => {
    setState((prevState) => ({ ...prevState, value: e.target.value }));
    throttledUpdateABIText(e.target.value);

    try {
      const jABI = JSON.parse(e.target.value);
      throttledUpdateABIJson(jABI);
    } catch (e) {
      if ("function" === typeof onError) {
        onError(INVALID_JSON_ERROR);
      }
    }
  };

  return (
    <Textarea
      autosize={true}
      placeholder="functions ..."
      value={state.value}
      onChange={handleOnChange}
      error={error}
    />
  );
};

export default AbiTextInput;
