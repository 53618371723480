import {
  createStyles,
  useMantineTheme,
  Footer,
  Grid,
  Image,
  Group,
  Anchor,
  Button
} from "@mantine/core";
import ThemeSwitcher from "./reusable/ThemeSwitcher";
import { IconBrandGithub } from "@tabler/icons";
import BdlWhiteLogo from "../assets/media/bdl-white-logo.png";
import BdlBlackLogo from "../assets/media/bdl-black-logo.png";
import { useMemo } from "react";

const GITHUB_REPO_URL = "https://github.com/buidler-labs/headstarter";

const AppFooter = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const logoSrc = theme.colorScheme === "dark" ? BdlWhiteLogo : BdlBlackLogo;

  const gitInfo = useMemo(() => {
    const commitUrl = GITHUB_REPO_URL + "/commit/" + process.env.COMMIT_SHA;

    return (
      <Group>
        <Anchor href={commitUrl} target="_blank">
          <Button variant="light" leftIcon={<IconBrandGithub />}>
            {process.env.COMMIT_VERSION}
          </Button>
        </Anchor>
      </Group>
    );
  }, []);

  return (
    <Footer height={60} p="lg">
      <Grid
        className={classes.footerContainer}
        justify="space-between"
        align="center"
      >
        <Image width={100} src={logoSrc} alt="BuidlerLabs Logo" />
        <Group>
          {gitInfo}
          <ThemeSwitcher />
        </Group>
      </Grid>
    </Footer>
  );
};

const useStyles = createStyles(() => {
  return {
    footerContainer: {
      display: "flex",
      alignItems: "center",
      height: "100%"
    }
  };
});

export default AppFooter;
