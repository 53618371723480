import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { ApiSession } from "headstarter-crypto/hashgraph-venin";
import { SUPPORTED_WALLETS } from "../../components/reusable/WalletConnectBtn/WalletConnectBtn";

export const StratoContext = createContext({});

const StratoProvider = ({ network, children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [balance, setBalance] = useState();
  const [hapiSession, setHapiSession] = useState(null);
  const connectedWallet = useRef(window["hedera"]);

  const disconnectWallet = useCallback(async () => {
    if (connectedWallet.current) {
      if (typeof connectedWallet.current.wipePairingData === "function") {
        connectedWallet.current.wipePairingData();
      }

      window["hedera"] = connectedWallet.current = null;
      setIsConnected(false);
    }
  }, []);

  const connectWallet = useCallback(
    (_wallet) => {
      fetchBalance(_wallet)
        .then((bal) => {
          window["hedera"] = connectedWallet.current = _wallet;
          setBalance(bal);
          setIsConnected(true);
        })
        .catch(() => {
          setBalance(null);
          disconnectWallet();
        });
    },
    [disconnectWallet]
  );

  const fetchBalance = async (wallet) => {
    if (!wallet) {
      throw new Error("Cannot fetch the balance if there no wallet connected");
    }

    return await wallet.getAccountBalance();
  };

  const updateBalance = useCallback(async () => {
    fetchBalance(connectedWallet.current)
      .then((bal) => setBalance(bal))
      .catch((error) => {
        console.error(error);
        setBalance(null);
        disconnectWallet();
      });
  }, [disconnectWallet]);

  const getConnection = useCallback(async () => {
    SUPPORTED_WALLETS.map(async ({ name, wallet, enabled }) => {
      if (enabled) {
        const _wallet = await wallet.getConnection();

        if (_wallet) {
          console.log(name + " is connected");
          connectWallet(_wallet);
        }
      }
    });
  }, [connectWallet]);

  useEffect(() => {
    getConnection();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const initHapiSession = async () => {
      try {
        if (connectedWallet.current) {
          const { session } = await ApiSession.default({
            wallet: { type: "Browser" }
          });
          setHapiSession(session);
        }
      } catch (e) {
        console.error("Cannot initialize the session. Error: ", e);
      }
    };

    if (isConnected) {
      initHapiSession();
    }
  }, [isConnected]);

  const value = useMemo(
    () => ({
      network,
      strato: {
        session: hapiSession
      },
      wallet: {
        payload: connectedWallet.current,
        balance,
        updateBalance,
        connected: isConnected,
        connect: connectWallet,
        disconnect: disconnectWallet
      }
    }),
    [
      network,
      balance,
      connectWallet,
      disconnectWallet,
      hapiSession,
      updateBalance,
      isConnected
    ]
  );

  return (
    <StratoContext.Provider value={value}>{children}</StratoContext.Provider>
  );
};

export default StratoProvider;
