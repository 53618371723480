import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import projectsReducers from "./reducers/projects.reducers";
import { awsApi } from "../../APIs/Aws.api";
import { mirrorNodeApi } from "../../APIs/MirrorNode.api";

export const reduxStore = configureStore({
  reducer: {
    projects: projectsReducers,
    [awsApi.reducerPath]: awsApi.reducer,
    [mirrorNodeApi.reducerPath]: mirrorNodeApi.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(awsApi.middleware)
      .concat(mirrorNodeApi.middleware);
  }
});

export const dispatchAction = async (action) => {
  const subscription = reduxStore.dispatch(action);
  const response = await subscription;
  subscription.unsubscribe();

  if (response.isError) {
    const {
      error: { data, error }
    } = response;
    throw new Error(data?.message || error);
  }

  return response;
};

// enable listener behavior for the store
setupListeners(reduxStore.dispatch);
