import {
  Group,
  Text,
  Stack,
  useMantineTheme,
  createStyles
} from "@mantine/core";
import { IconUpload, IconPhoto, IconX } from "@tabler/icons";
import { FileInput } from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import ImageCropper from "./ImageCropper";
import { Fragment, useEffect, useState } from "react";

const DropzoneInput = ({
  label,
  accept,
  value,
  onChange,
  passClearStateHandler,
  ...props
}) => {
  const [staticPreview, setStaticPreview] = useState(null);
  const cropperAspectRatio = label === "Logo" ? 1 : 16 / 9;
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const handleOnChange = (file) => {
    if (!file || IMAGE_MIME_TYPE.indexOf(file.type) > -1) {
      setStaticPreview(file);
    }

    onChange(file);
  };

  const handleClearState = () => {
    setStaticPreview(null);
  };

  useEffect(() => {
    if (typeof passClearStateHandler === "function") {
      passClearStateHandler(label, handleClearState);
    }
  }, [passClearStateHandler, label]);

  const file = staticPreview || value;

  return (
    <Stack mt={5} spacing={8}>
      <FileInput
        label={label}
        icon={<IconUpload size={14} />}
        accept={Object.values(accept).flat().join(", ")}
        value={staticPreview || value}
        onChange={handleOnChange}
        {...props}
        clearable
      />
      {file ? (
        <Fragment>
          {
            staticPreview ? (
              <div className={classes.imageCropperContainer}>
                <ImageCropper
                  className={classes.imageCropper}
                  value={staticPreview}
                  onChange={onChange}
                  aspect={cropperAspectRatio}
                  keepSelection={true}
                  mime={file.type}
                />
              </div>
            ) : null //TODO: better, we can read the .md file content and show the rich text editor as preview only
          }
        </Fragment>
      ) : (
        <Dropzone
          onDrop={(files) => handleOnChange(files?.length && files[0])}
          accept={accept}
          {...props}
        >
          <Group
            position="center"
            spacing="xl"
            className={classes.dropzoneStatesGroup}
          >
            <Dropzone.Accept>
              <IconUpload
                size={50}
                stroke={1.5}
                color={
                  theme.colors[theme.primaryColor][
                    theme.colorScheme === "dark" ? 4 : 6
                  ]
                }
              />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX
                size={50}
                stroke={1.5}
                color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
              />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconPhoto size={50} stroke={1.5} />
            </Dropzone.Idle>

            <div>
              <Text size="xl" inline>
                Drag files here or click to select
              </Text>
              <Text size="sm" color="dimmed" inline mt={7}>
                Only one file is accepted
              </Text>
            </div>
          </Group>
        </Dropzone>
      )}
    </Stack>
  );
};

const useStyles = createStyles((theme) => {
  return {
    imageCropperContainer: {
      marginTop: "1rem",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      justifyContent: "center",

      ".ReactCrop__crop-selection": {
        borderColor:
          theme.colorScheme === "dark"
            ? theme.colors.gray[5]
            : theme.colors.gray[7],

        ".ReactCrop__drag-elements": {
          ".ReactCrop__drag-handle": {
            "&.ord-nw, &.ord-ne, &.ord-sw, &.ord-se": {
              "&::after": {
                borderColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.gray[5]
                    : theme.colors.gray[7]
              }
            }
          }
        }
      }
    },
    dropzoneStatesGroup: {
      minHeight: 220,
      pointerEvents: "none"
    },
    imageCropper: {
      maxHeight: 400
    }
  };
});

export default DropzoneInput;
