import { useMemo, useState } from "react";
import { Text, TextInput } from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";
import WithEditableField from "../../HOCs/withEditableField";

const EditableText = ({
  className,
  value,
  formatted,
  onChange,
  onBlur,
  ...props
}) => {
  const [isEdit, setEdit] = useState(false);
  const Editable = useMemo(() => WithEditableField(TextComponent, "value"), []);

  const exitEditMode = () => {
    setEdit(false);
    if (typeof onBlur === "function") onBlur();
  };

  const handleOnChange = (e) => {
    const _value =
      props.type === "number" ? Number(e.target.value) : e.target.value;

    onChange(_value);
  };

  const handleOnEnterKey = (e) => {
    if (e.key === "Enter") {
      exitEditMode();
    }
  };

  const ref = useClickOutside(exitEditMode);

  return isEdit ? (
    <TextInput
      className={`${className} editable`}
      ref={ref}
      value={value}
      onChange={handleOnChange}
      onBlur={exitEditMode}
      onKeyDown={handleOnEnterKey}
      {...props}
      autoFocus
    />
  ) : (
    <Editable
      className={`${className} editable`}
      value={formatted ?? value}
      onEdit={() => setEdit(true)}
      {...props}
    />
  );
};

const TextComponent = ({ value, onEdit, ...props }) => (
  <Text {...props}>{value}</Text>
);

export default EditableText;
