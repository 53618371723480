import FunctionForm from "./FunctionForm";

const LiveContractExecutor = ({ contract }) => {
  const displayedContractFunctions = contract
    ? Object.entries(contract.interface.functions).filter(
        (fn) => fn[1]["type"] === "function"
      )
    : [];

  return displayedContractFunctions.map((contractFuncInfo) => {
    const functionDefinition = contractFuncInfo[0];
    const functionFragment = contractFuncInfo[1];
    const contractFunc = contract[functionFragment.name];

    if (typeof contractFunc === "function") {
      return (
        <FunctionForm
          key={"function-form-" + functionDefinition}
          contractFunction={contractFunc}
          functionInfo={functionFragment}
        />
      );
    }
    return null;
  });
};

export default LiveContractExecutor;
