import { useState } from "react";
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Group,
  Paper,
  Stack,
  Table,
  Text,
  TextInput,
  createStyles
} from "@mantine/core";
import {
  IconFileX,
  IconPlus,
  IconSearch,
  IconTrash,
  IconUser,
  IconX
} from "@tabler/icons";
import { useInputState } from "@mantine/hooks";
import { closeAllModals, openModal, openConfirmModal } from "@mantine/modals";
import { REGEX_PATTERNS } from "../../../utils/constants";
import { showNotification } from "@mantine/notifications";
import WithEditableConfig from "../../../HOCs/withEditableField";

const PrivateAccessAccounts = ({ isEdit, ...rest }) => {
  const { classes, cx } = useStyles();
  const accountsArr = rest?.value ? rest?.value?.split(",") : [];

  const [searchTerm, setSearchTerm] = useInputState("");

  const handleAddAccount = (accounts) => {
    const updatedAccounts = [accounts, ...accountsArr];
    rest.onChange(updatedAccounts.join(","));
  };

  const handleDeleteAccount = (_accountId) => {
    const updatedAccounts = accountsArr.filter(
      (accountId) => accountId !== _accountId
    );
    rest.onChange(updatedAccounts.join(","));
  };

  const openAddAccountModal = () => {
    openModal({
      title: "Add a new account",
      children: <NewAccountModal onAdd={handleAddAccount} />
    });
  };

  const openDeleteConfirmationModal = (accountId) => {
    openConfirmModal({
      title: "Delete account access",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete {accountId}?</Text>
      ),
      labels: { confirm: "Delete", cancel: "No don't delete it" },
      confirmProps: { color: "red" },
      onCancel: closeAllModals,
      onConfirm: () => handleDeleteAccount(accountId)
    });
  };

  const filteredAccounts = accountsArr.filter(
    (accountId) => accountId.indexOf(searchTerm.toLowerCase()) > -1
  );

  const rows = filteredAccounts.map((accountId) => (
    <tr key={accountId}>
      <td>{accountId}</td>
      {isEdit && (
        <td>
          <Group position="right">
            <ActionIcon
              size="sm"
              variant="filled"
              color="red"
              onClick={() => openDeleteConfirmationModal(accountId)}
            >
              <IconTrash />
            </ActionIcon>
          </Group>
        </td>
      )}
    </tr>
  ));

  return (
    <Stack>
      <Text>Private access accounts:</Text>
      <Group position="apart" align="start">
        <Paper py={6} px="xs" withBorder>
          <Group>
            <Group>
              <IconUser />
              <Text size="sm">Accounts: {filteredAccounts.length}</Text>
            </Group>
            {isEdit && (
              <ActionIcon size="sm" onClick={openAddAccountModal}>
                <IconPlus />
              </ActionIcon>
            )}
          </Group>
        </Paper>
        <TextInput
          placeholder="0.0.xxxx"
          rightSection={<IconSearch />}
          value={searchTerm}
          onChange={setSearchTerm}
        />
      </Group>
      <Box className={classes.tableWrapper}>
        <Table className={cx(classes.table, `${isEdit ? "editable" : ""}`)}>
          <thead>
            <tr>
              <th>Account</th>
              {isEdit && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 ? (
              rows
            ) : (
              <tr>
                <td colSpan={2}>
                  <Stack>
                    <Center>
                      <IconFileX />
                      <Text color="dimmed" ml="xs">
                        No accounts found
                      </Text>
                    </Center>
                  </Stack>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Box>
    </Stack>
  );
};

const NewAccountModal = ({ onAdd }) => {
  const [newAccounts, setNewAccounts] = useInputState("");

  const handleSubmitNewAccounts = (e) => {
    e.preventDefault();
    const _accountsArr = newAccounts
      .split(",")
      .map((accountId) => accountId.trim());

    if (
      !newAccounts ||
      _accountsArr.some(
        (acc) => !new RegExp(REGEX_PATTERNS.hederaAccount).test(acc)
      )
    ) {
      return showNotification({
        id: "no-empty-account-id",
        icon: <IconX />,
        autoClose: 4000,
        color: "red",
        title: "Unable to add the account(s)",
        message:
          "One or more accounts are invalid. Please check your input and try again",
        loading: false
      });
    }

    onAdd(_accountsArr);
    closeAllModals();
  };

  return (
    <form onSubmit={handleSubmitNewAccounts}>
      <Stack>
        <TextInput
          label="Account ID(s)"
          placeholder="0.0.xxxx, 0.0.xxxx"
          value={newAccounts}
          onChange={setNewAccounts}
        />
        <Button type="submit">Add</Button>
      </Stack>
    </form>
  );
};

const EditablePrivateAccessAccounts = (props) => {
  const { classes, cx } = useStyles();
  const [isEdit, setEdit] = useState(false);
  const Editable = WithEditableConfig(PrivateAccessAccounts, "value");

  return (
    <Editable
      className={cx(classes.editable, "test")}
      isEdit={isEdit}
      onEdit={() => setEdit(!isEdit)}
      {...props}
    />
  );
};

const useStyles = createStyles(() => {
  return {
    editable: {
      position: "relative",
      width: "100%",

      ".edit-btn": {
        right: 0
      }
    },
    tableWrapper: {
      maxHeight: 300,
      overflowY: "auto"
    },
    table: {
      "&.editable": {
        "th:last-child, td:last-child": {
          textAlign: "right"
        }
      }
    }
  };
});

export default EditablePrivateAccessAccounts;
