import { gql } from "@apollo/client";

export const STAKEPOOLS_QUERY = gql`
  query GetStakepools {
    stakepools {
      id
      rewardToken
    }
  }
`;

export const getStakepoolById = (id) => gql`
    query GetStakepoolById {
      stakepool(id: "${id}") {
        rewardToken
      }
    }
`;
