import {
  createStyles,
  Grid,
  LoadingOverlay,
  Paper,
  Stack
} from "@mantine/core";
import GeneralInfo from "./components/GeneralInfo";
import TimelineComponent from "./components/Timeline";
import EditableDescription from "../../components/editable/EditableDescription";
import StakepoolsCard from "./components/Stakepools/StakepoolsCard";

const SPOOLProjectView = ({ project, form }) => {
  const { classes } = useStyles();

  return (
    <Grid m={0} mt={20}>
      <LoadingOverlay visible={!project} overlayBlur={2} />

      <Grid.Col md={6}>
        <Stack spacing={20}>
          <GeneralInfo
            project={project}
            name={form.getInputProps("name")}
            subTitle={form.getInputProps("subTitle")}
            author={form.getInputProps("author")}
            status={project?.status}
            logo={form.getInputProps("logo")}
            thumbnail={form.getInputProps("thumbnail")}
          />
        </Stack>
      </Grid.Col>

      <Grid.Col md={6}>
        <Stack spacing={20}>
          <Paper p="md" radius="md" withBorder>
            <TimelineComponent
              isEditable={form.getInputProps("pools")?.value?.length === 0}
            />
          </Paper>

          <StakepoolsCard
            projectKey={form.getInputProps("key")}
            stakepools={form.getInputProps("spools")}
          />
        </Stack>
      </Grid.Col>

      <Grid.Col className={classes.descriptionWrapper} xs={12}>
        <EditableDescription
          className="description"
          projectKey={project?.key}
          description={form.getInputProps("description")}
        />
      </Grid.Col>
    </Grid>
  );
};

const useStyles = createStyles(() => {
  return {
    ipfsGatewayWrapper: {
      paddingRight: 20,

      ".edit-btn": {
        top: 0,
        right: -30,
        width: 22,
        minWidth: 22,
        height: 22,
        minHeight: 22,

        ".edit-btn-icon": {
          width: "12px",
          height: "12px"
        }
      }
    },
    ipfsGatewayText: {
      "& > div": {
        fontSize: 14
      }
    },
    descriptionWrapper: {
      ".editable-wrapper": {
        width: "100%"
      }
    }
  };
});

export default SPOOLProjectView;
