import { Fragment, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons";
import BreadcrumbsComponent from "../../components/reusable/BreadcrumbsComponent";
import { useForm } from "@mantine/form";
import ProjectForm from "../../forms/projectForm";
import utilities from "../../utils/utilities";
import ProjectActions from "./components/ProjectActions";
import {
  DEFAULT_NOTIFICATION_DELAY,
  MEDIA_DEFAULT_NAMES,
  PROJECT_ACTIONS,
  PROJECT_TYPES
} from "../../utils/constants";
import IDOProjectView from "./IDOProjectView";
import INOProjectView from "./INOProjectView";
import {
  useGetProjectByKeyQuery,
  useUpdateProjectMutation,
  useUploadContractInfoMutation,
  useUploadMediaMutation
} from "../../APIs/Aws.api";
import { LoadingOverlay } from "@mantine/core";
import SPOOLProjectView from "./SPOOLProjectView";
import HSTGraphProvider from "../../core/providers/HSTGraphProvider";

let initialFormValues = null;

const ProjectPage = () => {
  const { key } = useParams();
  const {
    data: project,
    isLoading,
    isError,
    error
  } = useGetProjectByKeyQuery({
    key,
    withContractInfo: true
  });
  const [updateProject] = useUpdateProjectMutation();
  const [uploadMedia] = useUploadMediaMutation();
  const [uploadContractInfo] = useUploadContractInfoMutation();
  const navigate = useNavigate();
  const form = useForm(ProjectForm);

  const breadcrumbsItems = [
    { title: "Projects", href: "/projects" },
    { title: project?.name || "N/A", href: "#", disabled: true }
  ];

  const activeChanges = useMemo(() => {
    const values = utilities.getChanges(initialFormValues, form.values);

    return {
      values,
      count: Object.keys(values).length
    };
  }, [form.values]);

  const populateFormData = useCallback(() => {
    const formValues = {
      key: project?.key,
      projectType: project?.type,
      status: project?.status,
      name: project?.name || "",
      subTitle: project?.subTitle || "",
      author: project?.author || "",
      saleLabel: project?.saleLabel || "",
      privateAccessTo: project?.privateAccessTo || "",
      whitelistUrl: project?.whitelistUrl || "",
      whitelistMerkle: project?.whitelistMerkle || "",
      whitelistStartDate: project?.whitelistStartDate || "",
      whitelistEndDate: project?.whitelistEndDate || "",
      startDate: project?.startDate || "",
      endDate: project?.endDate || "",
      redeemDate: project?.redeemDate || "",
      maxAllocationUsd: project?.maxAllocationUsd || "",
      totalRaiseUsd: project?.totalRaiseUsd || "",
      initialTokenCirculation: project?.initialTokenCirculation || "",
      initialMarketCapUsd: project?.initialMarketCapUsd || "",
      nftsOnSale: project?.nftsOnSale || "",
      ipfsGateway: project?.ipfsGateway || "",
      tokenName: project?.tokenName || "",
      tokenSymbol: project?.tokenSymbol || "",
      tokenPriceInUsd: project?.tokenPriceInUsd || "",
      pools: project?.pools || [],
      spools: project?.spools || [],
      storefronts: project?.storefronts || [],
      logo: project?.logo,
      thumbnail: project?.thumbnail,
      description: project?.description
    };

    form.setValues(formValues);
    initialFormValues = formValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const uploadMediaFiles = async (payload) => {
    //Check for the media dirtiness, then upload them if is needed
    const { key, logo, thumbnail } = payload;

    if (form.isDirty("logo")) {
      await uploadMedia({
        projectKey: key,
        file: logo,
        fileName: MEDIA_DEFAULT_NAMES.LOGO
      });
    }

    if (form.isDirty("thumbnail")) {
      await uploadMedia({
        projectKey: key,
        file: thumbnail,
        fileName: MEDIA_DEFAULT_NAMES.THUMBNAIL
      });
    }

    return true;
  };

  const uploadContractInfoData = async (payload) => {
    if (form.isDirty("pools")) {
      const { key, startDate, endDate, redeemDate, pools } = payload;

      //Get only the new added pools
      const newAddedPools = pools.filter((pool) => {
        return (
          initialFormValues.pools.findIndex(
            (item) => item.contractId === pool.contractId
          ) === -1
        );
      });

      //Map the payload with all the needed fields
      const poolsPayload = newAddedPools.map((pool) => ({
        startTime: startDate,
        endTime: endDate,
        redeemTime: redeemDate,
        ...pool
      }));

      const poolsPromises = poolsPayload.map(async (pool) =>
        uploadContractInfo({ projectKey: key, payload: pool })
      );
      await Promise.allSettled(poolsPromises);
    }

    if (form.isDirty("storefronts")) {
      const { key, startDate: startTime, storefronts } = payload;

      //Get only the new added storefronts
      const newAddedStorefronts = storefronts
        .filter((storefront) => {
          return (
            initialFormValues.storefronts.findIndex(
              (item) => item.contractId === storefront.contractId
            ) === -1
          );
        })
        .map((storefront) => ({ ...storefront, startTime }));

      const storefrontsPromises = newAddedStorefronts.map(async (storefront) =>
        uploadContractInfo({
          projectKey: key,
          payload: storefront
        })
      );
      await Promise.allSettled(storefrontsPromises);
    }

    if (form.isDirty("spools")) {
      const { key, spools } = payload;

      //Get only the new added spools
      const newAddedSPools = spools.filter((pool) => {
        return (
          initialFormValues.spools.findIndex(
            (item) => item.address === pool.address
          ) === -1
        );
      });

      const spoolsPromises = newAddedSPools.map(async (spool) =>
        uploadContractInfo({
          projectKey: key,
          payload: { address: spool.address }
        })
      );
      await Promise.allSettled(spoolsPromises);
    }

    return true;
  };

  const handleOnSave = async () => {
    const projectType = form.values.projectType || "IDO";

    const commonFields = {
      type: projectType.toUpperCase(),
      ...(!!form.values.key && { key: form.values.key }),
      ...(!!form.values.name && { name: form.values.name }),
      ...(!!form.values.author && { author: form.values.author }),
      ...(!!form.values.saleLabel && { saleLabel: form.values.saleLabel }),
      ...(!!form.values.privateAccessTo && {
        privateAccessTo: form.values.privateAccessTo
      }),
      ...(!!form.values.status && { status: form.values.status }),
      ...(!!form.values.subTitle && { subTitle: form.values.subTitle }),
      ...(!!form.values.startDate && { startDate: form.values.startDate }),
      ...(!!form.values.endDate && { endDate: form.values.endDate }),
      ...(!!form.values.totalRaiseUsd && {
        totalRaiseUsd: form.values.totalRaiseUsd
      }),
      ...(!!form.values.logo && { logo: form.values.logo }),
      ...(!!form.values.thumbnail && { thumbnail: form.values.thumbnail }),
      ...(!!form.values.description && {
        description: utilities.escapeMarkdownString(form.values.description)
      }),
      ...(!!form.values.whitelistUrl && {
        whitelistUrl: form.values.whitelistUrl
      }),
      ...(!!form.values.whitelistMerkle && {
        whitelistMerkle: form.values.whitelistMerkle
      }),
      ...(!!form.values.whitelistEndDate && {
        whitelistEndDate: form.values.whitelistEndDate
      }),
      ...(!!form.values.whitelistStartDate && {
        whitelistStartDate: form.values.whitelistStartDate
      })
    };

    const payloadMapping = {
      [PROJECT_TYPES.ido.value]: {
        ...commonFields,
        initialMarketCapUsd: form.values.initialMarketCapUsd,
        initialTokenCirculation: form.values.initialTokenCirculation,
        tokenName: form.values.tokenName,
        tokenPriceInUsd: form.values.tokenPriceInUsd,
        tokenSymbol: form.values.tokenSymbol,
        maxAllocationUsd: form.values.maxAllocationUsd,
        endDate: form.values.endDate,
        redeemDate: form.values.redeemDate,
        pools: form.values.pools
      },
      [PROJECT_TYPES.ino.value]: {
        ...commonFields,
        nftsOnSale: form.values.nftsOnSale,
        ipfsGateway: form.values.ipfsGateway,
        storefronts: form.values.storefronts
      },
      [PROJECT_TYPES.spool.value]: {
        ...commonFields,
        spools: form.values.spools
      }
    };

    const payload = payloadMapping[projectType.toLowerCase()];

    try {
      showNotification({
        id: "update-project",
        loading: true,
        title: "Updating project",
        message: "This process can take a few seconds",
        autoClose: false,
        disallowClose: true
      });

      await uploadMediaFiles(payload);
      await uploadContractInfoData(payload);

      //Get rid of unnecessary fields and update the project
      delete payload.logo;
      delete payload.thumbnail;
      delete payload.pools;
      delete payload.storefronts;
      delete payload.spools;

      await updateProject({ payload })
        .unwrap()
        .then(() => {
          updateNotification({
            id: "update-project",
            color: "teal",
            title: "Success",
            message: "The project has been successfully updated",
            icon: <IconCheck size={16} />,
            autoClose: DEFAULT_NOTIFICATION_DELAY
          });

          form.resetDirty(form.values);
        })
        .then((error) => {
          if (error) {
            updateNotification({
              id: "update-project",
              color: "orange",
              title: "Conflict",
              message: error?.error || error,
              icon: <IconX size={16} />,
              autoClose: DEFAULT_NOTIFICATION_DELAY
            });

            return error;
          }

          return null;
        });
    } catch (e) {
      console.error(e);
      updateNotification({
        id: "update-project",
        color: "red",
        title: "Cannot update the project",
        message: `${e.error || e}. Check the console for more details`,
        icon: <IconX size={16} />,
        autoClose: 4000
      });
    }
  };

  const handleOnReset = () => {
    form.setValues(initialFormValues);
  };

  useEffect(() => {
    if (isError) {
      navigate("/not-found");

      showNotification({
        id: "get-project",
        color: "red",
        title: "Failed to fetch " + key,
        message: `${error?.error || error}. Check the console for more details`,
        icon: <IconX size={16} />,
        autoClose: 4000
      });
    }
  }, [isError, error, key, navigate]);

  useEffect(() => {
    if (project) {
      populateFormData(project);
    }
  }, [project, populateFormData]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const updateActions = initialFormValues?.status
    ? PROJECT_ACTIONS[initialFormValues?.status]
    : PROJECT_ACTIONS.DRAFTING;
  const detailsView = project
    ? (() => {
        const type = project.type.toLowerCase();

        switch (type) {
          case PROJECT_TYPES.ino.value:
            return <INOProjectView project={project} form={form} />;
          case PROJECT_TYPES.ido.value:
            return <IDOProjectView project={project} form={form} />;
          case PROJECT_TYPES.spool.value:
            return (
              <HSTGraphProvider endpoint="/Stakepools">
                <SPOOLProjectView project={project} form={form} />
              </HSTGraphProvider>
            );
          default:
            return null;
        }
      })()
    : null;

  return (
    <Fragment>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
      <BreadcrumbsComponent routes={breadcrumbsItems} />
      <ProjectActions
        hasChanges={activeChanges.count > 0}
        actions={updateActions}
        initialProjectStatus={initialFormValues?.status}
        projectKey={form.getInputProps("key")}
        projectStatus={form.getInputProps("status")}
        onSave={handleOnSave}
        onReset={handleOnReset}
      />
      {detailsView}
    </Fragment>
  );
};

export default ProjectPage;
