import { createStyles } from "@mantine/core";

const StakepoolTabContent = ({ data }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.tabContent}>
      <div>Stakepool address: {data.address}</div>
      <div>Reward token address: {data.rewardTokenAddress}</div>
      <div>Reward token symbol: {data.rewardTokenSymbol}</div>
    </div>
  );
};

const useStyles = createStyles(() => ({
  tabContent: {
    fontSize: 14
  }
}));

export default StakepoolTabContent;
