import { HEDERA_NETWORK } from "./constants";

const PROMISE_STATUS = {
  fulfilled: "fulfilled",
  rejected: "rejected"
};

const Utils = {
  /**
   * @param React.Component
   * @returns {string}
   */
  getDisplayName: (component) => {
    return component.displayName || component.name || "Component";
  },

  /**
   * @param date
   * @returns {boolean}
   */
  isDate: (date) => {
    return (
      date &&
      Object.prototype.toString.call(date) === "[object Date]" &&
      !isNaN(date)
    );
  },

  /**
   * @param value
   * @param prefix = '$'
   * @returns {string}
   */
  formatValueWithPrefix: (value, prefix = "$") => {
    if (value) {
      const formattedValue = value.toLocaleString();

      return prefix ? prefix + formattedValue : formattedValue;
    }

    return "TBA";
  },

  /**
   *
   * @returns {{
   *      readAsDataURL: (function(file, callback): Promise<string>),
   *      readAsText: (function(file, callback): Promise<string>)
   * }}
   */
  fileReader: () => {
    const read = async (file, cb) => {
      try {
        if (!file || !typeof file.name === "string") {
          throw new Error("You must provide a Blob or File type");
        }

        const fileReader = new FileReader();

        return new Promise((resolve, reject) => {
          if (fileReader && file) {
            fileReader.onload = () => resolve(fileReader.result);
            fileReader.onerror = () => reject("Unable to read " + file.name);

            cb(fileReader);
          } else {
            throw new Error("You must provide a file.");
          }
        });
      } catch (e) {
        console.error(e);
        return null;
      }
    };

    return {
      readAsText: async (file) =>
        read(file, (fileReader) => fileReader.readAsText(file, "utf-8")),
      readAsDataURL: async (file) =>
        read(file, (fileReader) => fileReader.readAsDataURL(file))
    };
  },

  /**
   * Generates a random alphanumeric UID of length
   * @param length
   * @returns {string}
   */
  alphaNumericUid: (length) => {
    return Math.random()
      .toString(36)
      .split("")
      .filter((value, index, self) => self.indexOf(value) === index)
      .join("")
      .substr(2, length);
  },

  /**
   *
   * @param markdown
   * @returns {string}
   */
  escapeMarkdownString: (markdown) => {
    if (!markdown) {
      return "";
    }

    const charsToEscape = {
      "\n": "\\n",
      "\r": "\\r"
    };

    return Object.keys(charsToEscape).reduce((prev, cur) => {
      prev = prev.replaceAll(cur, charsToEscape[cur]);
      return prev;
    }, markdown);
  },

  /**
   * @param o(Object)
   * @returns {string}
   */
  typeOf: function (o) {
    return Object.prototype.toString.call(o);
  },

  /**
   * @param o(Object)
   * @returns {boolean}
   */
  isObject: function (o) {
    return (
      o !== null &&
      !Array.isArray(o) &&
      this.typeOf(o).split(" ")[1].slice(0, -1) === "Object"
    );
  },

  /**
   * @param o(Object)
   * @returns {boolean}
   */
  isPrimitive: function (o) {
    switch (typeof o) {
      case "object": {
        return false;
      }
      case "function": {
        return false;
      }
      default: {
        return true;
      }
    }
  },

  /**
   * Get the differences between two primitives, arrays or objects
   * @param previous
   * @param current
   * @returns {string|*[]|*}
   * @source: https://stackoverflow.com/questions/38277385/design-pattern-to-check-if-a-javascript-object-has-changed
   */
  getChanges: function (previous, current) {
    if (this.isPrimitive(previous) && this.isPrimitive(current)) {
      if (previous === current) {
        return "";
      }

      return current;
    }

    if (this.isObject(previous) && this.isObject(current)) {
      const diff = this.getChanges(
        Object.entries(previous),
        Object.entries(current)
      );

      return diff.reduce((merged, [key, value]) => {
        return {
          ...merged,
          [key]: value
        };
      }, {});
    }

    const changes = [];

    if (JSON.stringify(previous) === JSON.stringify(current)) {
      return changes;
    }

    for (let i = 0; i < current.length; i++) {
      const item = current[i];

      if (JSON.stringify(item) !== JSON.stringify(previous[i])) {
        changes.push(item);
      }
    }

    return changes;
  },

  isHexString: (value, length) => {
    if (typeof value !== "string" || !value.match(/^0x[0-9A-Fa-f]*$/)) {
      return false;
    }
    if (typeof length === "number" && value.length !== 2 + 2 * length) {
      return false;
    }
    if (length === true && value.length % 2 !== 0) {
      return false;
    }
    return true;
  },

  objToContractExecutorShareParams: (obj) => {
    return Object.entries(obj)
      .map(([key, value]) => (value ? `${key}=${value}` : null))
      .filter(Boolean)
      .join("::");
  },

  filterSettledPromises: (promises) => {
    return promises
      .filter((promise) => promise.status === PROMISE_STATUS.fulfilled)
      .map((promise) => promise.value);
  },

  getHashscanUrl: function ({
    tokenId = null,
    accountId = null,
    contractId = null,
    transactionId = null
  }) {
    const baseUrl = "https://hashscan.io";

    switch (true) {
      case !!tokenId:
        return `${baseUrl}/${HEDERA_NETWORK}/token/${tokenId}`;
      case !!accountId:
        return `${baseUrl}/${HEDERA_NETWORK}/account/${accountId}`;
      case !!contractId:
        return `${baseUrl}/${HEDERA_NETWORK}/contract/${contractId}`;
      case !!transactionId:
        return `${baseUrl}/${HEDERA_NETWORK}/transaction/${transactionId}`;
      default:
        return baseUrl;
    }
  }
};

export default Utils;
