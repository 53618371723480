import { Fragment, useMemo, useState } from "react";
import { Button, Image, Modal } from "@mantine/core";
import WithEditableField from "../../HOCs/withEditableField";
import { IMAGE_MIME_TYPE } from "@mantine/dropzone";
import DropzoneInput from "../reusable/DropzoneInput";

const EditableImage = ({ className, label, value, onChange, ...props }) => {
  const [image, setImage] = useState(value);
  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setImage(null);
    setModalOpen(false);
  };

  const handleOnChange = (file) => {
    if (file) {
      setImage(file);
    }
  };

  const handleOnSave = () => {
    onChange(image);
    handleCloseModal();
  };

  const Editable = useMemo(() => WithEditableField(ImageComponent, "src"), []);

  return (
    <Fragment>
      <Modal
        opened={modalOpen}
        onClose={handleCloseModal}
        title={`Edit ${label}`}
        size="xl"
        centered
        withCloseButton
      >
        <DropzoneInput
          label={label}
          accept={IMAGE_MIME_TYPE}
          multiple={false}
          onChange={handleOnChange}
        />
        <Button type="submit" mt="sm" onClick={handleOnSave}>
          Save
        </Button>
      </Modal>

      <Editable
        className={className}
        src={value}
        onEdit={() => setModalOpen(true)}
        {...props}
      />
    </Fragment>
  );
};

const ImageComponent = ({ onEdit, ...props }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <Image
      {...props}
      onError={() => setLoaded(false)}
      onLoad={() => setLoaded(true)}
      withPlaceholder={!props.src || !loaded}
    />
  );
};

export default EditableImage;
