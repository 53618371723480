import { createContext, useContext } from "react";
import { AuthContext } from "../core/providers/AuthProvider";
import utilities from "../utils/utilities";

const EditableContext = createContext({});

const WithEditProjectContext = (WrappedComponent) => {
  const WithEditProjectContext = (props) => {
    const { connectedWallet } = useContext(AuthContext);

    return (
      <EditableContext.Provider value={{ editingEnabled: connectedWallet }}>
        <EditableContext.Consumer>
          {(value) => <WrappedComponent {...props} {...value} />}
        </EditableContext.Consumer>
      </EditableContext.Provider>
    );
  };

  WithEditProjectContext.displayName = `WithEditableConfig(${utilities.getDisplayName(WrappedComponent)})`;

  return WithEditProjectContext;
};

export default WithEditProjectContext;
