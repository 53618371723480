import React from "react";
import ReactDOM from "react-dom/client";
import Main from "./Main";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { withEditRouteToggle } from "./utils/contexts";

// COMPONENTS
import ProjectsList from "./views/ProjectsList/ProjectsList";
import CreateProject from "./views/CreateProject/CreateProject";
import ProjectPage from "./views/ProjectPage/ProjectPage";
import AuthPage from "./views/AuthPage";
import NotFound from "./views/NotFound";
import ContractsExecutor from "./views/ContractsExecutor/ContractsExecutor";
import MerkleWhitelist from "./views/MerkleWhitelist/MerkleWhitelist";

const EditableProjectPage = withEditRouteToggle(ProjectPage);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Router>
    <Main>
      <Routes>
        <Route path="/projects" element={<ProjectsList />} />
        <Route path="/projects/create" element={<CreateProject />} />
        <Route path="/projects/view/:key" element={<ProjectPage />} />
        <Route path="/projects/edit/:key" element={<EditableProjectPage />} />
        <Route path="/contracts-executor" element={<ContractsExecutor />} />
        <Route path="/merkle-whitelist" element={<MerkleWhitelist />} />
        <Route path="/" element={<Navigate to="/projects" />} />
        <Route path="/authenticate" element={<AuthPage />} />
        <Route path="not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="not-found" />} />
      </Routes>
    </Main>
  </Router>
  // </React.StrictMode>
);
