import { Stack } from "@mantine/core";
import { DESCRIPTION_CONFIG } from "../../../utils/constants";
import DropzoneInput from "../DropzoneInput";
import MarkdownEditor from "../MarkdownEditor";
import TabbedContent from "../TabbedContent";

const DescriptionFormField = ({ field, inputProps, onClearState }) => {
  const markdownToFile = (markdown) => {
    const blob = new Blob([markdown], {
      type: DESCRIPTION_CONFIG.DESCRIPTION_MIME_TYPE
    });

    const file = new File([blob], DESCRIPTION_CONFIG.DESCRIPTION_DEFAULT_NAME, {
      type: DESCRIPTION_CONFIG.DESCRIPTION_MIME_TYPE
    });

    inputProps.onChange(file);
  };

  const tabs = [
    {
      key: "upload-description-markdown",
      label: "Upload",
      content: (
        <DropzoneInput
          label="Description"
          accept={{
            [DESCRIPTION_CONFIG.DESCRIPTION_MIME_TYPE]:
              DESCRIPTION_CONFIG.DESCRIPTION_EXTENSIONS
          }}
          multiple={false}
          passClearStateHandler={onClearState}
          {...inputProps}
        />
      )
    },
    {
      key: "compose-description",
      label: "Rich Text Editor",
      content: (
        <MarkdownEditor
          initValue="Your description"
          onChange={(markdown) => markdownToFile(markdown)}
        />
      )
    }
  ];

  return (
    <Stack key={field.formRef} pb="md">
      <TabbedContent tabs={tabs} />
    </Stack>
  );
};

export default DescriptionFormField;
