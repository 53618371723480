import {
  createStyles,
  Paper,
  Title,
  Text,
  Button,
  Container,
  Group,
  Stack
} from "@mantine/core";
import { useContext } from "react";
import { AuthContext } from "../core/providers/AuthProvider";
import WalletConnectBtn from "../components/reusable/WalletConnectBtn/WalletConnectBtn";

const AuthPage = () => {
  const { classes } = useStyles();
  const { connected, connectedWallet, onAuthenticate } =
    useContext(AuthContext);

  return (
    <Container size={460} my={50}>
      <Title className={classes.title} align="center">
        HeadStarter - Authentication
      </Title>
      <Text color="dimmed" size="sm" align="center">
        In order to use the administrative panel, you have to be authenticated
      </Text>

      <Paper shadow="md" p={30} radius="md" mt="xl" withBorder>
        <Stack spacing={connected ? 10 : 0}>
          <Group position="apart" grow>
            {connected && (
              <Button className={classes.control} onClick={onAuthenticate}>
                Authenticate
              </Button>
            )}
          </Group>
          <Group position="apart" grow>
            <WalletConnectBtn
              className={classes.control}
              connectLabel="Connect and authenticate"
              disconnectLabel={`Disconnect (${connectedWallet?.getAccountId()})`}
            />
          </Group>
        </Stack>
      </Paper>
    </Container>
  );
};

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 26,
    fontWeight: 900
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      width: "100%",
      textAlign: "center"
    }
  }
}));

export default AuthPage;
