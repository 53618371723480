import { Badge } from "@mantine/core";
import { PROJECT_TYPES } from "../../utils/constants";
import { Fragment } from "react";

const ProjectTypeBadge = ({ project }) => {
  let projectType =
    PROJECT_TYPES[project.type?.toLowerCase()] || PROJECT_TYPES.default;
  let badges = [
    <Badge variant="gradient" gradient={projectType.badgeGradient}>
      {projectType.label}
    </Badge>
  ];

  if (project.saleLabel) {
    const saleBadge = {
      label: project.saleLabel,
      badgeGradient: {
        from: project.saleLabel === "IHO" ? "orange" : "blue",
        to: project.saleLabel === "IHO" ? "red" : "pink"
      }
    };

    badges.push(
      <Badge variant="gradient" gradient={saleBadge.badgeGradient}>
        {saleBadge.label}
      </Badge>
    );
  }

  if (project.privateAccessTo) {
    badges.push(
      <Badge variant="gradient" gradient={{ from: "red", to: "yellow" }}>
        PRIVATE ACCESS
      </Badge>
    );
  }

  return (
    <Fragment>
      {badges.map((badge, index) => (
        <Fragment key={`project-type-badge-${index}`}>{badge}</Fragment>
      ))}
    </Fragment>
  );
};

export default ProjectTypeBadge;
