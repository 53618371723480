import { useCallback, useEffect, useState } from "react";
import { IconX } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import Utils from "../../../utils/utilities";
import CsvEntriesFormField from "./CsvEntriesFormField";
import { REGEX_PATTERNS } from "../../../utils/constants";

const PrivateAccessToFormField = ({
  field,
  inputProps,
  onClearState,
  onUploaded,
  ...rest
}) => {
  const [file, setFile] = useState(null);

  const filterNonEmptyRows = (row) =>
    Array.isArray(row) ? row.filter(Boolean).length > 0 : Boolean(row);

  const handleReadCSV = useCallback(
    async (file) => {
      try {
        const result = await Utils.fileReader().readAsText(file);

        const data = result
          .split(/\r\n|\r/)
          .map((row, index) => (index === 0 ? null : row))
          .filter(filterNonEmptyRows);

        if (
          !Array.isArray(data) ||
          !data.some((accountId) =>
            new RegExp(REGEX_PATTERNS.hederaAccount).test(accountId)
          )
        ) {
          return showNotification({
            id: "failed-to-parse-csv",
            icon: <IconX />,
            autoClose: 4000,
            color: "red",
            title: "Failed to parse.",
            message: "Failed to parse your CSV, please check it and try again.",
            loading: false
          });
        }

        inputProps.onChange(data.join(","));
      } catch (e) {
        console.error(e);
      }
    },
    [inputProps]
  );

  useEffect(() => {
    if (file) handleReadCSV(file);
    // eslint-disable-next-line
  }, [file]);

  return (
    <CsvEntriesFormField
      field={field}
      inputProps={inputProps}
      onClearState={onClearState}
      csvFile={file}
      onUploaded={(f) => setFile(f)}
      {...rest}
    />
  );
};

export default PrivateAccessToFormField;
