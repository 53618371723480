import { MEDIA_DEFAULT_NAMES, URLs } from "./constants";
import { awsApi } from "../APIs/Aws.api";
import utilities from "./utilities";
import { dispatchAction } from "../core/redux/reduxStore";

const ProjectUtils = {
  withMediaAssets: (project) => {
    const { key, lastUpdatedDate } = project;
    const logo = `${URLs.MEDIA}/media/${key}/${MEDIA_DEFAULT_NAMES.LOGO}?ts=${lastUpdatedDate}`;
    const thumbnail = `${URLs.MEDIA}/media/${key}/${MEDIA_DEFAULT_NAMES.THUMBNAIL}?ts=${lastUpdatedDate}`;

    return {
      ...project,
      logo,
      thumbnail
    };
  },

  withContractInfo: async (project) => {
    const contractsMapping = {
      pools: project.pools,
      storefronts: project.storefronts
    };

    const contractsInfoMap = async (contract) => {
      const { data: contractInfo } = await dispatchAction(
        awsApi.endpoints.getContractInfo.initiate({
          contractId: contract.contractId
        })
      );

      return {
        ...contract,
        ...(contractInfo && { contractInfo })
      };
    };

    const promises = Object.keys(contractsMapping).map(async (contractsKey) => {
      const contracts = contractsMapping[contractsKey];

      return {
        [contractsKey]: await Promise.allSettled(
          contracts.map(contractsInfoMap)
        ).then(utilities.filterSettledPromises)
      };
    });

    const resolvedContracts = await Promise.allSettled(promises).then(
      utilities.filterSettledPromises
    );

    return resolvedContracts.reduce((project, contracts) => {
      project = { ...project, ...contracts };
      return project;
    }, project);
  }
};

export default ProjectUtils;
