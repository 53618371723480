import utilities from "../utils/utilities";
import { IconEdit, IconDoorExit } from "@tabler/icons";
import { ActionIcon, createStyles } from "@mantine/core";
import { withToggleOnEdit } from "../utils/contexts";
import { isNil } from "lodash";

const EditIcon = ({ isEdit, onEdit }) => {
  const { classes, cx } = useStyles();

  const icon = isEdit ? (
    <IconDoorExit
      className={cx("edit-btn-icon enable", classes.editBtnIcon)}
      stroke={1.5}
    />
  ) : (
    <IconEdit
      className={cx("edit-btn-icon disable", classes.editBtnIcon)}
      stroke={1.5}
    />
  );

  return (
    <ActionIcon
      className={cx("edit-btn", classes.editBtn)}
      variant="default"
      radius="md"
      onClick={onEdit}
    >
      {icon}
    </ActionIcon>
  );
};

const WithEditableField = (WrappedComponent, toggleOn = null) => {
  const WithEditableConfig = (props) => {
    const { classes, cx } = useStyles();
    const EditToggle = withToggleOnEdit(EditIcon);

    return (
      <div
        className={cx(
          "editable-wrapper",
          classes.editableWrapper,
          props.className
        )}
      >
        {!isNil(props[toggleOn]) && <EditToggle {...props} />}
        <WrappedComponent {...props} />
      </div>
    );
  };

  WithEditableConfig.displayName = `WithEditableConfig(${utilities.getDisplayName(WrappedComponent)})`;

  return WithEditableConfig;
};

const useStyles = createStyles((theme) => {
  return {
    editableWrapper: {
      position: "relative",
      display: "table",

      ".editable": {
        minHeight: "20px"
      }
    },
    editBtn: {
      position: "absolute",
      top: theme.spacing.xs,
      right: theme.spacing.xs + 2,
      fontSize: 36,
      zIndex: 1
    },

    editBtnIcon: {
      fontSize: 18,
      color: theme.colors.gray[5]
    }
  };
});

export default WithEditableField;
