import DropzoneInput from "../../components/reusable/DropzoneInput";
import { MIME_TYPES, MS_EXCEL_MIME_TYPE } from "@mantine/dropzone";

const MerkleUploader = (props) => {
  return (
    <DropzoneInput
      label="Upload file:"
      accept={[...MS_EXCEL_MIME_TYPE, MIME_TYPES.csv]}
      {...props}
    />
  );
};

export default MerkleUploader;
