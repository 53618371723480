import {
  MediaQuery,
  useMantineTheme,
  createStyles,
  Burger,
  Header,
  Image,
  Group
} from "@mantine/core";
import HeadStarterLogo from "../assets/media/headstarter_logo.png";
import WalletConnectBtn from "./reusable/WalletConnectBtn/WalletConnectBtn";

const AppHeader = ({ opened, toggleMenu }) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Header height={70} p="lg">
      <div className={classes.headerContainer}>
        <Group>
          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Burger
              opened={opened}
              onClick={() => toggleMenu((o) => !o)}
              size="sm"
              color={theme.colors.gray[6]}
              mr="xl"
            />
          </MediaQuery>

          <Image width={160} src={HeadStarterLogo} alt="HeadStarter Logo" />
        </Group>

        <Group>
          <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
            <Group>
              <WalletConnectBtn withBalance={true} />
            </Group>
          </MediaQuery>
        </Group>
      </div>
    </Header>
  );
};

const useStyles = createStyles(() => {
  return {
    headerContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "100%"
    }
  };
});

export default AppHeader;
