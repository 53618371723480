import { PROJECT_STATUS_BADGE } from "../../utils/constants";
import { Badge } from "@mantine/core";

const ProjectStatusBadge = ({ status }) => {
  const badgeConfig = PROJECT_STATUS_BADGE[status];

  const getStyle = (theme) => {
    const bgColors = theme.colors[badgeConfig.color];

    return {
      backgroundColor: bgColors?.length ? bgColors[6] : theme.colors.gray[6]
    };
  };

  return (
    badgeConfig && (
      <Badge variant="color" sx={getStyle}>
        {badgeConfig.label || PROJECT_STATUS_BADGE.DRAFTING.label}
      </Badge>
    )
  );
};

export default ProjectStatusBadge;
