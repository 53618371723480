import { useState } from "react";
import { Calendar, TimeInput } from "@mantine/dates";
import {
  Button,
  createStyles,
  Paper,
  TextInput,
  Radio,
  Divider,
  Space,
  ActionIcon
} from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";
import { IconX } from "@tabler/icons";

const TIME_FORMATS = {
  12: {
    key: "12",
    label: "12 hours"
  },
  24: {
    key: "24",
    label: "24 hours"
  }
};

const DateAndTimePicker = ({
  label,
  placeholder,
  value,
  error,
  autoFocus = false,
  ...props
}) => {
  const dateNow = value ? new Date(Math.floor(value * 1000)) : "-";

  const [state, setState] = useState({
    date: dateNow,
    time: dateNow,
    isOpen: false,
    timeFormat: TIME_FORMATS["12"].key
  });

  const onClose = () => {
    setState((prevState) => ({
      ...prevState,
      isOpen: false
    }));

    if (typeof props.onClose === "function") {
      props.onClose();
    }
  };

  const ref = useClickOutside(onClose);

  const handleOnFocus = () => {
    props.onFocus();
    setState((prevState) => ({
      ...prevState,
      isOpen: true
    }));
  };

  const handleOnChange = (prop, date) => {
    setState((prevState) => ({
      ...prevState,
      [prop]: date
    }));
  };

  const handleOnSubmit = () => {
    //TODO: find a better way to format the date
    const time = new Date(state.time);
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();

    const date = new Date(state.date);
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);

    props.onChange(Math.floor(date.getTime() / 1000));
    onClose();
  };

  const handleOnReset = () => {
    setState((prevState) => ({
      ...prevState,
      date: "",
      time: ""
    }));

    props.onChange("");
    onClose();
  };

  const { classes } = useStyles();

  return (
    <Paper className={classes.timePickerParent} ref={ref}>
      <TextInput
        label={label}
        placeholder={placeholder}
        onFocus={handleOnFocus}
        value={dateNow}
        error={error}
        rightSection={
          <ActionIcon variant="transparent" onClick={handleOnReset}>
            <IconX color={"#868e96"} size={14} />
          </ActionIcon>
        }
        autoFocus={autoFocus}
        readOnly
      />
      {state.isOpen && (
        <Paper className={classes.timePickerContainer}>
          <Calendar
            {...props}
            value={state.date}
            onChange={(value) => handleOnChange("date", value)}
          />

          <Divider my="sm" />

          <Radio.Group
            value={state.timeFormat}
            onChange={(timeFormat) =>
              setState((prevState) => ({ ...prevState, timeFormat }))
            }
            label="Format"
          >
            <Radio
              value={TIME_FORMATS["12"].key}
              label={TIME_FORMATS["12"].label}
            />
            <Radio
              value={TIME_FORMATS["24"].key}
              label={TIME_FORMATS["24"].label}
            />
          </Radio.Group>

          <Space h="md" />

          <TimeInput
            {...props}
            label="Time"
            format={state.timeFormat}
            value={state.time}
            onChange={(value) => handleOnChange("time", value)}
            withSeconds
            clearable
          />

          <Space h="md" />

          <Paper className={classes.timePickerActions} mt="sm">
            <Button className="btn" type="submit" onClick={handleOnSubmit}>
              Confirm
            </Button>
            <Button className="btn" onClick={onClose}>
              Close
            </Button>
          </Paper>
        </Paper>
      )}
    </Paper>
  );
};

const useStyles = createStyles((theme) => {
  const isDarkMode = theme.colorScheme === "dark";

  return {
    timePickerParent: {
      position: "relative"
    },
    timePickerContainer: {
      position: "absolute",
      padding: "1rem",
      zIndex: 2,
      border: `1px solid ${isDarkMode ? theme.colors.dark[4] : theme.colors.gray[3]}`
    },
    timePickerActions: {
      display: "flex",
      gap: "0.5rem",

      ".btn": {
        flex: 1
      }
    }
  };
});

export default DateAndTimePicker;
