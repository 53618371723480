import {
  ActionIcon,
  Alert,
  CloseButton,
  Divider,
  Group,
  Stack,
  TextInput,
  Tooltip
} from "@mantine/core";
import {
  IconInfoCircle,
  IconLetterH,
  IconLetterS,
  IconPlus,
  IconStar,
  IconX
} from "@tabler/icons";
import { useEffect, useState } from "react";
import { REGEX_PATTERNS } from "../../utils/constants";
import { AccountId } from "headstarter-crypto/hashgraph-sdk";
import { showNotification } from "@mantine/notifications";

const META_ARGS_COUNT_LIMIT = 5;
const DEFAULT_META_ARGUMENTS = [{ key: "gas", value: "169000" }];

const FunctionFormMetaArgs = ({ onSubmit }) => {
  const [state, setState] = useState({
    metaArgs: {
      count: DEFAULT_META_ARGUMENTS.length,
      args: [...DEFAULT_META_ARGUMENTS]
    }
  });

  const handleAddMetaArg = () => {
    if (META_ARGS_COUNT_LIMIT === state.metaArgs.count) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      metaArgs: {
        ...prevState.metaArgs,
        count: prevState.metaArgs.count + 1,
        args: [...prevState.metaArgs.args, { key: "", value: "" }]
      }
    }));
  };

  const handleChangeMetaArgs = (_index, e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      metaArgs: {
        ...prevState.metaArgs,
        args: prevState.metaArgs.args.map((arg, index) => {
          return index === _index ? { ...arg, [name]: value } : arg;
        })
      }
    }));
  };

  const handleRemoveMetaArg = (_index) => {
    setState((prevState) => ({
      ...prevState,
      metaArgs: {
        count: prevState.metaArgs.count - 1,
        args: prevState.metaArgs.args.filter((_, index) => index !== _index)
      }
    }));
  };

  useEffect(() => {
    const args = state.metaArgs.args.filter(({ key, value }) => key && value);
    onSubmit(args);
  }, [state.metaArgs.count, state.metaArgs.args, onSubmit]);

  const inputs = Array.from({ length: state.metaArgs.count }, (_, index) => {
    const arg = state.metaArgs.args[index];
    let buttons = [];

    if (new RegExp(REGEX_PATTERNS.hederaAccount).test(arg.value.toString())) {
      buttons = [
        ...buttons,
        <Tooltip
          position="top"
          label="To solidity address"
          key="to-solidity-address-btn"
        >
          <ActionIcon
            size="sm"
            variant="filled"
            onClick={() => {
              handleChangeMetaArgs(index, {
                target: {
                  name: "value",
                  value: AccountId.fromString(arg.value).toSolidityAddress()
                }
              });
            }}
          >
            <IconLetterS size={12} />
          </ActionIcon>
        </Tooltip>
      ];
    } else if (
      ("string" === typeof arg.value &&
        arg.value.startsWith("0x") &&
        arg.value.length === 42) ||
      `0x${arg.value}`.length === 42
    ) {
      buttons = [
        ...buttons,
        <Tooltip
          position="top"
          label="To hedera address"
          key="to-hedera-address-btn"
        >
          <ActionIcon
            size="sm"
            variant="filled"
            onClick={() => {
              try {
                handleChangeMetaArgs(index, {
                  target: {
                    name: "value",
                    value:
                      AccountId.fromSolidityAddress(arg.value)?.toString() || ""
                  }
                });
              } catch (e) {
                return showNotification({
                  id: "parse-address-from-solidity",
                  icon: <IconX />,
                  autoClose: 4000,
                  color: "red",
                  title: `Failed to execute`,
                  message: e.reason || e.message,
                  loading: false
                });
              }
            }}
          >
            <IconLetterH size={12} />
          </ActionIcon>
        </Tooltip>
      ];
    } else if (arg.value && !isNaN(arg.value)) {
      buttons = (
        <Tooltip position="top" label="* 10 ** 8" key="to-decimals-btn">
          <ActionIcon
            size="sm"
            variant="filled"
            onClick={() => {
              handleChangeMetaArgs(index, {
                target: {
                  name: "value",
                  value: Math.floor(arg.value * Math.pow(10, 8))
                }
              });
            }}
          >
            <IconStar size={12} />
          </ActionIcon>
        </Tooltip>
      );
    }

    return (
      <Stack spacing={5} key={`meta-arg-field-${index}`}>
        <Group position="right">{buttons}</Group>
        <Group grow>
          <TextInput
            placeholder="key"
            name="key"
            onChange={(e) => handleChangeMetaArgs(index, e)}
            value={arg.key}
          />
          <TextInput
            placeholder="value"
            name="value"
            onChange={(e) => handleChangeMetaArgs(index, e)}
            value={arg.value}
            rightSection={
              <CloseButton
                size="xs"
                onClick={() => handleRemoveMetaArg(index)}
              />
            }
          />
        </Group>
      </Stack>
    );
  });

  return (
    <Stack spacing={5} mb={10}>
      <Divider my="xs" label="Meta arguments" />
      {inputs.length > 0 && (
        <Stack mb={10} spacing={5}>
          {inputs}
        </Stack>
      )}
      {state.metaArgs.count < META_ARGS_COUNT_LIMIT ? (
        <ActionIcon
          sx={{ width: "100%" }}
          variant="light"
          onClick={handleAddMetaArg}
        >
          <IconPlus size="1rem" />
        </ActionIcon>
      ) : (
        <Alert icon={<IconInfoCircle size="1rem" />}>
          Limited to {META_ARGS_COUNT_LIMIT} meta arguments
        </Alert>
      )}
    </Stack>
  );
};

export default FunctionFormMetaArgs;
